import {Component, Input, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-sidebar-summary',
  templateUrl: './sidebar-summary.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./sidebar-summary.component.scss']
})
export class SidebarSummaryComponent implements OnInit {
  @Input() parts: any;
  @Input() formGroup: UntypedFormGroup;
  @Input() form: any = {config: {parts: []}};
  @Input() selectedFormIndex: number;
  @Input() metrics: any;
  data: any;

  constructor() {

  }

  changeDetected(): void {
    console.log('changeDetected');
  }


  ngOnInit() {
    this.data = this.formGroup.controls;
  }
}
