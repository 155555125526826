import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LANGUAGES} from '../data/languages';
import {ActivatedRoute} from '@angular/router';
import {StateService} from '../services/state.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-no-contract',
  templateUrl: './no-contract.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./no-contract.component.scss']
})
export class NoContractComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private state: StateService,
    private route: ActivatedRoute
  ) {
    if(!this.state.language) {
      const currentLanguage = LANGUAGES[this.route.snapshot.paramMap.get('language') || translate.getBrowserLang()];
      this.state.translationId = environment.translationId;

      this.state.language = currentLanguage;
      this.translate.use(currentLanguage);
    }
  }

  ngOnInit() {
  }
}
