import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerService} from "../../services/customer.service";
import {StateService} from "../../services/state.service";
import {environment} from "../../../environments/environment";

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    CustomerService
  ]
})
export class PasswordResetComponent implements OnInit {
  loader=false;
  loginError='';
  formGroup: UntypedFormGroup;
  language='en';
  resetPassword=false;
  formSubscription: Subscription;
  form: any = {config: {parts: [], widgetParts: []}};

  constructor(
    private activatedRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _router: Router
  ) {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(''),
      password: new UntypedFormControl(''),
    });
    let dataPointer = (this.activatedRoute.snapshot.params.formId ? this.activatedRoute.data : this.activatedRoute.parent.data);
    this.formSubscription = dataPointer.subscribe((data: { form: any, language: string }) => {
      this.form = data.form;
      this.language = this.activatedRoute.snapshot.paramMap.get('language') ?
        this.activatedRoute.snapshot.paramMap.get('language') :
        this.activatedRoute.parent.snapshot.paramMap.get('language');    });

  }

  ngOnInit(): void {

  }

  requestNewPassword(): void {
    const self = this;
    self.loader = true;
    const email = this.formGroup.controls['email'].value;
    /**
     * Is email formatted as a valid email
     */
    if (!email || !email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      self.loader = false;
      self.loginError = 'field_invalid_email_error';
      return;
    }

    this._customerService.requestPassword({
      email: this.formGroup.controls['email'].value,
      branding: {
        name: this.form.name,
        url: `${environment.orderFormUrl}/dashboard/${this.language}/${this.form.id}/set-password`,
        logo: this.form.config.logo,
      },
      translationId: environment.translationId
    }).subscribe((data: any) => {
      document.location = `/dashboard/${self.language}/${self.form.id}/login`;
    }, (error) => {
      if(error && error.error && error.error.error && error.error.error.name === 'NOT_FOUND') {
        self.loginError = 'email_reset_not_found';
      } else {
        self.loginError = 'email_reset';
      }
      self.loader = false;
      setTimeout(function(){
        self.loginError = '';
      }, 3000)
    });
  }
}
