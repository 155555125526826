import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './form/form.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ConfirmationComponent } from './form/confirmation/confirmation.component';
import { OrderComponent } from './form/order/order.component';
import { FormResolverService } from './services/form-resolver.service';
import {CurrencyResolverService} from './services/currency-resolver.service';
import {NoContractComponent} from './no-contract/no-contract.component';
import {LoginComponent} from "./dashboard/login/login.component";
import {PasswordResetComponent} from "./dashboard/password-reset/password-reset.component";
import {HistoryComponent} from "./dashboard/history/history.component";
import {InvoicesComponent} from "./dashboard/invoices/invoices.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {SetPasswordComponent} from "./dashboard/set-password/set-password.component";
import {PopupWidgetComponent} from "../integrations/popup-widget/popup-widget.component";
import {InlineWebbookerComponent} from "../integrations/inline-webbooker/inline-webbooker.component";
import {PassengerSignupComponent} from "./dashboard/passenger-signup/passenger-signup.component";
import {DriverSignupComponent} from "./dashboard/driver-signup/driver-signup.component";
import {HomeComponent} from "./home/home.component";

const formChildRoutes: Routes = [
  {
    path: ':orderId/p/:paymentStatus',
    component: OrderComponent
  },
  {
    path: ':orderId/:status',
    component: ConfirmationComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '',
    component: OrderComponent,
  },
];

const inlineChildRoutes: Routes = [
  {
    path: ':orderId/p/:paymentStatus',
    component: OrderComponent
  },
  {
    path: ':orderId/:status',
    component: ConfirmationComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '',
    component: InlineWebbookerComponent,
  },
];

const dashboardChildRoutes: Routes = [
  {
    path: 'signup',
    component: PassengerSignupComponent,
  },
  {
    path: 'driver-signup',
    component: DriverSignupComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
  },
  {
    path: 'set-password/:resetToken',
    component: SetPasswordComponent,
  },
  {
    path: 'new-password/:resetToken',
    component: SetPasswordComponent,
  },
  {
    path: 'history',
    component: HistoryComponent,
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '',
    component: LoginComponent,
  },
];

const routes: Routes = [
  {
    path: 'dashboard/:language/:formId',
    resolve: {
      form: FormResolverService,
      currency: CurrencyResolverService,
    },
    component: DashboardComponent,
    children: dashboardChildRoutes
  },
  {
    path: 'inline-webbooker/:language/:formId',
    resolve: {
      form: FormResolverService,
      currency: CurrencyResolverService,
    },
    component: FormComponent,
    children: inlineChildRoutes
  },
  {
    path: 'popup-widget/:language/:formId',
    resolve: {
      form: FormResolverService,
      currency: CurrencyResolverService,
    },
    component: PopupWidgetComponent
  },
  {
    path: ':language/:formId/:showtopbar/:showlogo/:showbreadcrumbs/:buttonstyle/:headercolor/:textcolor/:backgroundcolor/:font',
    resolve: {
      form: FormResolverService,
      currency: CurrencyResolverService,
    },
    component: FormComponent,
    children: formChildRoutes
  },
  {
    path: 'no-contract',
    component: NoContractComponent,
  },
  {
    path: ':language/no-contract',
    component: NoContractComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'support/:hash/:language/:formId',
    resolve: {
      form: FormResolverService,
      currency: CurrencyResolverService,
    },
    component: FormComponent,
    children: formChildRoutes
  },
  {
    path: ':language/:formId',
    resolve: {
      form: FormResolverService,
      currency: CurrencyResolverService,
    },
    component: FormComponent,
    children: formChildRoutes
  },
  {
    path: ':formId',
    resolve: {
      form: FormResolverService,
      currency: CurrencyResolverService,
    },
    component: FormComponent,
    children: formChildRoutes
  },
  {
    path: ':language/',
    resolve: {
      form: FormResolverService,
      currency: CurrencyResolverService,
    },
    component: FormComponent,
    children: formChildRoutes
  },
  {
    path: '**',
    component: HomeComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
