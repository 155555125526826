export enum TransactionCostType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export interface TransactionCosts {
  type: TransactionCostType;
  amount: number;
}

export interface PaymentGateway {
  id: string;
  description: string;
  directProcess?: boolean;
  preAuth?: boolean;
  psp?: string;
  transactionCosts: TransactionCosts[];
  calculatedCosts?: number;
}

export interface CouponDiscount {
  id: string;
  code: string;
  type: string;
  returnType: string;
  discountType: string;
  balanceLimitByCustomer: number;
  maxDiscount: number;
  value?: boolean;
  discount?: number;
  discountRetour?: number;
}

export interface Direction {
  value: object
}
