import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable, of, timeout} from 'rxjs';
import {StateService} from './state.service';
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'any'
})
export class ApiService {
  host: string;
  protected headers: HttpHeaders;

  constructor(protected http: HttpClient,
              protected state: StateService,
              @Inject('apiModel') public model: string,
              @Inject('apiFormId') public formId: any) {
    this.model = model;
    this.host = environment.apiUrl;
    this.getHeaders();
  }

  protected getHeaders(): HttpHeaders {
    if (!this.formId) {
      this.formId = this.state.formId;
    }
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Form-Id': this.formId,
    });
  }

  public find(filter: any = {}) {
    // console.log('find()', `${this.host}/${this.model}`);
    return this.http.get(`${this.host}/${this.model}?filter=${JSON.stringify(filter)}`, {headers: this.getHeaders()});
  }

  public findById(id: string, filter: any = {}) {
    // console.log('findById()');
    return this.http.get(`${this.host}/${this.model}/${id}?filter=${JSON.stringify(filter)}`, {headers: this.getHeaders()});
  }

  public create(data: any) {
    // console.log('create()');
    return this.http.post(`${this.host}/${this.model}`, data, {headers: this.getHeaders()});
  }

  public update(id: string, data: any) {
    // console.log('update()');
    return this.http.patch(`${this.host}/${this.model}/${id}`, data, {headers: this.getHeaders()});
  }

  public getCustom(path: string, filter: any = {}): Observable<any> {
    return this.http.get<any>(`${this.host}${path}?filter=${JSON.stringify(filter)}`, {
      headers: this.getHeaders(),
    });
  }

  public postCustom(path: string, data: any): Observable<any> {
    // console.log('postCustom()');
    return this.http.post<any>(`${this.host}${path}`, data, {headers: this.getHeaders()});
  }

  public patchCustom(path: string, data: any): Observable<any> {
    // console.log('patchCustom()');
    return this.http.patch<any>(`${this.host}${path}`, data, {headers: this.getHeaders()});
  }

  public getCustomUrl(url: string): any {
    var requestOptions = {
      method: 'GET',
    };
    return fetch(url, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(result => {
        return result;
      })
      .catch(error => console.log('error', error));
  }
}
