import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomerService} from "../../services/customer.service";
import {StateService} from "../../services/state.service";
import {TranslateService} from "@ngx-translate/core";

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    CustomerService
  ]
})
export class SetPasswordComponent implements OnInit {
  loader=false;
  language='en';
  formGroup: UntypedFormGroup;
  formSubscription: Subscription;
  subtext = '';
  form: any = {config: {parts: [], widgetParts: []}};

  constructor(
    private activatedRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _translationService: TranslateService,
    private _router: Router
  ) {
    this.language = this.activatedRoute.snapshot.params.language
    this.formGroup = new UntypedFormGroup({
      'password': new UntypedFormControl(''),
      'password-confirm': new UntypedFormControl(''),
      'resetToken': new UntypedFormControl(activatedRoute.snapshot.params.resetToken),
    });

    let dataPointer = (this.activatedRoute.snapshot.params.formId ? this.activatedRoute.data : this.activatedRoute.parent.data);
    this.formSubscription = dataPointer.subscribe((data: { form: any, language: string }) => {
      this.form = data.form;

      _translationService.get(['set_password_subtitle']).subscribe((translations) => {
        this.subtext = translations['set_password_subtitle'].replace('{companyName}', (data.form.companyName ? data.form.companyName : data.form.name))
      });

      this.language = this.activatedRoute.snapshot.paramMap.get('language') ?
        this.activatedRoute.snapshot.paramMap.get('language') :
        this.activatedRoute.parent.snapshot.paramMap.get('language');
    });
  }

  ngOnInit(): void {
  }

  setPassword(): void {
    const self = this;
    this._customerService.setPassword({
      password: this.formGroup.controls['password'].value,
      resetToken: this.formGroup.controls['resetToken'].value,
    }).subscribe((data: any) => {
      document.location = `/dashboard/${self.language}/${self.form.id}/login`;
    }, (error) => {
      console.log(error);
    });
  }
}
