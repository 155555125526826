import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from './services/state.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { LANGUAGES } from './data/languages';
import 'jquery';
import {CoolLocalStorage} from "@angular-cool/storage";
declare var google: any;
interface Styles {
  colors: any;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'het-dienblad';
  styleSubscription: Subscription;
  styles: Styles = {
    colors: {}
  };

  constructor(@Inject(DOCUMENT) private document: Document,
              translate: TranslateService,
              private _vault: CoolLocalStorage,
              private state: StateService) {
    const availableLanguages = [];
    Object.keys(LANGUAGES).forEach(key => {
      availableLanguages.push(LANGUAGES[key]);
    });
    // Add available languages
    translate.addLangs(availableLanguages);
    // console.log(translate.getBrowserLang());
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang((translate.getBrowserLang() ? translate.getBrowserLang() : availableLanguages[0]));

    // console.log(translate.getBrowserLang());

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en-EN|nl-NL/) ? browserLang : 'en-EN');
  }

  ngOnInit(): void {
    this.styleSubscription = this.state.formStyles$.subscribe(styles => {
      this.styles = styles;
      this.setColors();
    });
  }

  setColors(): void {
    console.groupCollapsed('setBaseColors');
    // @ts-ignore
    const defaultConfig = require('../assets/default_css.json');

    if (this.styles.colors) {
      Object.keys(defaultConfig).forEach(key => {
        if (!this.styles.colors[key]) {
          if (defaultConfig[key].indexOf('$') > -1) {
            console.log(`--${key}: var(--${defaultConfig[key].replace('$', '')})`);
            this.document.documentElement.style.setProperty(`--${key}`, `var(--${defaultConfig[key].replace('$', '')})`);
          } else {
            console.log(`--${key}: ${defaultConfig[key]}`);
            this.document.documentElement.style.setProperty(`--${key}`, defaultConfig[key]);
          }
        }
      });

      console.log('### form config ###');
      Object.keys(this.styles.colors).forEach(key => {
        if (this.styles.colors[key].indexOf('$') > -1) {
          console.log(`--${key}: var(--${this.styles.colors[key].replace('$', '')})`);
          this.document.documentElement.style.setProperty(`--${key}`, `var(--${this.styles.colors[key].replace('$', '')})`);
        } else {
          console.log(`--${key}: ${this.styles.colors[key]}`);
          this.document.documentElement.style.setProperty(`--${key}`, this.styles.colors[key]);
        }
      });
    }
    console.groupEnd();
  }

  ngOnDestroy(): void {
    if (this.styleSubscription) {
      this.styleSubscription.unsubscribe();
    }
  }

}
