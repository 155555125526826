import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Field} from '../../interfaces/field';
import {UntypedFormGroup} from '@angular/forms';
import {FieldErrorService} from '../../services/field-error.service';
import {mergeMap, take} from 'rxjs/operators';
import {EMPTY, Observable, of} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {HttpClient} from '@angular/common/http';
import {StateService} from '../../services/state.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Order} from '../../interfaces/order';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-coupon-code-field',
  templateUrl: './coupon-code-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
styleUrls: ['./coupon-code-field.component.scss']
})
export class CouponCodeFieldComponent implements OnInit {
  api: ApiService;
  @Input() config: Field;
  @Input() control: UntypedFormGroup;
  hasErrors = false;
  hasValidatedCoupon = false;
  value = '';

  constructor(private errorService: FieldErrorService,
              private http: HttpClient,
              private translate: TranslateService,
              private state: StateService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {

    let formId;
    if (activatedRoute.snapshot && activatedRoute.snapshot.params && activatedRoute.snapshot.params.paramMap) {
      formId = activatedRoute.snapshot.params.formId ? activatedRoute.snapshot.params.formId : activatedRoute.snapshot.params.paramMap.formId;
      this.state.language = activatedRoute.snapshot.params.language ? activatedRoute.snapshot.params.language : activatedRoute.snapshot.params.paramMap.language;
      if(!this.state.language) {
        this.state.language = translate.getBrowserLang();
      }
    } else {
      formId = this.state.formId;
    }

    this.api = new ApiService(this.http, this.state, 'forms', formId);
  }

  ngOnInit() {
  }

  onValueChanged(event: any): void {
    const formatErrorId = `${this.config.property}InvalidFormat`;
    const flightNumberErrorId = 'flightNumberRequired';
    const returnFlightNumberErrorId = 'returnFlightNumberRequired';
    this.value = event.target.value;
    if (this.config.options && this.config.options.enforceFormat) {
      this.value = event.target.value.toUpperCase().replace(/ /g, '');
    }
    this.control.controls[this.config.property].setValue(this.value);
    if (this.errorService.fieldHasErrors(this.config.property)) {
      this.errorService.removeErrors([formatErrorId, flightNumberErrorId, returnFlightNumberErrorId]);
    }
  }

  isValid(): boolean {
    if (this.value === '' && !this.config.required) { return true; }
    const regex = /^[A-Z]{2,3}\d{3,4}$/g;
    return regex.test(this.value);
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }

  validateCoupon(): void {
    if(this.value === '') {
      return;
    }
    // console.groupCollapsed('Coupon Code Field');
    const self = this;
    const formatErrorId = `${this.config.property}InvalidFormat`;
    // console.log(this.control);
    // console.log(this.control.controls);
    // console.log('##########');
    const data = {
      checkOpenJobs: true,
      requestedDate: this.control.controls[this.control.controls['requestedDateMode'].value].value,
      sku: this.control.controls.product.value.sku,
      passenger: {
        emailAddress: this.control.controls.email.value,
        phoneNumber: this.control.controls.phoneNumber.value,
      }
    };

    // console.log(data);
    this.api.postCustom(`/forms/validateCoupon/${encodeURIComponent(this.value)}`, data).subscribe(result => {
      console.log(result);

      this.state.couponDiscount = result;
      this.state.couponDiscount.type = result.discountType;
      this.state.couponDiscount.value = (result.discountType === 'fixed' ? result.discountFixedAmount : result.discountPercentage );

      console.log('#################', JSON.parse(JSON.stringify(this.control.controls.product.value.price)));

      this.control.controls['couponId'].setValue(result.id);
      this.control.controls[this.config.property].setValue(result.id);

      this.hasValidatedCoupon = true;
      if (this.errorService.fieldHasErrors(this.config.property)) {
        this.errorService.removeErrors([formatErrorId]);
      }
      // console.groupEnd();
    }, (error => {
      console.log(error.error.error);
      if (error.error && error.error.error && error.error.error.statusCode) {
        let label = 'coupon_field_error_not_found';
        if (error.error.error.name && error.error.error.name !== 'NotFoundError') {
          label = `coupon_field_error_${error.error.error.name.toLowerCase()}`;
        }
        // console.log(this.config.property);
        this.errorService.addError({
          id: formatErrorId,
          property: this.config.property,
          text: label,
          label: this.config.label,
        });

        setTimeout(function(){
          self.errorService.removeErrors([formatErrorId]);
        }, 4000);
      }
    }));
  }
}
