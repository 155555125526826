import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateLoader} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {StateService} from "./state.service";
import moment from "moment"

@Injectable()
export class TranslationService implements TranslateLoader {

  constructor(private http: HttpClient,
              private _vault: CoolLocalStorage,
              private _state: StateService,
  ) {
  }

  getTranslation(lang: string): Observable<any> {
    const self = this;
    if (lang === 'default') {
      return of('[]');
    }
    let id = this._state.translationId;
    if (id === '') {
      id = environment.translationId;
    }
    const projectUrl = `https://localization-api.dispatchapi.io/api/Projects/${id}/${lang}`;
    const hash = `${id}-${lang}`;
    const result = self._vault.getObject(hash);

    if (result && result['ttl'] && environment.production && moment(result['ttl']).isAfter()) {
      return of(result);
    } else {
      return this.http.get(`${projectUrl}`).pipe(
        map((response: JSON) => {
          response['ttl'] = moment().add('1','days').toISOString();
          try {
            self._vault.setObject(hash, response);
          } catch (e) {
            self._vault.clear();
          }
          return response;
        }));
    }
  }
}
