interface PriceBreakdownRule {
  total: number;
  taxPercentage: number;
}

export interface PriceBreakdown {
  route: PriceBreakdownRule;
  toll: PriceBreakdownRule;
  parking: PriceBreakdownRule;
  waiting: PriceBreakdownRule;
  cleaning: PriceBreakdownRule;
  discount: PriceBreakdownRule;
}

export interface Price {
  total: number;
  currency: string;
  breakdown: PriceBreakdown;
}

export const DefaultPrice: Price = {
  total: 0,
  currency: '',
  breakdown: {
    route: {
      total: 0,
      taxPercentage: 0
    },
    toll: {
      total: 0,
      taxPercentage: 0
    },
    parking: {
      total: 0,
      taxPercentage: 0
    },
    waiting: {
      total: 0,
      taxPercentage: 0
    },
    cleaning: {
      total: 0,
      taxPercentage: 0
    },
    discount: {
      total: 0,
      taxPercentage: 0
    }
  }
};
