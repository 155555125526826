import {AfterViewInit, Component, Inject, Input, OnDestroy, Renderer2, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LANGUAGES } from '../data/languages';
import { StateService } from '../services/state.service';
import { environment } from '../../environments/environment';
import {CoolLocalStorage} from "@angular-cool/storage";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnDestroy {
  formSubscription: Subscription;
  headerLinks: any[] = [];
  mForm: any;
  currentLanguage: any;
  currentUser: any;
  supportMode:string = null;

  constructor(public translate: TranslateService,
              private state: StateService,
              private _vault: CoolLocalStorage,
              private _renderer2: Renderer2,
              private route: ActivatedRoute,
              @Inject('Window') private window: Window,
              @Inject(DOCUMENT) private document: Document) {
    this.formSubscription = this.route.data.subscribe((data: {form: any}) => {
      this.mForm = data;
      this.headerLinks = data.form.config.header.links;
      this.state.translationId = data.form.config.translationId ? data.form.config.translationId : environment.translationId;

      try {
        this.currentUser = JSON.parse(this._vault.getItem(`#t-#${this.mForm.form.id}-user`) || '{}');
      } catch(e) {
        this._vault.removeItem(`#t-#${this.mForm.form.id}-user`);
      }
    });
  }
  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
