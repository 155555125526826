import {Inject, Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient} from "@angular/common/http";
import {StateService} from "./state.service";

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends ApiService {
  constructor(http: HttpClient,
              state: StateService,
              @Inject('apiFormId') public formId: any) {
    super(http, state, 'customers', formId);
  }

  public login(body: any = {}) {
    return this.http.post(`${this.host}/${this.model}/login`, body, {headers: this.getHeaders()});
  }

  public requestPassword(body: any = {}) {
    return this.http.post(`${this.host}/${this.model}/requestPasswordReset`, body, {headers: this.getHeaders()});
  }

  public setPassword(body: any = {}) {
    return this.http.post(`${this.host}/${this.model}/setPassword`, body, {headers: this.getHeaders()});
  }

  public createPassenger(body: any = {}) {
    return this.http.post(`${this.host}/${this.model}/`, body, {headers: this.getHeaders()});
  }

  public getHistory(filter: any = {}) {
    return this.http.get(`${this.host}/${this.model}/history?filter=${encodeURIComponent(JSON.stringify(filter))}`, {headers: this.getHeaders()});
  }

  public getInvoices(filter: any = {}) {
    return this.http.get(`${this.host}/${this.model}/invoices?filter=${encodeURIComponent(JSON.stringify(filter))}`, {headers: this.getHeaders()});
  }

  public getPaymentMethods(body: any = {}) {
    return this.http.get(`${this.host}/${this.model}/paymentMethods`, {headers: this.getHeaders()});
  }

  public getDebtorRoles() {
    return this.http.get(`${this.host}/${this.model}/debtorRoles`, {headers: this.getHeaders()});
  }

  public getDebtorInvoices(filter: any = {}) {
    return this.http.get(`${this.host}/${this.model}/debtorInvoices?filter=${encodeURIComponent(JSON.stringify(filter))}`, {headers: this.getHeaders()});
  }

  public getDebtorRides(filter: any = {}) {
    return this.http.get(`${this.host}/${this.model}/debtorRides?filter=${encodeURIComponent(JSON.stringify(filter))}`, {headers: this.getHeaders()});
  }
}
