import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {StateService} from "../services/state.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../environments/environment";
import {LANGUAGES} from "../data/languages";
import {ApiService} from "../services/api.service";
import {CoolLocalStorage} from "@angular-cool/storage";

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    ApiService
  ]
})
export class DashboardComponent implements OnDestroy {
  formSubscription: Subscription;
  headerLinks: any[] = [];
  mForm: any;

  constructor(public translate: TranslateService,
              private route: ActivatedRoute) {
    this.formSubscription = this.route.data.subscribe((data: { form: any }) => {
      this.mForm = data.form;
      this.headerLinks = data.form.config.header.links;
    });
  }

  getLanguageName(lang: string): string {
    return lang.split('-')[0].toUpperCase();
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
