<h3 *ngIf="!['requestedDate', 'returnRequestedDate'].includes(config.property)">
  <fa-icon [icon]="(_state.airportDestination ? faPlaneDeparture : faPlaneArrival)"></fa-icon> {{'flight_details'|translate|ucFirst}}
</h3>
<span class="wb-summary-name">
  <ng-container>
    {{config.label | translate | ucFirst}}
    <span class="wb-required" *ngIf="config.required">*</span>
  </ng-container>

  <div *ngIf="config.helpText && !summary" class="wb-help"
       (click)="doToggleHelpText.emit({helpTitleTag: config.label,helpTextTag: getHelpText()})">
    <fa-icon [icon]="faInfoCircle"></fa-icon>
  </div>
</span>
<div [formGroup]="control" class="wb-{{config.type}} {{opened ? 'open' : ''}} {{hasErrors ? 'error' : ''}}">
  <div
    class="wb-select {{!hasOptions ? 'hidden-select' : ''}}">
    <select (change)="changeMode($event)" name="{{config.property}}-selector">
      <option value="requestedDate" *ngIf="(orderMode !== 'airport' || this.airportDates === 'optional')">
        {{'pick_up' | translate | ucFirst}}
      </option>

      <option value="requestedDestinationDate" [selected]="(this.control.controls['requestedDateMode'].value === 'requestedDestinationDate')"
              *ngIf="((orderMode === 'airport' && _state.airportDestination)) && !_state.airportDeparture ">
        {{'drop_off' | translate | ucFirst}}
      </option>

      <option value="requestedScheduleDate" [selected]="(this.control.controls['requestedDateMode'].value === 'requestedScheduleDate')"
              *ngIf="(orderMode === 'airport' && _state.airportDeparture)">
        {{'scheduled_pick_up' | translate | ucFirst}}
      </option>
    </select>

    <svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="!config.options.pickUpOnly &&
         !(config.property === 'returnRequestedDate' && orderMode === 'airport' && _state.airportDestination) &&
         !(config.property === 'requestedDate' && orderMode === 'airport' && _state.airportDeparture)">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Book-your-trip" transform="translate(-108.000000, -498.000000)" fill="#FFFFFF">
          <polygon id="Triangle"
                   transform="translate(115.000000, 503.000000) rotate(-180.000000) translate(-115.000000, -503.000000) "
                   points="115 498 122 508 108 508"></polygon>
        </g>
      </g>
    </svg>
  </div>
  <div class="wb-inputs {{(!hasOptions ? 'full-width' : '')}}">
    <div class="flatpickr {{(_state.companySettings.clock === '12' ? 'clock-date' : '')}}">
      <ng2-flatpickr #picker [config]="options2">
        <a class="input-button" title="toggle" data-toggle>
          <mat-icon>today</mat-icon>
        </a>
      </ng2-flatpickr>
      <div class="time-selection {{(_state.companySettings.clock ? 'am-clock' : 'am-clock')}}">
        <select
          class="desktop-timePicker mobile-time-picker text-center hour-picker"
          (change)="onChangeTime($event)"
                name="hours">
          {{selectedTime.split(':')[0]}}
          <option *ngFor="let time of timeHourOptions"
                  [selected]="(time.selectedValue===selectedTime.split(':')[0])"
                  [disabled]="!(time.enabled)">{{time.value}}</option>
        </select>
        <div class="seperator">:</div>
        <select class="desktop-timePicker mobile-time-picker text-center" (change)="onChangeTime($event)"
                name="minutes">
          <option *ngFor="let time of timeMinutesOptions"
                  [selected]="(time.value===selectedTime.split(':')[1])"
                  [disabled]="!(time.enabled)">{{time.value}}</option>
        </select>
        <div class="seperator" *ngIf="_state.companySettings.clock === '12'">:</div>
        <div *ngIf="_state.companySettings.clock === '12'">
          <select class="desktop-timePicker mobile-time-picker text-center clock-picker" (change)="onChangeTime($event)"
                  name="clock" #clock>
            <option *ngFor="let time of clockOptions"
                    [selected]="(time===selectedClock)">{{time}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="configRequestedDestinationDateOffset > 0 && _state.airportDestination && !['requestedDate','returnRequestedDate'].includes(this.control.controls['requestedDateMode'].value)" class="wb-airport-destination-text wb-help">
    <fa-icon [icon]="faInfoCircle"></fa-icon> {{'destination_offset_text_1'|translate|ucFirst}} {{Math.floor(configRequestedDestinationDateOffset/60)}} {{'hours'|translate}}
    <span *ngIf="(configRequestedDestinationDateOffset % 60) > 0">{{(configRequestedDestinationDateOffset % 60)}} {{'min'|translate}}.</span>
    {{'destination_offset_text_2'|translate}}
  </div>
</div>
<app-field-error [config]="config" (validityChanged)="validityChanged($event)"></app-field-error>
