import {Component, Inject, Input, isDevMode, OnChanges, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AsyncTranslationLoader} from '../../app/async-translation-loader';
import {StateService} from '../../app/services/state.service';
import {ApiService} from '../../app/services/api.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EMPTY, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {OrderComponent} from '../../app/form/order/order.component';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {FieldErrorService} from '../../app/services/field-error.service';
import {AnalyticsService} from '../../app/services/analytics.service';
import {Title} from '@angular/platform-browser';
import {VisitorService} from '../../app/services/visitor.service';
import {LANGUAGES} from '../../app/data/languages';
import {CustomerService} from "../../app/services/customer.service";
import * as ColorContrastChecker from 'color-contrast-checker';

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};

interface Styles {
  colors: any;
}

@Component({
  selector: 'app-widget',
  templateUrl: '../../app/form/order/order.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./inline-webbooker.component.scss',
    '../../app/form/order/order.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    ApiService, CustomerService
  ]
})
export class InlineWebbookerComponent extends OrderComponent implements OnInit, OnChanges {
  formConfig = 'webbooker';
  formStyle = 'inline';
  @Input() formid: string;
  @Input() language: string;
  @Input() navigation: string = 'inline';
  @Input() showtopbar: any = false;
  @Input() showbreadcrumbs: any = true;
  @Input() showlogo: any = true;
  @Input() buttonstyle: string = 'rounded';
  @Input() headercolor: string = '#597db0';
  @Input() textcolor: string = '#000000';
  @Input() backgroundcolor: string = 'transparent';

  nActivatedRoute: any;
  data: any;
  activeContract = true;
  loaded = false;
  nState: StateService;
  nTranslate: TranslateService;
  nModalService: NgxSmartModalService;
  nRouter: Router;
  nVault: CoolLocalStorage;
  directLoaded = false;
  formGroup = new UntypedFormGroup({
    firstname: new UntypedFormControl(''),
    lastname: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    address: new UntypedFormGroup({
      address: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
    })
  });
  styleSubscription: Subscription;
  styles: Styles = {
    colors: {}
  };
  modalOpen = false;
  loadingForm = false;

  constructor(private http: HttpClient,
              activatedRoute: ActivatedRoute,
              apiService: ApiService,
              modalService: NgxSmartModalService,
              translate: TranslateService,
              vault: CoolLocalStorage,
              router: Router,
              route: ActivatedRoute,
              state: StateService,
              errorService: FieldErrorService,
              analytics: AnalyticsService,
              pageTitle: Title,
              visitorService: VisitorService,
              _customerService: CustomerService,
              _renderer2: Renderer2,
              @Inject('Window') window: Window,
              @Inject(DOCUMENT) document: Document) {
    super(apiService, activatedRoute, modalService, translate, vault, router, route, state, errorService, analytics, pageTitle, visitorService, _customerService, _renderer2, window, document);

    const availableLanguages = [];
    Object.keys(LANGUAGES).forEach(key => {
      availableLanguages.push(LANGUAGES[key]);
    });
    // Add available languages
    translate.addLangs(availableLanguages);
    state.translationId$.subscribe(id => {
      if (id !== '') {
        AsyncTranslationLoader.translationIdSubject.next(id);
      }
    });

    if(window.document.getElementsByTagName('webbooker-widget').length > 0) {
      this.formConfig = 'widget'
      this.showbreadcrumbs = false;
      this.showlogo = false;
    }

    this.isWidget = true;
    this.nState = state;
    this.nTranslate = translate;
    this.nModalService = modalService;
    this.nActivatedRoute = activatedRoute;
    this.nRouter = router;
    this.nVault = vault;
    this.loadCurrency();

    if (this.nActivatedRoute.snapshot && this.nActivatedRoute.parent) {
      const dataPointer = (this.nActivatedRoute.snapshot.params.formId ? this.nActivatedRoute.data : this.nActivatedRoute.parent.data);
      this.formSubscription = dataPointer.subscribe((data: { form: any }) => {
        // this.form = data.form;
        this.formId = data.form.id;
        this.language = LANGUAGES[this.nActivatedRoute.snapshot.paramMap.get('language') || translate.getBrowserLang()];
        const currentLanguage = this.nState.language;
        this.nTranslate.setDefaultLang(currentLanguage);
        this.loaded = true;
        console.log('Construct');
        this.loadForm();
      });
    }
  }

  ngOnChanges(values) {
    if (this.formid && !this.loaded) {
      this.formId = this.formid;
      this.nState.formId = this.formId;
      const currentLanguage = LANGUAGES[this.language || 'en'];
      this.nTranslate.setDefaultLang(currentLanguage);
      this.nState.language = this.language;
      this.loaded = true;
      if (!(this.nActivatedRoute && this.nActivatedRoute.snapshot && this.nActivatedRoute.parent)) {
        this.loadForm();
      }
    } else {
      this._onChanges(values);
    }
  }

  loadForm() {
    this.loadingForm = true;
    const api = new ApiService(this.http, this.nState, 'forms', this.formId);

    return api.getCustom(`/forms/${this.formId}`).subscribe((form: any) => {
      const controls: any = {
        product: new UntypedFormControl(null),
        returnTrip: new UntypedFormControl(false),
      };
      // const group = new FormGroup(controls);
      // this.formGroup = group;
      // this.nState.formGroup = group;

      this.data = {};
      this.data.form = form;
      this.data.currency = form.config.currency;
      this.data.formId = form.id;
      this.data.timeZone = form.config.timeZone;
      this.nState.timeZone = form.config.timeZone;
      this.nState.formConfig = form.config;
      this.nState.currency = this.data.currency;

      if (form.config.styles) {
        this.data.formStyles = form.config.styles;
      }
      if (form) {
        this.nState.translationId = form.config.translationId ? form.config.translationId : environment.translationId;
        this.styles = form.config.styles;
        this.setColors();

        return api.getCustom(`/forms/companySettings`).subscribe((result: any) => {
          this.nState.companySettings = result.settings;
          return api.getCustom(`/forms/validateCloudConnectContract`).subscribe((result: any) => {
            if (result.status === 'not_active') {
              this.activeContract = false;
            } else if (form) {
              this.nState.realm = result.realm;
              this.nState.googleApiKey = result.googleKey;
              this._ngOnInit(this.data);
            } else {
              this.activeContract = false;
              return EMPTY;
            }
          });
        });
      } else {
        // this.router.navigate([`/not-found`]);
        return EMPTY;
      }
    });
  }

  navigate(direction: string, index: number = -1): void {
    console.groupCollapsed('navigate');

    const self = this;
    const currentType = this.form.config.parts[(self.navigation === 'modal' ? this.modalFormIndex : this.selectedFormIndex)].type;
    const indexSpecified = index > -1;
    let goingBack = false;
    let goingForward = false;


    let newIndex = (indexSpecified ? index : (direction === 'next' ? this.selectedFormIndex + 1 : this.selectedFormIndex - 1));
    if (self.navigation === 'modal') {
      newIndex = (indexSpecified ? index : (direction === 'next' ? this.modalFormIndex + 1 : this.modalFormIndex - 1));
    }

    const newType = this.form.config.parts[newIndex].type;
    if (self.navigation === 'modal') {
      goingBack = this.modalFormIndex > newIndex;
      goingForward = this.modalFormIndex < newIndex;
    } else {
      goingBack = this.selectedFormIndex > newIndex;
      goingForward = this.selectedFormIndex < newIndex;
    }
    const returnTrip = this.formGroup.controls.returnTrip.value;
    const hasProduct = this.formGroup.controls['product'].value !== null;
    const fn = this.form.config.parts[newIndex].before;
    let checkPromise = Promise.resolve();

    console.log(this.form.config.parts);
    console.log(`navigate(${direction})[${newIndex}]`);
    console.log('NavigationType:', self.navigation);
    console.log(`current type: ${currentType}`);
    // console.log(`new type: ${newType}`);
    console.log(`modalFormIndex: ${this.modalFormIndex}`);
    console.log(`indexSpecified: ${indexSpecified}`);
    console.log(`goingBack: ${goingBack}`);
    console.log(`goingForward: ${goingForward}`);
    console.log(`hasProduct: ${hasProduct}`);
    console.log(`returnTrip: ${returnTrip}`);
    console.log(`productSelectionCode: ${this.productSelectionCode}`);
    console.log(`Product:`, this.formGroup.controls['product'].value);
    if (fn) {
      console.log(`fn: ${fn}`);
    }

    if (goingForward) {
      self.checkPageValidity(null, (newIndex - 1));
      console.log(self.pageStates);

    }

    if ((!self.pageStates[(newIndex - 1)] && self.navigation !== "tab") && goingForward) {
      console.error('FORM NOT VALID, NOT NAVIGATING!');
    } else {
      if (currentType === 'products' && goingBack) {
        if(hasProduct) {
          this.formGroup.controls['product'].setValue(null);
          this.formGroup.controls.returnTrip.setValue(false);
        }

        if (self.navigation === 'modal' && this.formConfig === 'widget') {
          const modal = this.nModalService.getModal(`productSelection_${this.productSelectionCode}`);
          modal.close();
        } else {
          this[(self.navigation === 'modal' ? 'modalFormIndex' : 'selectedFormIndex')] = newIndex;
        }
      } else {
        if (newType === 'products' && goingBack && !returnTrip) {
          this.pageStates[newIndex] = false;
        } else if (currentType === 'products' && !goingBack) {
          this.pageStates[newIndex] = false;
          this.processDatesForOrder();

          if(this.formGroup.controls.returnTrip.value && !this.acceptedPrice) {
            checkPromise = this.checkQuotation();
          }
        } else if(newType === 'products' && goingBack) {
          this.acceptedPrice = false;
        }
        /**
         * We need to correct the requestedDate setup
         * Are we showing the destinationdate? Then we put it in the requested date
         */
        checkPromise.then(() => {
          if(newIndex === 0 && this.state.orderMode == 'airport' && this.state.airportDestination &&
            this.formGroup.controls['requestedDestinationDate'] && this.formGroup.controls['requestedDestinationDate'].value) {
            this.formGroup.controls['requestedDate'].setValue(this.formGroup.controls['requestedDestinationDate'].value);
          }

          if (self.navigation === "tab") {
            self.nRouter.navigate([]).then(() => {
              let key = this.nState.storageKeys.values;
              let data = this.nVault.getItem(key);
              let object: any;
              object = JSON.parse(data);
              if (this.activeTab) {
                object.activeTab = this.activeTab;
              }

              if(this.form.config.navigateTo) {
                object.navigateTo = this.form.config.navigateTo;
              }

              window.open(`${environment.orderFormUrl}/${self.nState.language}/${this.form.id}?preOrder=${encodeURIComponent(JSON.stringify(object))}`, '_blank');
            });
          } else if (fn) {
            this[fn]().then(() => {
              this.modalFormIndex = newIndex;
              if (self.navigation === 'modal' && this.formStyle !== 'widget') {
                console.log('modalFormIndex:', this.modalFormIndex);
                if (this.modalFormIndex === 1) {
                  const modal = this.nModalService.getModal(`productSelection_${this.productSelectionCode}`);
                  modal.addCustomClass('productSelectionModal');
                  modal.open();
                }
              } else {
                this.selectedFormIndex = newIndex;
              }
            }, (error) => {
              console.error('ERROR IN BEFORE', error);
              this.navigationError = error;
              this.nModalService.getModal('navigationErrorModal').open();
            });
          } else {
            if (self.navigation === 'modal' && self.formStyle === 'widget' && newIndex === 0) {
              const modal = self.nModalService.getModal(`productSelection_${self.productSelectionCode}`);
              modal.addCustomClass('productSelectionModal');
              modal.open(true);
              this.modalOpen = true;
            }
            this[(self.navigation === 'modal' ? 'modalFormIndex' : 'selectedFormIndex')] = newIndex;
          }
        }).catch((error) => {
          console.groupEnd();
        });
      }
      // this.analytics.pageView(`/${this.nState.language}/${this.form.id}/${this.form.config.parts[newIndex].gtmVirtualPageUrl}`);
    }
    console.groupEnd();
  }

  closeMyModal = () => {
    const modal = this.nModalService.getModal(`productSelection_${this.productSelectionCode}`);
    modal.close();
    console.log('Closing modal');
  }

  closeModal = () => {
    const self = this;
    setTimeout(() => {
      self.modalOpen = false;
      self.formGroup.controls['product'].setValue(null);
      self.formGroup.controls.returnTrip.setValue(false);
      self.pageStates[this.modalFormIndex] = false;
      self.modalFormIndex = 0;
    }, 400);
  }

  setColors(): void {
    console.groupCollapsed('Loading color config');
    // @ts-ignore
    const defaultConfig = require('../../assets/default_css.json');

    if (this.styles.colors) {
      Object.keys(defaultConfig).forEach(key => {
        if (!this.styles.colors[key]) {
          if (defaultConfig[key].indexOf('$') > -1) {
            // console.log(`--${key}: var(--${defaultConfig[key].replace('$', '')})`);
            this.document.documentElement.style.setProperty(`--${key}`, `var(--${defaultConfig[key].replace('$', '')})`);
          } else {
            // console.log(`--${key}: ${defaultConfig[key]}`);
            this.document.documentElement.style.setProperty(`--${key}`, defaultConfig[key]);
          }
        }
      });
      var ccc = new ColorContrastChecker();
      if (this.headercolor) {
        this.styles.colors['global-title-color'] = this.headercolor;

        let color1 = "#FFFFFF";
        let color2 = this.headercolor;
        var l1 = ccc.hexToLuminance('000000'); /* higher value */
        var l2 = ccc.hexToLuminance(color1); /* lower value */
        var l3 = ccc.hexToLuminance(color2); /* lower value */

        if (ccc.isLevelAA(color1, color2, 14) || ccc.getContrastRatio(l2, l3) > 4) {
          this.styles.colors['header-nav-link-active-color-inverse'] = this.headercolor;
        } else {
          this.styles.colors['header-nav-link-active-color-inverse'] = '#000000';
          this.styles.colors['form-nav-button-text-color'] = '#000000';
          this.styles.colors['form-nav-back-button-text-color'] = '#000000';
          this.styles.colors['product-book-button-text-color'] = '#000000';
        }
      }
      if (this.textcolor) {
        this.styles.colors['header-text-color'] = this.textcolor;
        this.styles.colors['global-text-color'] = this.textcolor;
        let color1 = "#FFFFFF";
        let color2 = this.textcolor;
        if (ccc.isLevelAA(color1, color2, 14)) {
          this.styles.colors['input-text-color'] = this.textcolor;
        } else {
          this.styles.colors['input-text-color'] = '#000000';
        }
      }
      if (this.backgroundcolor) {
        this.styles.colors['form-nav-background-color'] = this.backgroundcolor;
      }
      if (!this.showlogo || this.showlogo === 'false') {
        this.styles.colors['logo-display'] = 'none';
      } else {
        this.styles.colors['logo-display'] = 'inline-grid';
      }
      if (!this.showtopbar) {
        this.styles.colors['header-display'] = 'none';
      } else {
        this.styles.colors['header-display'] = 'flow-root';
      }
      if (!this.showlogo && !this.showbreadcrumbs) {
        this.styles.colors['top-display'] = 'none';
      } else {
        this.styles.colors['top-display'] = 'block';
      }
      // console.log('#############');
      // console.log(this.showlogo);
      // console.log(this.showtopbar);
      // console.log(this.showbreadcrumbs);

      Object.keys(this.styles.colors).forEach(key => {
        if (this.styles.colors[key].indexOf('$') > -1) {
          console.log(`--${key}: var(--${this.styles.colors[key].replace('$', '')})`);
          this.document.documentElement.style.setProperty(`--${key}`, `var(--${this.styles.colors[key].replace('$', '')})`);
        } else {
          console.log(`--${key}: ${this.styles.colors[key]}`);
          this.document.documentElement.style.setProperty(`--${key}`, this.styles.colors[key]);
        }
      });
    }
    console.groupEnd();
  }

  loadCurrency() {
    const host = environment.libraryUrl;
    return this.http.get(`${host}/currencies/`, {}).subscribe((currencies: any) => {
      this.nState.currencies = currencies;
    });
  }
}
