import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-table',
  templateUrl: './global-table.component.html',
  styleUrls: ['./global-table.component.scss']
})
export class GlobalTableComponent {
  columns:Array<any>
  displayedColumns:Array<any>
  dataSource:any

  initTable(data) {
    // Get list of columns by gathering unique keys of objects found in DATA.
    const columns = data
      .reduce((columns, row) => {
        return [...columns, ...Object.keys(row)]
      }, [])
      .reduce((columns, column) => {
        return columns.includes(column)
          ? columns
          : [...columns, column]
      }, [])
    // Describe the columns for <mat-table>.
    this.columns = columns.map(column => {
      return {
        columnDef: column,
        header: column,
        cell: (element: any) => `${element[column] ? element[column] : ``}`
      }
    })
    this.displayedColumns = this.columns.map(c => c.columnDef);
    console.log(this.displayedColumns);
    // Set the dataSource for <mat-table>.
    this.dataSource = data
  }
}
