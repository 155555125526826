import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { CoolLocalStorage } from '@angular-cool/storage';
import * as CryptoJS from 'crypto-js';
import {Observable, of, Subscription, throwError} from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import {LANGUAGES} from "../data/languages";
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  jwtHelper: JwtHelperService;
  inflightAuthRequest = null;
  mForm: any;
  currentLanguage: any;

  constructor(private vault: CoolLocalStorage,
              private route: ActivatedRoute,
              private http: HttpClient) {
    this.jwtHelper = new JwtHelperService();

    this.route.data.subscribe((data: { form: any }) => {
      this.mForm = data.form;
      this.currentLanguage = LANGUAGES[this.route.snapshot.paramMap.get('language') || 'en'].split('-')[0];
    });
  }

  refreshToken(formId: string): Observable<string> {
    const url = `${environment.apiUrl}/jwt/login`;
    const secret = `${formId}`.replace(/-/g, '/').split('').reverse().join('');
    const publicSecret = `${secret}`.replace(/\//g, '+');
    const data: any = {
      timestamp: new Date().toISOString(),
      credentials: formId,
    };
    console.log(`refreshToken - Timestamp ${data.timestamp}`);

    const body = {
      credentials: `${publicSecret}#${CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString()}`
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Form-Id': formId,
      'X-Ignore-Auth': 'true',
    });

    return this.http
      .post(url, body, {headers})
      .pipe(
        share(), // <========== YOU HAVE TO SHARE THIS OBSERVABLE TO AVOID MULTIPLE REQUEST BEING SENT SIMULTANEOUSLY
        map((res: any) => {
          this.vault.setItem(`#t-#${formId}`, res.token);
          return res.token;
        })
      );
  }

  getToken(formId: string): Observable<string> {
    const token = this.vault.getItem(`#t-#${formId}`);
    if (!token || token === 'undefined') {
      return this.refreshToken(formId);
    }
    const isTokenExpired = this.jwtHelper.isTokenExpired(token);
    if (!isTokenExpired) {
      return of(token);
    }

    return this.refreshToken(formId);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('X-Ignore-Auth') === 'true') {
      // console.log(`intercept() - IGNORING AUTH FOR ${req.url}`);
      return next.handle(req);
    }

    const formId = req.headers.get('X-Form-Id');
    if (!formId) {
       return next.handle(req);
    }

    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = this.getToken(formId);
    }

    return this.inflightAuthRequest.pipe(
      switchMap((newToken: string) => {
        // unset request inflight
        this.inflightAuthRequest = null;
        // use the newly returned token
        const authReq = req.clone({
          headers: req.headers.set('Authorization', newToken ? `Bearer ${newToken}` : '').delete('X-Form-Id')
        });

        return next.handle(authReq);
      }),
      catchError(error => {
        // checks if a url is to an admin api or not
        if (error.status === 401) {
          // check if the response is from the token refresh end point
          const isFromRefreshTokenEndpoint = !!error.headers.get(
            'unableToRefreshToken'
          );

          if (isFromRefreshTokenEndpoint) {
            // localStorage.clear();
            // this.router.navigate(['/sign-page']);
            return throwError(error);
          }

          if(error && error.error && error.error.error.name === 'USER_NOT_FOUND') {
            this.vault.removeItem(`#t-#${formId}-user`);
            this.vault.clear();
            document.location = `${this.currentLanguage}/${formId}/`;
            return throwError(error);
          }

          if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = this.refreshToken(formId);

            if (!this.inflightAuthRequest) {
              // remove existing tokens
              // localStorage.clear();
              // this.router.navigate(['/sign-page']);
              return throwError(error);
            }
          }

          return this.inflightAuthRequest.pipe(
            switchMap((newToken: string) => {
              // unset inflight request
              this.inflightAuthRequest = null;

              // clone the original request
              const authReqRepeat = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${newToken}`).delete('X-Form-Id')
              });

              // resend the request
              return next.handle(authReqRepeat);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }
}
