import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { Field } from '../../interfaces/field';
import { FieldErrorService } from '../../services/field-error.service';
import { FieldError } from '../../interfaces/field-error';
import { Subscription } from 'rxjs';
import {StateService} from "../../services/state.service";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./field-error.component.scss']
})
export class FieldErrorComponent implements OnInit, OnDestroy {
  @Input() config: Field;
  @Output() validityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openHelpModal: EventEmitter<any> = new EventEmitter<any>();

  private errorSubscription: Subscription;
  public errors: FieldError[] = [];
  public erroneous = false;

  constructor(
    private service: FieldErrorService,
    public state: StateService,
  ) {}

  ngOnInit() {
    this.errorSubscription = this.service.$errors.subscribe(() => {
      this.erroneous = this.service.fieldHasErrors(this.config.property);
      this.validityChanged.emit(this.erroneous);
      this.errors = this.service.getFieldErrors(this.config.property);
    });
  }

  ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  toggleHelpModal(helpTitleTag: string, helpTextTag: string) {
    this.openHelpModal.emit({
      helpTitleTag,
      helpTextTag,
    });
  }

  protected readonly faInfoCircle = faInfoCircle;
}
