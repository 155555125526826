import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Field} from '../../interfaces/field';
import {UntypedFormGroup} from '@angular/forms';
import {FieldErrorService} from '../../services/field-error.service';

@Component({
  selector: 'app-flight-number-field',
  templateUrl: './flight-number-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./flight-number-field.component.scss']
})
export class FlightNumberFieldComponent implements OnInit {
  @Input() config: Field;
  @Input() control: UntypedFormGroup;
  hasErrors = false;
  value = '';

  constructor(private errorService: FieldErrorService) {
  }

  ngOnInit() {
    // console.log(this.config.options);
    // console.log(this.config.showIf);
  }

  onValueChanged(event: any): void {
    // console.log(`onValueChanged`, event);
    const formatErrorId = `${this.config.property}InvalidFormat`;
    const flightNumberErrorId = 'flightNumberRequired';
    const returnFlightNumberErrorId = 'returnFlightNumberRequired';
    this.value = event.target.value;
    if (this.config.options && this.config.options.enforceFormat) {
      this.value = event.target.value.toUpperCase().replace(/ /g, '');
    }
    if (!this.isValid() && this.config.options && this.config.options.enforceFormat) {
      console.error('INVALID FLIGHT NUMBER');
      this.errorService.addError({
        id: formatErrorId,
        property: this.config.property,
        text: 'flight_number_field_error_invalid_format',
        label: this.config.label,
      });
    } else {
      this.control.controls[this.config.property].setValue(this.value);
      if (this.errorService.fieldHasErrors(this.config.property)) {
        this.errorService.removeErrors([formatErrorId, flightNumberErrorId, returnFlightNumberErrorId]);
      }
    }
  }

  isValid(): boolean {
    if (this.value === '' && !this.config.required) {
      return true;
    }
    const regex = /^[A-Z]{2,3}\d{3,4}$/g;
    return regex.test(this.value);
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }
}
