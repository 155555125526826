<div class="{{config.property}} {{config.type}} {{erroneous ? 'active' : ''}}" *ngIf="this.state.orderPosted ||
config.property !== 'acceptTermsAndConditions'">
  <ng-container *ngFor="let error of errors">
    <span *ngIf="error.text" [class]="(error.helpText === 'address_field_error_work_area' ? 'inline' : '')"
          (click)="(error.helpText ? toggleHelpModal(error.text, error.helpText) : null)">{{error.text | translate | ucFirst}}</span>
    <span *ngIf="error.helpText && error.helpText === 'address_field_error_work_area'" class="wb-help" (click)="toggleHelpModal(error.text, error.helpText)">
      <span class="underline inline">{{'address_field_error_work_area_link' | translate | ucFirst}}</span>
    </span>
  </ng-container>
</div>
