import {Component, Inject, Input, NgZone, Renderer2, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AsyncTranslationLoader} from '../../app/async-translation-loader';
import {StateService} from '../../app/services/state.service';
import {ApiService} from '../../app/services/api.service';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {FieldErrorService} from '../../app/services/field-error.service';
import {AnalyticsService} from '../../app/services/analytics.service';
import {Title} from '@angular/platform-browser';
import {VisitorService} from '../../app/services/visitor.service';
import {LANGUAGES} from '../../app/data/languages';
import {CustomerService} from "../../app/services/customer.service";
import {InlineWebbookerComponent} from "../inline-webbooker/inline-webbooker.component";

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};

interface Styles {
  colors: any;
}

declare global {
  interface Window {
    initMyOrder(arg:string, arg1: number);
  }
}

@Component({
  selector: 'app-widget',
  templateUrl: '../../app/form/order/order.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./popup-widget.component.scss',
    '../../app/form/order/order.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    ApiService, CustomerService
  ]
})
export class PopupWidgetComponent extends InlineWebbookerComponent {
  formStyle = 'widget';
  @Input() formid: string;
  @Input() language: string;
  navigation = 'modal';
  @Input() buttonbgcolor: string = '#000000';
  @Input() buttontextcolor: string = '#ffffff' ;
  @Input() buttontext: string = 'process_order_button';
  @Input() buttonposition: string = 'topRight';
  @Input() buttonstyle: string = 'rounded';
  @Input() widgettype: string = 'popup-widget';

  @Input() showtopbar: boolean = true;
  @Input() showbreadcrumbs: boolean = true;
  @Input() showlogo: boolean = true;
  @Input() showclose: boolean = true;
  @Input() headercolor: string = '#000000';
  @Input() textcolor: string = '#000000';
  @Input() backgroundcolor: string = '#FFFFFF';

  constructor(private zone: NgZone,
              http: HttpClient,
              activatedRoute: ActivatedRoute,
              apiService: ApiService,
              modalService: NgxSmartModalService,
              translate: TranslateService,
              vault: CoolLocalStorage,
              router: Router,
              route: ActivatedRoute,
              state: StateService,
              errorService: FieldErrorService,
              analytics: AnalyticsService,
              pageTitle: Title,
              visitorService: VisitorService,
              _customerService: CustomerService,
              _renderer2: Renderer2,
              @Inject('Window') window: Window,
              @Inject(DOCUMENT) document: Document,) {
    super(http, activatedRoute, apiService, modalService, translate, vault, router, route,
      state, errorService, analytics, pageTitle, visitorService, _customerService, _renderer2, window, document);

    const availableLanguages = [];
    Object.keys(LANGUAGES).forEach(key => {
      availableLanguages.push(LANGUAGES[key]);
    });
    // Add available languages
    translate.addLangs(availableLanguages);
    state.translationId$.subscribe(id => {
      if (id !== '') {
        AsyncTranslationLoader.translationIdSubject.next(id);
      }
    });

    this.isWidget = true;
    this.nState = state;
    this.nTranslate = translate;
    this.nModalService = modalService;
    this.nActivatedRoute = activatedRoute;
    this.nRouter = router;
    this.nVault = vault;
    this.loadCurrency();

    if ( this.nActivatedRoute.snapshot && this.nActivatedRoute.parent) {
      const dataPointer = (this.nActivatedRoute.snapshot.params.formId ? this.nActivatedRoute.data : this.nActivatedRoute.parent.data);
      this.formSubscription = dataPointer.subscribe((data: { form: any }) => {
        // this.form = data.form;
        this.formId = data.form.id;
        // this.bgcolor = this.nActivatedRoute.snapshot.paramMap.get('bgColor');
        // this.textcolor = this.nActivatedRoute.snapshot.paramMap.get('textColor');
        // this.buttontext = this.nActivatedRoute.snapshot.paramMap.get('buttontext');
        // this.position = (this.nActivatedRoute.snapshot.paramMap.get('position') ? this.nActivatedRoute.snapshot.paramMap.get('position') : 'bottomRight');
        this.formId = data.form.id;
        this.language = LANGUAGES[this.nActivatedRoute.snapshot.paramMap.get('language') || translate.getBrowserLang()];
        const currentLanguage = this.nState.language;
        this.nTranslate.setDefaultLang(currentLanguage);
        if(!this.loadingForm) {
          this.loaded = true;
          this.loadForm();
        }
      });
    }

    window.initMyOrder = () => {
      // Since this function runs outside Angular's zone, we need to get back inside!
      this.zone.run(() => {
        // Put angular code that has to be called on click on the link in the popover here...
        this.navigate('next', 0);
      });
    }
  }
}
