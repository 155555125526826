import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {Field, ProductMutation} from '../../interfaces/field';
import {faInfoCircle, faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {StateService} from "../../services/state.service";
import {CurrencyPipe} from "@angular/common";
import {UnicornPipe} from "../../pipes/unicorn.pipe";

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./number-field.component.scss'],
  providers: [CurrencyPipe, UnicornPipe]
})
export class NumberFieldComponent implements OnInit {
  @Input() config: Field;
  @Input() control: UntypedFormGroup;
  @Output() openHelpModal: EventEmitter<any> = new EventEmitter<any>();

  hasErrors = false;
  placeholder = '';
  description = '';
  productMutation: ProductMutation;

  min: number;
  max: number;
  faMinus = faMinus;
  faPlus = faPlus;

  constructor(private translate: TranslateService,
              private state: StateService,
              private currencyPipe: CurrencyPipe,
              private unicornPipe: UnicornPipe) {
  }

  ngOnInit() {
    this.min = this.config.min || 0;
    this.max = this.config.max || undefined;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.defineDescription();
    });
    this.defineDescription();
  }


  defineDescription(): void {
    this.translate.get(this.config.label).subscribe(placeholder => {
      this.placeholder = placeholder;

      if (this.config.product) {
        const amount = this.config.product.amount;
        if (this.config.product && this.config.product.type === 'airportDepartureJob') {
          if(this.state.airportDestination && !this.control.controls['returnTrip'].value) {
            delete this.config.product;
          }
        } else if(this.config.product && this.config.product.type === 'airportDestinationJob') {
          if(this.state.airportDeparture && !this.control.controls['returnTrip'].value) {
            delete this.config.product;
          }
        }
      }

      if (this.config.product) {
        this.productMutation = this.config.product;
        const amount = (this.control.controls['returnTrip'].value && this.productMutation.returnAmount ?
          this.productMutation.returnAmount :
          this.productMutation.amount);
        const amountFormatted: any = this.currencyPipe.transform((amount / 100), this.state.currency, 'symbol', '1.2-2');
        this.description = `${this.placeholder} (${amountFormatted})`;
      } else {
        this.description = this.placeholder;
      }
    });
  }


  mutate(add: boolean = true) {
    const value = this.control.controls[this.config.property].value;
    const newValue = (add ? value + 1 : value - 1);
    const update = {};
    update[this.config.property] = newValue;
    if (add && newValue <= this.max) {
      this.control.patchValue(update);
    } else if (!add && newValue >= this.min) {
      this.control.patchValue(update);
    }
  }

  toggleHelpModal(helpTitleTag: string, helpTextTag: string, type?: string, meta?: any) {
    this.openHelpModal.emit({
      helpTitleTag,
      helpTextTag,
      type,
      meta,
    });
  }

  protected readonly faInfoCircle = faInfoCircle;
}
