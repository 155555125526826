<span class="wb-summary-name">
  {{description | ucFirst}}
  <span *ngIf="config.helpText" class="wb-help"
        (click)="toggleHelpModal(config.label, config.helpText)"><fa-icon [icon]="faInfoCircle"></fa-icon>
  </span>
</span>
<div [formGroup]="control">
  <div class="subtract" (click)="mutate(false)"><fa-icon [icon]="faMinus"></fa-icon></div>
  <input type="number" id="{{config.property}}" [min]="min" [max]="max" [formControlName]="config.property" />
  <div class="add" (click)="mutate()"><fa-icon [icon]="faPlus"></fa-icon></div>
</div>
