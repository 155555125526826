import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { Field } from '../../interfaces/field';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { FieldErrorService } from '../../services/field-error.service';

declare const intlTelInput: any;
declare const intlTelInputUtils: any;

@Component({
  selector: 'app-phone-field',
  templateUrl: './phone-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./phone-field.component.scss']
})
export class PhoneFieldComponent implements OnInit, AfterViewInit {
  @Input() config: Field;
  @Input() formControlName: UntypedFormControl;
  @Input() control: UntypedFormGroup;

  input: HTMLElement;
  iti: any;
  isBlurred: boolean;
  phoneNumber: string;
  valid: boolean;
  hasErrors = false;

  constructor(private errorService: FieldErrorService) {
    this.phoneNumber = '';
  }

  ngOnInit() {
    this.valid = true;
  }

  validateAndPatchValue(event: any): void {
    // console.groupCollapsed('PhoneNumber validateAndPatchValue', this.config.property)

    if(this.iti.getNumber(intlTelInputUtils.numberFormat.E164)) {
      this.phoneNumber = this.iti.getNumber(intlTelInputUtils.numberFormat.E164).replace(/ /g, '');
      this.valid = this.iti.isValidNumber();
      if(typeof(this.phoneNumber['phoneNumber']) !== 'undefined') {
        this.valid = false;
      }

      const errorId = `${this.config.property}Invalid`;
      if (this.phoneNumber !== '') {
        this.errorService.removeError(`${this.config.property}Required`);
      }

      if(this.isBlurred) {
        if (!this.valid) {
          this.errorService.addError({
            id: errorId,
            property: this.config.property,
            text: 'phone_number_field_error_invalid',
            label: this.config.label,
          });
        } else {
          this.errorService.removeError(errorId);
        }
      }

      const patch = {};
      if(this.valid) {
        patch[this.config.property] = this.valid ? this.phoneNumber : '';
        patch[this.config.property+'Country'] = this.iti.getSelectedCountryData().iso2;
        this.control.patchValue(patch);
      }
    }
    // console.groupEnd();
  }

  ngAfterViewInit(): void {
    const self = this;
    this.input = document.querySelector(`#phone-field-${this.config.property} input[type=tel]`);
    if (this.input) {
      this.input.addEventListener('countrychange', this.validateAndPatchValue.bind(this));

      if(!this.config.defaultCountry) {
        this.config.defaultCountry = 'nl';
      } else if(this.config.defaultCountry.toLowerCase() === 'en') {
        this.config.defaultCountry = 'gb';
      }

      this.iti = intlTelInput(this.input, {
        utilsScript: '//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/19.5.5/js/utils.js',
        preferredCountries: [],
        initialCountry: this.config.defaultCountry,
        separateDialCode: true,
        formatOnDisplay: false,
      });
    } else {
      setTimeout(function(){
        self.ngAfterViewInit();
      },100)
    }
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }

}
