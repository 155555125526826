import { Component } from '@angular/core';

@Component({
  selector: 'app-driver-signup',
  templateUrl: './driver-signup.component.html',
  styleUrls: ['./driver-signup.component.scss']
})
export class DriverSignupComponent {

}
