import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import { environment } from '../../environments/environment';
import {StateService} from './state.service';
import {mergeMap, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrencyResolverService {
  constructor(private http: HttpClient, private state: StateService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const host = environment.libraryUrl ;
    return this.http.get(`${host}/currencies/`, {})
      .pipe(
        mergeMap((currencies: any[]) => {
          this.state.currencies = currencies;
          return currencies;
        })
      );
  }
}
