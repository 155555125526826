import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { Field } from '../../interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { FieldErrorService } from '../../services/field-error.service';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent implements OnInit {
  @Input() config: Field;
  @Input() control: UntypedFormGroup;
  hasErrors = false;

  constructor(private errorService: FieldErrorService) { }

  ngOnInit() {
  }

  selectionChanged(event: any) {
    if (event.target.value !== '') {
      this.errorService.removePropertyErrors(this.config.property);
    }
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }

}
