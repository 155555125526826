import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {Observable, of} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {MapDirectionsService} from "@angular/google-maps";
import {StateService} from "../../services/state.service";
import {CoolLocalStorage} from "@angular-cool/storage";
import {StorageMap} from "@ngx-pwa/local-storage";

@Component({
  selector: 'app-preview-route-map',
  templateUrl: './preview-route-map.component.html',
  styleUrls: ['./preview-route-map.component.scss']
})
export class PreviewRouteMapComponent implements OnInit {
  data: any;
  googleMapsLoaded = false;
  currentActiveRouteKey: string;

  @Input() parts: any;
  @Input() formGroup: UntypedFormGroup;
  @Input() form: any = {config: {parts: []}};
  @Input() metrics: any;
  @Input() buttonstyle: string;

  directionsResults: Observable<google.maps.DirectionsResult | undefined>;
  options: any = {
    zoom: 14,
    disableDefaultUI: true,
  }
  directionsRenderOption: any;
  departureMarker: any;
  stopOverMarker: any;
  destinationMarker: any;
  calculating=false;
  markerPositions: google.maps.LatLngLiteral[] = [];

  constructor(
    private mapDirectionsService: MapDirectionsService,
    private _state: StateService,
    private _vault: StorageMap,
  ) {
  }

  ngOnInit(): void {
    this.data = this.formGroup.controls;
    this.detectGoogleMapsLoaded();
    console.log('ngOnInit');
  }

  detectGoogleMapsLoaded() {
    const self = this;
    if (!this.googleMapsLoaded) {
      if (typeof (google) !== 'undefined') {
        this.googleMapsLoaded = true;
        if (this.data.departureModel && this.data.departureModel.value && this.data.departureModel.value.gps && this.data.departureModel.value.gps.lat && this.data.departureModel.value.gps.lng) {
          self.recalculateRoute();
        }
      } else {
        setTimeout(function () {
          self.detectGoogleMapsLoaded();
        }, 1000);
      }
    }
  }

  recalculateRoute() {
    // console.log('recalculateRoute');
    if(!this.calculating) {
      this.calculating = true;
      if(this.googleMapsLoaded) {
        const self = this;
        if(this.data.departureModel && this.data.departureModel.value && this.data.departureModel.value.gps && this.data.departureModel.value.gps.lat && this.data.departureModel.value.gps.lng) {
          this.options.center = {
            lat: this.data.departureModel.value.gps.lat,
            lng: this.data.departureModel.value.gps.lng
          };
        }

        if (this.data.departureModel && this.data.departureModel.value && this.data.departureModel.value.gps && this.data.departureModel.value.gps.lat && this.data.departureModel.value.gps.lng && this.data.destinationModel && this.data.destinationModel.value && this.data.destinationModel.value.gps && this.data.destinationModel.value.gps.lat && this.data.destinationModel.value.gps.lng) {
          const request: google.maps.DirectionsRequest = {
            destination: {
              lat: this.data.destinationModel.value.gps.lat,
              lng: this.data.destinationModel.value.gps.lng
            },
            origin: {
              lat: this.data.departureModel.value.gps.lat,
              lng: this.data.departureModel.value.gps.lng
            },
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: (this.form.config.distanceFormat  === 'km'? google.maps.UnitSystem.METRIC : google.maps.UnitSystem.IMPERIAL),
          };
          const stopOvers = this.getStopOvers();
          if (stopOvers) {
            request.waypoints = [];
            stopOvers.forEach((stopOver) => {
              if(stopOver.value) {
                request.waypoints.push({
                  location: {
                    lat: stopOver.value.gps.lat,
                    lng: stopOver.value.gps.lng
                  }
                });
                }
            });
          }

          // const key = `${this.data.destinationModel.value.gps.lat},${this.data.destinationModel.value.gps.lng}-${this.data.departureModel.value.gps.lat},${this.data.departureModel.value.gps.lng}`;

          const serializeKey = {
            departure: this.data.departureModel.value.gps,
            destination: this.data.destinationModel.value.gps,
            stopOvers: this.data.stopOvers.value
          }

          const key = JSON.stringify(serializeKey);

          //Store current key in object to detect changes
          this.currentActiveRouteKey = key;

          self._vault.get('directions').subscribe((directions: any) => {
            if (!directions || !directions[key]) {
              if(!directions) {
                directions = {};
              }
              directions[key] = 'loading';
              this._vault.set('directions', directions).subscribe(() => { });
              this.directionsResults = this.mapDirectionsService.route(request)
                .pipe(
                  map(response => response.result)
                )
              this.directionsResults.subscribe((finalResult: google.maps.DirectionsResult) => {
                console.log('loading from api', key);
                directions[key] = finalResult;

                if(this._state.companySettings.distanceFormat === 'mi') {
                  /**
                   * Convert distance from meters to miles
                   */
                  directions[key].routes[0].legs[0].distance.value = (directions[key].routes[0].legs[0].distance.value * 0.000621371192).toFixed(1);
                } else {
                  directions[key].routes[0].legs[0].distance.value = (directions[key].routes[0].legs[0].distance.value/1000).toFixed(1);
                }
                directions[key].routes[0].legs[0].distance.value = Math.round(directions[key].routes[0].legs[0].distance.value * 100) / 100

                directions[key].routes[0].legs[0].distance.value = (this._state.companySettings.decimalSeparator === '.' ? directions[key].routes[0].legs[0].distance.value : directions[key].routes[0].legs[0].distance.value.toString().replace('.', ','));

                //set Distance and duration to metrics
                if (directions && directions[key]) {
                  this.metrics = {
                    distance: directions[key].routes[0].legs[0].distance.value,
                    duration: directions[key].routes[0].legs[0].duration.value,
                    distanceDesc: directions[key].routes[0].legs[0].distance.value,
                    durationDesc: Math.ceil(directions[key].routes[0].legs[0].duration.value/60),
                  };
                }
                directions[key].metrics = this.metrics;

                directions[key] = JSON.parse(JSON.stringify(directions[key]));
                this._vault.set('directions', directions).subscribe((result) => {
                  this.calculating = false;
                })
              })
            } else {
              if (directions[key] === 'loading') {
                setTimeout(function(){
                  self._vault.get('directions').subscribe((directions: any) => {
                    if (directions[key] && directions[key] !== 'loading') {
                      console.log(directions[key]);
                      self.metrics = directions[key].metrics;
                      //load directions from state as observable
                      self.directionsResults = of(directions[key]);
                    } else {
                      directions[key] = null;
                      self._vault.set('directions', directions).subscribe(() => {
                        self.calculating = false;
                        self.recalculateRoute();
                      });
                    }
                  });
                }, 2000)
              } else {
                this.metrics = directions[key].metrics;
                //load directions from state as observable
                this.directionsResults = of(directions[key]);
                this.calculating = false;
              }
            }
          });
        } else {
          this.directionsResults = null;
          this.calculating = false;
        }

        this.directionsRenderOption = {
          draggable: false,
          suppressInfoWindows: true,
          markerOptions: {
            draggable: false,
            clickable: false,
            visible: false,
          },
          polylineOptions: {
            strokeColor: '#6a77e0',
            strokeOpacity: 0,
            icons: [{
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillOpacity: 1,
                scale: 3
              },
              offset: '0',
              repeat: '1px'
            }],
          }
        };
        this.departureMarker = {
          draggable: false,
          clickable: false,
          icon: {
            url: 'assets/icon-location-departure.svg',
            scaledSize: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15)
          }
        };
        this.stopOverMarker = {
          draggable: false,
          clickable: false,
          icon: {
            url: 'assets/icon-location-stopover.svg',
            scaledSize: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15)
          }
        };
        this.destinationMarker = {
          draggable: false,
          clickable: false,
          icon: {
            url: 'assets/icon-location-destination.svg',
            scaledSize: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 15)
          }
        };
      }
    }
  }

  getStopOvers() {
    // @ts-ignore
    return this.formGroup.controls['stopOversModel'].controls;
  }
}
