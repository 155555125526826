import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {UcFirstPipe, UcFirstPipeModule} from '../pipes/uc-first.pipe';
import { HtmlPipeModule } from '../pipes/html.pipe';
import { UnicornPipeModule } from '../pipes/unicorn.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {FieldWrapperComponent} from '../form/field-wrapper/field-wrapper.component';
import {FormComponent} from '../form/form.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FieldErrorComponent} from '../form/field-error/field-error.component';
import {CheckboxFieldComponent} from '../form/checkbox-field/checkbox-field.component';
import {SelectFieldComponent} from '../form/select-field/select-field.component';
import {PhoneFieldComponent} from '../form/phone-field/phone-field.component';
import {FlightNumberFieldComponent} from '../form/flight-number-field/flight-number-field.component';
import {CouponCodeFieldComponent} from '../form/coupon-code-field/coupon-code-field.component';
import {PaymentMethodFieldComponent} from '../form/payment-method-field/payment-method-field.component';
import {DateTimeFieldComponent} from '../form/date-time-field/date-time-field.component';
import {AddressFieldComponent} from '../form/address-field/address-field.component';
import {NumberFieldComponent} from '../form/number-field/number-field.component';
import {FieldValueComponent} from '../form/field-value/field-value.component';
import {Ng2FlatpickrModule} from 'ng2-flatpickr';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {BrowserModule} from '@angular/platform-browser';
import {SummaryComponent} from '../form/summary/summary.component';
import {SidebarSummaryComponent} from '../form/sidebar-summary/sidebar-summary.component';
import {ProductSelectionComponent} from '../form/product-selection/product-selection.component';
import {ConfirmationComponent} from '../form/confirmation/confirmation.component';
import {OrderComponent} from '../form/order/order.component';
import {LoggedinMenuComponent} from "../dashboard/loggedin-menu/loggedin-menu.component";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {GoogleMapsModule} from "@angular/google-maps";
import {PreviewRouteMapComponent} from "../form/preview-route-map/preview-route-map.component";
import {KeepHtmlPipeModule} from "../pipes/keep-html.pipe";
import { SortableAddressFieldComponent } from '../form/sortable-address-field/sortable-address-field.component';
import {CountrySelectComponent} from "../global/country-select/country-select.component";

@NgModule({
  declarations: [
    FieldWrapperComponent,
    ProductSelectionComponent,
    ConfirmationComponent,
    OrderComponent,
    FormComponent,
    FieldErrorComponent,
    CheckboxFieldComponent,
    SelectFieldComponent,
    PhoneFieldComponent,
    FlightNumberFieldComponent,
    PaymentMethodFieldComponent,
    CouponCodeFieldComponent,
    DateTimeFieldComponent,
    AddressFieldComponent,
    NumberFieldComponent,
    FieldValueComponent,
    SummaryComponent,
    SidebarSummaryComponent,
    LoggedinMenuComponent,
    CountrySelectComponent,
    PreviewRouteMapComponent,
    SortableAddressFieldComponent
  ],
    imports: [
        CommonModule,
        BrowserModule,
        Ng2FlatpickrModule,
        NgxSmartModalModule.forChild(),
        AutocompleteLibModule,
        ReactiveFormsModule,
        TranslateModule,
        UcFirstPipeModule,
        HtmlPipeModule,
        UnicornPipeModule,
        FontAwesomeModule,
        RouterModule,
        FormsModule,
        MatIconModule,
        MatInputModule,
        GoogleMapsModule,
        KeepHtmlPipeModule,
    ],
  exports: [
    FieldWrapperComponent,
    ProductSelectionComponent,
    SummaryComponent,
    SidebarSummaryComponent,
    LoggedinMenuComponent,
    CountrySelectComponent,
    ConfirmationComponent,
    PreviewRouteMapComponent,
    AddressFieldComponent
  ]
})
export class SharedModule { }
