<div class="wb-text {{status}} wb-modal-status-{{navigation}}" *ngIf="status!=='inline'">
  <img src="https://yourwebbooker.com/assets/icon-order-{{(status === statuses.FAILED ? 'failed' : 'success')}}.svg"
       alt="{{status}}">
  <h3>{{'order_confirmation_title_'+status | translate | ucFirst}}</h3>

  <p *ngIf="getInnerHTMLValue()" [innerHTML]="getInnerHTMLValue()"></p>

  <img *ngIf="logo && false" src="{{logo}}" alt="logo" class="logo">
</div>
<div class="wb-tools">
  <a class="wb-button" *ngIf="status === statuses.FAILED"
     href="javascript:void(0)" (click)="retryPayment()">{{'order_confirmation_try_again_failed_payment' | translate |
    ucFirst}}</a>
  <a href="javascript:void(0)" (click)="bookNewRide()"
     *ngIf="navigation!=='modal'">{{'order_confirmation_book_new_ride' | translate | ucFirst}}</a>

  <a href="javascript:void(0)" (click)="parent.setup();parent.closeMyModal()"
     *ngIf="navigation==='modal'">{{'close' | translate | ucFirst}}</a>
</div>
