import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {StateService} from "../../services/state.service";
import {CustomerService} from "../../services/customer.service";

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    CustomerService
  ]
})
export class HistoryComponent implements OnInit {
  debtorFilter = 'all';
  loader = false;
  formGroup: UntypedFormGroup;
  formSubscription: Subscription;
  debtors: any[] = [];
  form: any = {config: {parts: [], widgetParts: []}};
  selected = '';
  sortBy = 'requestedDateStamp';
  // sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  filterData: any;
  fromRow = 1;
  currentPage = 1;
  pageSize = 10;
  data: any;
  filteredData: any = [];
  allSelected = false;
  multiSelectBox = [];
  columnConfig = {
    'departure': {
      'type': 'address',
      'sort': false,
      'noRowClick': true,
    },
    'requestedDate': {
      'type': 'date',
      'noRowClick': true,
      'actions': {
        'open': this.open
      },
      'sort': false,
    },
    'receipt': {
      type: 'link',
      'noRowClick': true,
      actions: {
        'open': this.open
      },
      sort: false,
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private _customerService: CustomerService,
    // private _dataTableService: TdDataTableService,
  ) {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(''),
      password: new UntypedFormControl(''),
    });

    let dataPointer = (this.activatedRoute.snapshot.params.formId ? this.activatedRoute.data : this.activatedRoute.parent.data);
    this.formSubscription = dataPointer.subscribe((data: { form: any }) => {
      this.form = data.form;
    });

    this.loadData();
  }

  ngOnInit(): void {
  }

  loadData(): void {
    const self = this;
    let query: any = {};

    if (!self.debtors[0]) {
      self._customerService.getDebtorRoles()
        .subscribe((debtors: any) => {
          self.debtors = debtors.filter((d) => {
            return (d.Debtor);
          });
        });
    }

    if (this.debtorFilter !== 'all') {
      query = {
        where: {
          debtorId: this.debtorFilter
        }
      };
      this.getDebtorRides(query);
    } else {
      this._customerService.getHistory(query).subscribe((data: any) => {
        data = data.map((j) => {
          let map = {
            // requestedDateStamp: new Date(j.requestedDate).getTime(),
            reference: j.reference,
            // debtor: (j.Debtor ? j.Debtor.companyName : '-'),
            requestedDate: j.requestedDate,
            departure: (j.departure.internationalAlias ? j.departure.internationalAlias : `${j.departure.city}, ${j.departure.street}, ${j.departure.houseNumber}`),
            passenger: (j.passenger ? `${j.passenger.fname} ${j.passenger.lname}` : ''),
            status: j.status,
            // paymentMethod: `payment_method_${j.paymentMeta.origin.toLowerCase()}`,
            // amount: j.price,
            receipt: j.resources.receiptSummaryUrl,
          }
          if (this.debtorFilter !== 'all') {
            delete map['debtor'];
          } else {
            delete map['status'];
          }
          return map
        });
        this.getDebtorRides(query, data);
      }, (error) => {
        console.log(error);
      });
    }
  }

  getDebtorRides(query: any, dataRS: any[] = []) {
    query.includes = ['Debtor'];
    this._customerService.getDebtorRides(query).subscribe((data: any) => {
      this.data = dataRS.concat(data.map((j) => {
        let map = {
          // requestedDateStamp: new Date(j.requestedDate).getTime(),
          reference: j.reference,
          // debtor: (j.Debtor ? j.Debtor.companyName : '-'),
          requestedDate: j.requestedDate,
          departure: (j.departure.internationalAlias ? j.departure.internationalAlias : `${j.departure.city}, ${j.departure.street}, ${j.departure.houseNumber}`),
          passenger: (j.passenger ? `${j.passenger.fname} ${j.passenger.lname}` : ''),
          status: j.status,
          // paymentMethod: `payment_method_${j.paymentMeta.origin.toLowerCase()}`,
          // amount: j.price,
          receipt: j.resources.receiptSummaryUrl
        }
        if (this.debtorFilter !== 'all') {
          delete map['debtor'];
        } else {
          delete map['status'];
        }
        return map;
      }));
    });
  }

  toggleMultiActionAll(): void {
    const self = this;
    self.data.forEach((ride) => {
      self.multiSelectBox[ride.id] = (self.allSelected);
    });
    self.toggleMultiAction();
  }

  toggleMultiAction(): void {
  }

  open(url: string): void {
    if(url) {
      window.open(url, '_blank');
    }
  }

  // sort(sortEvent: ITdDataTableSortChangeEvent): void {
  //   this.sortBy = sortEvent.name;
  //   this.sortOrder = sortEvent.order;
  //   this.filter(false, true);
  // }

  // filter(firstLoad: boolean = false, sorting: boolean = false): void {
  //   let filteredData: any[] = this.data;
  //   // filteredData = this._dataTableService.filterData(filteredData, this.searchTerm, true);
  //   filteredData = this._dataTableService.sortData(filteredData, this.sortBy, this.sortOrder);
  //   filteredData = this._dataTableService.pageData(filteredData, this.fromRow, this.currentPage * this.pageSize);
  //   this.filteredData = filteredData;
  // }

  selectionChanged() {
    this.loadData();
  }

  // page(pagingEvent: IPageChangeEvent): void {
  //   this.fromRow = pagingEvent.fromRow;
  //   this.currentPage = pagingEvent.page;
  //   this.pageSize = pagingEvent.pageSize;
  //   this.filter(false, true);
  // }
}
