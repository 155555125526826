<div class="loader-overlay" *ngIf="loader"></div>
<span id="loading" *ngIf="loader"></span>

<div class="wb-form-container" autocomplete="off">
  <div class="title-pane">
    <h1>
      {{'your_invoices_title' | translate | ucFirst}}
    </h1>
    <div *ngIf="data && data.length > 0">
      {{'your_invoices_subtitle' | translate | ucFirst}}
    </div>
    <div *ngIf="data && data.length === 0">
      {{'no_invoices_available' | translate | ucFirst}}
    </div>
  </div>

  <div class="select-pane" *ngIf="debtors[0] && data && data.length > 0">
    <select name="debtor-selector" class="table-selector" [(ngModel)]="debtorFilter" (change)="selectionChanged()">
      <option value="all">{{'show_all_debtors'|translate|ucFirst}}</option>
      <option *ngFor="let debtor of debtors" value="{{debtor.Debtor.id}}" [selected]="this.selected === debtor.Debtor.id">
        {{(debtor.Debtor && debtor.Debtor.companyName ? debtor.Debtor.companyName : '')}}
      </option>
    </select>
    <svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Book-your-trip" transform="translate(-108.000000, -498.000000)" fill="#FFFFFF">
          <polygon id="Triangle"
                   transform="translate(115.000000, 503.000000) rotate(-180.000000) translate(-115.000000, -503.000000) "
                   points="115 498 122 508 108 508"></polygon>
        </g>
      </g>
    </svg>
  </div>

  <div *ngIf="data && data.length > 0">
    <app-dynamic-table [data]="data" [pageSize]="10" [columnConfig]="columnConfig" [rowClick]="this.open"></app-dynamic-table>
  </div>
</div>
