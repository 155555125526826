<div class="loader-overlay" *ngIf="loader"></div>
<span id="loading" *ngIf="loader"></span>

<form [formGroup]="formGroup" class="wb-form-container" autocomplete="off">
  <!-- First field is to throw off browsers from using autocomplete -->
  <input autocomplete="off" name="hidden" type="text" style="display:none;">
  <h2>
    {{ 'signup' | translate | ucFirst }}
  </h2>
  <div>
    {{ 'signup_sub_title' | translate | ucFirst }}
  </div>

  <div class="field">
    <span class="wb-summary-name">
      {{ 'first_name_field_label' | translate | ucFirst }} <span
      class="wb-field-wrapper-required">*</span>
    </span>
    <ng-container>
      <input formControlName="fname" type="text" id="fname" [ngClass]="loginError ? 'error' : ''"
             placeholder="{{'first_name_field_label' | translate | ucFirst}}">
    </ng-container>
  </div>

  <div class="field">
    <span class="wb-summary-name">
      {{ 'last_name_field_label' | translate | ucFirst }} <span
      class="wb-field-wrapper-required">*</span>
    </span>
    <ng-container>
      <input formControlName="lname" type="text" id="lname" [ngClass]="loginError ? 'error' : ''"
             placeholder="{{'last_name_field_label' | translate | ucFirst}}">
    </ng-container>
  </div>

  <div class="field">
    <span class="wb-summary-name">
      {{ 'email_field_label' | translate | ucFirst }} <span
      class="wb-field-wrapper-required">*</span>
    </span>
    <ng-container>
      <input formControlName="email" type="text" id="email" [ngClass]="loginError ? 'error' : ''"
             placeholder="{{'email_field_label' | translate | ucFirst}}">
    </ng-container>
  </div>

  <div class="field" id="phone-field">
    <span class="wb-summary-name">
      {{ 'phone_number_field_label' | translate | ucFirst }} <span
      class="wb-field-wrapper-required">*</span>
    </span>
    <input type="tel" formControlName="phoneNumber" (change)="validateAndPatchValue($event)"
           [className]="!valid || hasErrors ? 'error' : ''"
           (blur)="isBlurred=true;validateAndPatchValue($event)" (focus)="isBlurred=false"/>
  </div>
  <nav class="wb-form wb-page-login" #formNavigation>
    <div *ngIf="loginError" class="errorMsg">
      {{ loginError | translate | ucFirst }}
    </div>
    <div class="wb-container">
      <button class="wb-next" (click)="signupAccount()">
        {{ 'signup' | translate | ucFirst }}
      </button>
    </div>
  </nav>
</form>
