<ng-container (mouseleave)="drop($event, this)">
  <div class="draggable"
       *ngFor="let address of getAddressArray(); index as fi; last as islast"
       (mousedown)="pick($event, address.config.property, fi)"
       (mousemove)="move($event, address.config.property)"
       (mouseup)="drop($event, address.config.property)"

       (touchmove)="move($event, address.config.property)"
       (touchstart)="pick($event, address.config.property, fi)"
       (touchend)="drop($event, address.config.property)"

       [ngClass]="{'dragging': getPosY(address.config.property) }"
       [ngStyle]="{'transform': 'translate(' + getPosX(address.config.property) + 'px, ' + getPosY(address.config.property) + 'px)'}">

    <div class="wb-summary-name">
      {{ address.config.label | translate | ucFirst }}
      <span class="wb-field-wrapper-required" *ngIf="address.config.required">*</span>

      <span *ngIf="islast" (click)="addStopOver()" class="wb-summary-name wb-add-stopover">
        <mat-icon>add_circle_outline</mat-icon>
      </span>
      <span *ngIf="islast" (click)="addStopOver()" class="wb-summary-name wb-add-stopover-text">
        {{ 'add_stopover' | translate | ucFirst }}
      </span>
    </div>
    <div class="address-pane {{(addressArray.length > 2 ? 'is-sortable' : '')}}">
      <div class="sort-icon" *ngIf="addressArray.length > 2">
        <mat-icon>drag_indicator</mat-icon>
      </div>
      <app-address-field [ngClass]="(fi !== 0 && fi !== (addressArray.length-1) ? 'wb-stopover-pane' : '')"
                         [config]="address.config"
                         [form]="form"
                         [sortableErrors]="errors"
                         (openHelpModal)="toggleHelpModal($event.helpTitleTag, $event.helpTextTag, $event.type, $event.meta)"
                         (checkWorkArea)="checkWorkAreaEmit($event)"></app-address-field>

      <span (click)="removeDestination((fi-1))" *ngIf="(fi !== 0 && fi !== (addressArray.length-1))" class="wb-address-icon">
        <mat-icon>delete</mat-icon>
      </span>
    </div>
  </div>
</ng-container>
