import { BrowserModule } from '@angular/platform-browser';
import {APP_BASE_HREF, CommonModule, DOCUMENT} from '@angular/common';
import {DoBootstrap, Inject, Injector, NgModule, Renderer2} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { PopupWidgetComponent } from './popup-widget.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormModule} from '../../app/form/form.module';
import {SharedModule} from '../../app/shared/shared.module';
import {Ng2FlatpickrModule} from 'ng2-flatpickr';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {RouterModule} from '@angular/router';
import {UcFirstPipeModule} from '../../app/pipes/uc-first.pipe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateLoader, TranslateModule, TranslateService, TranslateStore} from '@ngx-translate/core';
import {AuthInterceptor} from '../../app/interceptors/auth.interceptor';
import {CoolStorageModule} from '@angular-cool/storage';
import {StateService} from '../../app/services/state.service';
import {VisitorService} from '../../app/services/visitor.service';
import {AnalyticsService} from '../../app/services/analytics.service';
import {FieldErrorService} from '../../app/services/field-error.service';
import {HttpLoaderFactory} from '../../app/app.module';
import {HtmlPipeModule} from "../../app/pipes/html.pipe";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        FormModule,
        SharedModule,
        Ng2FlatpickrModule,
        AutocompleteLibModule,
        HtmlPipeModule,
        ReactiveFormsModule,
        NgxSmartModalModule.forChild(),
        RouterModule.forRoot([]),
        UcFirstPipeModule,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CoolStorageModule.forRoot(),
    ],
    exports: [
        PopupWidgetComponent
    ],
    declarations: [PopupWidgetComponent],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        TranslateService,
        TranslateStore,
        { provide: 'Window', useValue: window },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        StateService,
        VisitorService,
        AnalyticsService,
        FieldErrorService,
    ]
})
export class PopupWidgetModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const el = createCustomElement(PopupWidgetComponent, {
      injector: this.injector,
    });
    customElements.define('popup-widget', el);
  }
}
