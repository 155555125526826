<div class="wb-mobile wb-{{layout}}" #mobileFieldsContainer (window:resize)="onResize()">
  <ng-container *ngFor="let partIndex of parts[index]?.summary">
    <div class="wb-chapter wb-{{parts[partIndex]?.type}}"
         *ngIf="parts[partIndex]?.type !== 'summary' && parts[partIndex]?.type === 'products'">
      <h3>{{parts[partIndex]?.title | translate | ucFirst}}</h3>
      <div class="wb-fields">
        <div class="wb-summary-product">
          <div class="wb-pricing" *ngIf="!form.config.parts[3].hidePrice && this.form.config.orderType !== 'hourly'">
            <span class="wb-type"
                  *ngIf="formGroup.controls.returnTrip?.value">{{'return_trip' | translate | ucFirst}}</span>
            <span class="wb-type"
                  *ngIf="!formGroup.controls.returnTrip?.value">{{'one_way_trip' | translate | ucFirst}}</span>
            <span class="wb-price"
                  *ngIf="formGroup.controls.returnTrip?.value && formGroup.controls['product']?.value?.price?.total > 0">
              {{formGroup.controls['product']?.value?.totalPrice?.total / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
            <span class="wb-price" *ngIf="!formGroup.controls.returnTrip?.value">
              {{formGroup.controls['product']?.value?.price?.total / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
          </div>
          <div class="wb-pricing" *ngIf="!form.config.parts[3].hidePrice && this.form.config.orderType === 'hourly'">
            <span class="wb-type"
                  *ngIf="formGroup.controls.returnTrip?.value">{{'hourly_booking' | translate | ucFirst}} {{formGroup.controls['duration'].value}} {{'hours' | translate}}</span>
            <span class="wb-price" *ngIf="!formGroup.controls.returnTrip?.value">
              {{formGroup.controls['product']?.value?.price?.total / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
          </div>

          <div class="wb-image">
            <img *ngIf="formGroup.controls['product']?.value?.imagePath"
                 src="{{formGroup.controls['product']?.value?.imagePath}}"
                 onError="this.src='/assets/default-product-img.png';"
                 alt="{{formGroup.controls['product']?.value?.descriptionTag}}">

            <img *ngIf="formGroup.controls['product']?.value && formGroup.controls['product']?.value?.sku &&
        !formGroup.controls['product']?.value?.imagePath"
                 src="{{cdnUrl}}/products/{{formGroup.controls['product']?.value?.sku}}.png"
                 onError="this.src='/assets/default-product-img.png';"
                 alt="{{formGroup.controls['product']?.value?.descriptionTag}}">
          </div>
          <div class="wb-type">
            {{formGroup.controls['product']?.value?.descriptionTag | translate | ucFirst}}
          </div>
        </div>
        <div class="wb-price-mutations" *ngIf="formGroup.controls['product']?.value?.price?.total > 0">
          <div class="wb-rule">
              <span class="wb-label">
                {{this._state.currency}}
                <ng-container
                  *ngIf="formGroup.controls.returnTrip?.value">{{formGroup.controls['product']?.value?.descriptionTag | translate |ucFirst}} {{'return_trip' | translate}}</ng-container>
                <ng-container
                  *ngIf="!formGroup.controls.returnTrip?.value">{{formGroup.controls['product']?.value?.descriptionTag | translate | ucFirst}} {{'one_way_trip' | translate}}</ng-container>
              </span>
            <span *ngIf="!formGroup.controls.returnTrip?.value">
                {{formGroup.controls['product']?.value?.price?.total / 100 | currency:
              this._state.currency:'symbol':'1.2-2'}}
              </span>
            <span *ngIf="formGroup.controls.returnTrip?.value">
                {{formGroup.controls['product']?.value?.totalPrice?.total / 100 | currency:
              this._state.currency:'symbol':'1.2-2'}}
              </span>
          </div>
          <ng-container *ngFor="let part of parts">
            <ng-container *ngFor="let field of part?.fields;">
              <div *ngIf="field.product && formGroup.controls[field.property]?.value && field.type === 'number'" class="wb-rule">
                <span class="wb-label"><b>{{formGroup.controls[field.property]?.value}} x</b> {{(field.label ? field.label : field.placeholder) | translate | ucFirst}}</span>
                <span *ngIf="!formGroup.controls.returnTrip?.value">
              {{(field.product.amount * formGroup.controls[field.property]?.value) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
                <span *ngIf="formGroup.controls.returnTrip?.value">
              {{((field.product.returnAmount ? field.product.returnAmount : field.product.amount) * formGroup.controls[field.property]?.value) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
              </div>

              <div *ngIf="field.type === 'collapse'">
                <div *ngFor="let subField of field?.fields;">
                  <div *ngIf="subField.product && formGroup.controls[subField.property]?.value && subField.type === 'number'" class="wb-rule">
                    <span class="wb-label"><b>{{formGroup.controls[subField.property]?.value}} x</b> {{(subField.label ? subField.label : subField.placeholder) | translate | ucFirst}}</span>
                    <span *ngIf="!formGroup.controls.returnTrip?.value">
                  {{(subField.product.amount * formGroup.controls[subField.property]?.value) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
                </span>
                    <span *ngIf="formGroup.controls.returnTrip?.value">
                  {{((subField.product.returnAmount ? subField.product.returnAmount : subField.product.amount) * formGroup.controls[subField.property]?.value) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
                </span>
                  </div>
                </div>
              </div>

              <div *ngIf="field.product && formGroup.controls[field.property]?.value && field.type !== 'number'" class="wb-rule">
                <span class="wb-label">{{(field.label ? field.label : field.placeholder) | translate | ucFirst}}</span>
                <span *ngIf="!formGroup.controls.returnTrip?.value">
              {{field.product.amount / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
                <span *ngIf="formGroup.controls.returnTrip?.value">
              {{(field.product.returnAmount ? field.product.returnAmount : field.product.amount) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
              </div>

              <div *ngIf="field.type === 'collapse'">
                <div *ngFor="let subField of field?.fields;">
                  <div *ngIf="subField.product && formGroup.controls[subField.property]?.value && subField.type !== 'number'" class="wb-rule">
                    <span class="wb-label">{{(subField.label ? subField.label : subField.placeholder) | translate | ucFirst}}</span>
                    <span *ngIf="!formGroup.controls.returnTrip?.value">
                  {{subField.product.amount / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
                </span>
                    <span *ngIf="formGroup.controls.returnTrip?.value">
                  {{(subField.product.returnAmount ? subField.product.returnAmount : subField.product.amount) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
                </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="transactionCosts > 0">
            <div class="wb-rule">
              <span class="wb-label">{{'transaction_costs' | translate | ucFirst}}</span>
              <span>{{transactionCosts / 100 | currency:this._state.currency:'symbol':'1.2-2'}}</span>
            </div>
          </ng-container>

          <ng-container *ngIf="this.state.couponDiscount">
            <div class="wb-rule" *ngIf="!formGroup.controls.returnTrip?.value">
              <span class="wb-label"
                    *ngIf="this.state.couponDiscount.discount < 0 && (_state.couponDiscount && _state.couponDiscount.id)">
                <ng-container>{{'coupon_discount' | translate | ucFirst}}</ng-container>
              </span>
              <span *ngIf="this.state.couponDiscount.discount < 0 && (_state.couponDiscount && _state.couponDiscount.id)">
                {{this.state.couponDiscount.discount / 100 |
                  currency:this._state.currency:'symbol':'1.2-2'}}
              </span>
            </div>
            <div class="wb-rule" *ngIf="formGroup.controls.returnTrip?.value">
              <span class="wb-label" *ngIf="this.state.couponDiscount.discount < 0
              && (_state.couponDiscount && _state.couponDiscount.id)">
                <ng-container>{{'coupon_discount' | translate | ucFirst}}</ng-container>
              </span>
              <span *ngIf="this.state.couponDiscount.discountRetour < 0">
              x {{this.state.couponDiscount.discountRetour / 100 | currency:this._state.currency:'symbol':'1.2-2'}}<br>
          </span>
            </div>
          </ng-container>

          <div class="wb-totals">
            <span class="wb-label">{{'total_price' | translate | ucFirst}}</span>
            <span>{{totalPrice / 100 | currency:this._state.currency:'symbol':'1.2-2'}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="wb-chapter wb-{{chapterStates[partIndex]}} wb-{{parts[partIndex]?.type}} wb-partindex-{{partIndex}}"
         *ngIf="parts[partIndex]?.type !== 'summary' && parts[partIndex]?.type !== 'products'">
      <h3 (click)="toggleChapter(partIndex)">
        {{parts[partIndex]?.title | translate | ucFirst}}
        <fa-icon [icon]="chapterStates[partIndex] === 'closed' ? faChevronUp : faChevronDown"></fa-icon>
      </h3>
      <div class="wb-fields">
        <ng-container *ngFor="let field of parts[partIndex]?.fields; index as fi;">
          <app-field-wrapper *ngIf="parts[partIndex]?.type !== 'products' && !field.product"
                             [summary]="true"
                             [className]="'wb-field wb-summary wb-'+field.type"
                             [layout]="layout"
                             [index]="fi"
                             [field]="field"
                             [form]="form"
                             [formGroup]="formGroup"></app-field-wrapper>
        </ng-container>
      </div>
    </div>

    <div class="wb-chapter {{chapterStates[parts[partIndex]?.returnIndex]}} {{parts[partIndex]?.type}}"
         *ngIf="parts[partIndex]?.returnIndex && formGroup.controls.returnTrip?.value">
      <h3 (click)="toggleChapter(parts[partIndex]?.returnIndex)">
        {{parts[parts[partIndex]?.returnIndex]?.summaryTitle | translate | ucFirst}}
        <fa-icon
          [icon]="chapterStates[parts[partIndex]?.returnIndex] === 'closed' ? faChevronUp : faChevronDown"></fa-icon>
      </h3>
      <div class="wb-fields">
        <ng-container *ngFor="let field of parts[parts[partIndex]?.returnIndex]?.fields; index as fi;">
          <app-field-wrapper *ngIf="!field.product"
                             [summary]="true"
                             [className]="'wb-field wb-summary wb-'+field.type"
                             [layout]="layout"
                             [index]="fi"
                             [field]="field"
                             [form]="form"
                             [formGroup]="formGroup"></app-field-wrapper>
        </ng-container>
      </div>
    </div>

    <div #lastFields
         class="wb-chapter wb-fixed-fields {{selectedIndex === 3 ? 'forceLeft' : 'hidden'}} wb-{{parts[partIndex]?.type}}" id="wb-{{parts[partIndex]?.type}}"
         *ngIf="parts[partIndex]?.type !== 'summary' &&
          parts[partIndex]?.type === 'products'">
      <div class="wb-fields">
        <ng-container *ngFor="let part of parts;">
          <ng-container *ngFor="let field of part.fields; index as fi;">
            <app-field-wrapper *ngIf="part.type === 'summary'"
                               [className]="'wb-field wb-'+field.type"
                               [index]="fi" [field]="field"
                               [formGroup]="formGroup"></app-field-wrapper>
          </ng-container>
        </ng-container>
      </div>
      <div class="wb-container">
        <button class="wb-previous wb-button-small"
                (click)="navigateForm('back')">
          {{'text_back_button' | translate | ucFirst}}
        </button>
        <button class="wb-next"
                (click)="bookOrderForm()">
          {{'process_order_button' | translate | ucFirst}}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div class="wb-desktop wb-{{layout}}" #summaryPane>
  <div class="wb-column">
    <div class="wb-product">
      <div class="wb-pricing" *ngIf="this.form.config.orderType !== 'hourly'">
        <span class="wb-type"
              *ngIf="formGroup.controls.returnTrip?.value">{{'return_trip' | translate | ucFirst}}</span>
        <span class="wb-type"
              *ngIf="!formGroup.controls.returnTrip?.value">{{'one_way_trip' | translate | ucFirst}}</span>
        <span class="wb-price"
              *ngIf="formGroup.controls.returnTrip?.value && formGroup.controls['product']?.value?.price?.total > 0">
              {{(formGroup.controls['product']?.value?.totalPrice?.total) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
        <span class="wb-price" *ngIf="!formGroup.controls.returnTrip?.value">
              {{(formGroup.controls['product']?.value?.price?.total) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
      </div>

      <div class="wb-pricing" *ngIf="this.form.config.orderType === 'hourly'">
        <div class="wb-price" *ngIf="!form.config.parts[3].hidePrice">
          <span class="wb-type">{{'hourly_booking' | translate | ucFirst}} {{'voor' | translate}} {{formGroup.controls['duration'].value}} {{'hours' | translate}}</span>
          <span class="wb-price" *ngIf="formGroup.controls['product']?.value?.price?.total > 0">
            {{formGroup.controls['product']?.value?.price?.total / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
          </span>
          <span class="wb-price" *ngIf="!formGroup.controls['product']?.value?.price?.total">
            {{'onmeter' | translate | ucFirst}}
          </span>
        </div>
      </div>

      <div class="wb-image">
        <img *ngIf="formGroup.controls['product']?.value?.imagePath"
             src="{{formGroup.controls['product']?.value?.imagePath}}"
             onError="this.src='/assets/default-product-img.png';"
             alt="{{formGroup.controls['product']?.value?.descriptionTag}}">

        <img *ngIf="formGroup.controls['product']?.value && formGroup.controls['product']?.value?.sku &&
        !formGroup.controls['product']?.value?.imagePath"
             src="{{cdnUrl}}/products/{{formGroup.controls['product']?.value?.sku}}.png"
             onError="this.src='/assets/default-product-img.png';"
             alt="{{formGroup.controls['product']?.value?.descriptionTag}}">
      </div>
    </div>
    <div class="wb-price-mutations" *ngIf="formGroup.controls['product']?.value?.price?.total > 0">
      <div class="wb-rule">
        <span class="wb-label" *ngIf="!formGroup.controls.returnTrip?.value">
          <ng-container>{{formGroup.controls['product']?.value?.descriptionTag |
            translate | ucFirst}} {{'one_way_trip' | translate}}</ng-container>
        </span>
        <span *ngIf="!formGroup.controls.returnTrip?.value">
          {{(formGroup.controls['product']?.value?.price?.total) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
        </span>
        <span class="wb-label" *ngIf="formGroup.controls.returnTrip?.value">
          <ng-container>{{formGroup.controls['product']?.value?.descriptionTag | translate |ucFirst}} {{'return_trip' | translate}}</ng-container>
        </span>
        <span *ngIf="formGroup.controls.returnTrip?.value">
          {{formGroup.controls['product']?.value?.totalPrice?.total / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
        </span>
      </div>

      <ng-container *ngFor="let part of parts">
        <ng-container *ngFor="let field of part?.fields;">
          <div *ngIf="field.product && formGroup.controls[field.property]?.value && field.type === 'number'" class="wb-rule">
            <span class="wb-label"><b>{{formGroup.controls[field.property]?.value}} x</b> {{(field.label ? field.label : field.placeholder) | translate | ucFirst}}</span>
            <span *ngIf="!formGroup.controls.returnTrip?.value">
              {{(field.product.amount * formGroup.controls[field.property]?.value) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
            <span *ngIf="formGroup.controls.returnTrip?.value">
              {{((field.product.returnAmount ? field.product.returnAmount : field.product.amount) * formGroup.controls[field.property]?.value) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
          </div>

          <div *ngIf="field.type === 'collapse'">
            <div *ngFor="let subField of field?.fields;">
              <div *ngIf="subField.product && formGroup.controls[subField.property]?.value && subField.type === 'number'" class="wb-rule">
                <span class="wb-label"><b>{{formGroup.controls[subField.property]?.value}} x</b> {{(subField.label ? subField.label : subField.placeholder) | translate | ucFirst}}</span>
                <span *ngIf="!formGroup.controls.returnTrip?.value">
                  {{(subField.product.amount * formGroup.controls[subField.property]?.value) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
                </span>
                <span *ngIf="formGroup.controls.returnTrip?.value">
                  {{((subField.product.returnAmount ? subField.product.returnAmount : subField.product.amount) * formGroup.controls[subField.property]?.value) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
                </span>
              </div>
            </div>
          </div>

          <div *ngIf="field.product && formGroup.controls[field.property]?.value && field.type !== 'number'" class="wb-rule">
            <span class="wb-label">{{(field.label ? field.label : field.placeholder) | translate | ucFirst}}</span>
            <span *ngIf="!formGroup.controls.returnTrip?.value">
              {{field.product.amount / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
            <span *ngIf="formGroup.controls.returnTrip?.value">
              {{(field.product.returnAmount ? field.product.returnAmount : field.product.amount) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
            </span>
          </div>

          <div *ngIf="field.type === 'collapse'">
            <div *ngFor="let subField of field?.fields;">
              <div *ngIf="subField.product && formGroup.controls[subField.property]?.value && subField.type !== 'number'" class="wb-rule">
                <span class="wb-label">{{(subField.label ? subField.label : subField.placeholder) | translate | ucFirst}}</span>
                <span *ngIf="!formGroup.controls.returnTrip?.value">
                  {{subField.product.amount / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
                </span>
                <span *ngIf="formGroup.controls.returnTrip?.value">
                  {{(subField.product.returnAmount ? subField.product.returnAmount : subField.product.amount) / 100 | currency:this._state.currency:'symbol':'1.2-2'}}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="transactionCosts > 0">
        <div class="wb-rule">
          <span class="wb-label">{{'transaction_costs' | translate | ucFirst}}</span>
          <span>{{transactionCosts / 100 | currency:this._state.currency:'symbol':'1.2-2'}}</span>
        </div>
      </ng-container>

      <ng-container *ngIf="this.state.couponDiscount">
        <div class="wb-rule" *ngIf="!formGroup.controls.returnTrip?.value">
          <span class="wb-label"
                *ngIf="this.state.couponDiscount.discount < 0 && (_state.couponDiscount && _state.couponDiscount.id)">
            <ng-container>{{'coupon_discount' | translate | ucFirst}}</ng-container>
          </span>
          <span *ngIf="this.state.couponDiscount.discount < 0 &&
          (_state.couponDiscount && _state.couponDiscount.id)">
              {{this.state.couponDiscount.discount / 100 |
            currency:this._state.currency:'symbol':'1.2-2'}}
          </span>
        </div>
        <div class="wb-rule" *ngIf="formGroup.controls.returnTrip?.value">
          <span class="wb-label" *ngIf="this.state.couponDiscount.discount < 0
          && (_state.couponDiscount && _state.couponDiscount.id)">
            <ng-container>{{'coupon_discount' | translate | ucFirst}}</ng-container>
          </span>
          <span *ngIf="this.state.couponDiscount.discountRetour < 0 &&
          (_state.couponDiscount && _state.couponDiscount.id)">
              {{(this.state.couponDiscount.discountRetour+this.state.couponDiscount.discount) / 100 |
            currency:this._state.currency:'symbol':'1.2-2'}}<br>
          </span>
        </div>
      </ng-container>

      <div class="wb-totals">
        <span class="wb-label">{{'total_price' | translate | ucFirst}}</span>
        <span>{{totalPrice / 100 | currency:this._state.currency:'symbol':'1.2-2'}}</span>
      </div>
    </div>

    <div [class]="(!formGroup.controls['product']?.value?.price?.total ? 'no-price' : '')">
      <ng-container *ngFor="let part of parts;">
        <ng-container *ngFor="let field of part.fields; index as fi;">
          <app-field-wrapper *ngIf="part.type === 'summary'"
                             [className]="'wb-field wb-'+field.type"
                             [index]="fi"
                             [layout]="layout"
                             [field]="field"
                             [form]="form"
                             [formGroup]="formGroup"></app-field-wrapper>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="wb-column">
    <ng-container *ngFor="let partIndex of parts[index]?.summary">
      <div class="wb-chapter wb-{{chapterStates[partIndex]}} wb-{{parts[partIndex]?.type}}"
           *ngIf="parts[partIndex]?.type !== 'summary' && parts[partIndex]?.type !== 'products'">
        <h3 (click)="toggleChapter(partIndex)">
          {{parts[partIndex]?.title | translate | ucFirst}}
          <fa-icon [icon]="chapterStates[partIndex] === 'closed' ? faChevronUp : faChevronDown"></fa-icon>
        </h3>
        <div class="wb-fields">
          <ng-container *ngFor="let field of parts[partIndex]?.fields; index as fi;">
            <app-field-wrapper *ngIf="parts[partIndex]?.type !== 'products' && !field.product"
                               [summary]="true"
                               [className]="'wb-field wb-summary wb-'+field.type"
                               [index]="fi"
                               [layout]="layout"
                               [field]="field"
                               [form]="form"
                               [formGroup]="formGroup"></app-field-wrapper>
          </ng-container>
        </div>
      </div>
      <div class="wb-chapter wb-{{chapterStates[parts[partIndex]?.returnIndex]}} wb-{{parts[partIndex]?.type}}"
           *ngIf="parts[partIndex]?.returnIndex && formGroup.controls.returnTrip?.value">
        <h3 (click)="toggleChapter(parts[partIndex]?.returnIndex)" *ngIf="parts[parts[partIndex]?.returnIndex]">
          {{parts[parts[partIndex]?.returnIndex]?.summaryTitle | translate | ucFirst}}
          <fa-icon
            [icon]="chapterStates[parts[partIndex]?.returnIndex] === 'closed' ? faChevronUp : faChevronDown"></fa-icon>
        </h3>
        <div class="wb-fields">
          <app-field-wrapper [className]="'wb-field wb-summary wb-address'"
                             [summary]="true"
                             [index]="9999"
                             [form]="form"
                             [layout]="layout"
                             [field]="returnDepartureField"
                             [formGroup]="formGroup"></app-field-wrapper>
          <app-field-wrapper [className]="'wb-field wb-summary wb-address'"
                             [summary]="true"
                             [index]="9999"
                             [form]="form"
                             [layout]="layout"
                             [field]="returnDestinationField"
                             [formGroup]="formGroup"></app-field-wrapper>
          <ng-container *ngFor="let field of parts[parts[partIndex]?.returnIndex]?.fields; index as fi;">
            <app-field-wrapper *ngIf="!field.product"
                               [summary]="true"
                               [className]="'wb-field wb-summary wb-'+field.type"
                               [index]="fi"
                               [form]="form"
                               [layout]="layout"
                               [field]="field"
                               [formGroup]="formGroup"></app-field-wrapper>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
