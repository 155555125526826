import {Component, OnDestroy} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {StateService} from "../../services/state.service";
import {CoolLocalStorage} from "@angular-cool/storage";
import {ActivatedRoute} from "@angular/router";
import moment from "moment-timezone";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-loggedin-menu',
  templateUrl: './loggedin-menu.component.html',
  styleUrls: ['./loggedin-menu.component.scss']
})
export class LoggedinMenuComponent implements OnDestroy {
  formSubscription: Subscription;
  headerLinks: any[] = [];
  mForm: any;
  currentUser: any;
  welcomeMessage = 'welcome';
  currentLanguage: any;

  constructor(public translate: TranslateService,
              private state: StateService,
              private _vault: CoolLocalStorage,
              private route: ActivatedRoute) {
    this.formSubscription = this.route.data.subscribe((data: { form: any }) => {
      this.mForm = data.form;
      this.headerLinks = data.form.config.header.links;
      this.currentUser = JSON.parse(this._vault.getItem(`#t-#${this.mForm.id}-user`) || '{}');
      this.welcomeMessage = this.generateGreetings();
    });
  }

  generateGreetings(): string {
    const currentHour = moment().hour();
    if (currentHour >= 3 && currentHour < 12) {
      return 'welcome_morning';
    } else if (currentHour >= 12 && currentHour < 15) {
      return "welcome_afternoon";
    } else if (currentHour >= 15 && currentHour < 20) {
      return "welcome_evening";
    } else if (currentHour >= 20 && currentHour < 3) {
      return "welcome_night";
    } else {
      return "welcome"
    }
  }

  getLanguageName(lang: string): string {
    return lang.split('-')[0].toUpperCase();
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  logout(): void {
    this._vault.removeItem(`#t-#${this.mForm.id}`);
    this._vault.removeItem(`#t-#${this.mForm.id}-user`);
    document.location = `${this.translate.currentLang.split('-')[0]}/${this.mForm.id}`;
  }
}
