<div [formGroup]="control">
  <input type="text"
         placeholder="{{config.placeholder | translate | ucFirst}}"
         id="{{config.property}}"
         [maxLength]="(config.options.enforceFormat ? 7 : 999999999)"
         [value]="value"
         [formControlName]="config.property"
         [ngClass]="hasErrors ? 'error' : ''"
         (change)="onValueChanged($event)"/>
  <app-field-error [config]="config" (validityChanged)="validityChanged($event)"></app-field-error>
</div>
