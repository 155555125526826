<label for="{{(!summary ? field.property : '')}}"
       [formGroup]="formGroup"
       class="wb-{{layout}} wb-{{(opened ? 'opened' : 'closed')}} wb-{{field.type}}"
       *ngIf="(formGroup.controls[field.property]?.value || !summary)">

  <span class="wb-summary-name" *ngIf="field.type !== 'collapse' && field.type !== 'hidden' && field.type !== 'hiddenInput' && field.type !== 'number' && field.type !== 'sortable-address' && (field.type !== 'dateTime' || (field.type === 'dateTime' && summary )) &&
  (field.type !=='couponCode' || (this.formGroup.controls && this.formGroup.controls['product'] &&
  this.formGroup.controls['product'].value && this.formGroup.controls['product'].value.price.total))">
    {{(field.label ? field.label : (summary ? field.placeholder : '')) | translate | ucFirst}} <span
    class="wb-field-wrapper-required" *ngIf="field.required && !summary">*</span>
    <span *ngIf="field.helpText && !summary" class="wb-help" (click)="toggleHelpModal(field.label, field.helpText)"><fa-icon [icon]="faInfoCircle"></fa-icon></span>
  </span>

  <span class="wb-summary-name wb-options-pane" *ngIf="field.type === 'collapse' && field.dynamic"
        id="collapse-{{index}}" (click)="toggleSection('collapse-'+index)">
    {{field.label | translate | ucFirst}}
    <fa-icon *ngIf="!opened" [icon]="faChevronDown"></fa-icon>
    <fa-icon *ngIf="opened" [icon]="faChevronUp"></fa-icon>
  </span>

  <ng-container *ngIf="!summary">
    <ng-container *ngIf="field.type === 'text' || field.type === 'email'">
      <input [formControlName]="field.property" type="{{field.type}}" id="{{field.property}}" [ngClass]="hasErrors ? 'error' : ''" placeholder="{{field.placeholder | translate | ucFirst}}">
      <app-field-error [config]="field" (validityChanged)="validityChanged($event)"></app-field-error>
    </ng-container>

    <ng-container *ngIf="field.type === 'checkbox'">
      <app-checkbox-field [config]="field"
                          [control]="formGroup"></app-checkbox-field>
    </ng-container>

    <ng-container *ngIf="field.type === 'select'">
      <app-select-field [config]="field"
                        [control]="formGroup"></app-select-field>
    </ng-container>

    <ng-container *ngIf="field.type === 'phone'">
      <app-phone-field [config]="field"
                       [control]="formGroup"></app-phone-field>
    </ng-container>

    <ng-container *ngIf="field.type === 'flightNumber'">
      <app-flight-number-field [config]="field"
                               [control]="formGroup"></app-flight-number-field>
    </ng-container>

    <ng-container *ngIf="field.type === 'couponCode' && (this.formGroup.controls && this.formGroup.controls['product'] &&
  this.formGroup.controls['product'].value && this.formGroup.controls['product'].value.price.total)">
      <app-coupon-code-field [config]="field"
                             [control]="formGroup"></app-coupon-code-field>
    </ng-container>

    <ng-container *ngIf="field.type === 'paymentMethod'">
      <app-payment-method-field [config]="field"
                                [control]="formGroup"></app-payment-method-field>
    </ng-container>

    <ng-container *ngIf="field.type === 'hidden'">
      <input [formControlName]="field.property" value="{{field.default}}" type="{{field.type}}"
             id="{{field.property}}"
             [ngClass]="hasErrors ? 'error' : ''" placeholder="{{field.placeholder | translate | ucFirst}}">
    </ng-container>

    <ng-container *ngIf="field.type === 'textArea'">
      <textarea [formControlName]="field.property" id="{{''+field.property}}" placeholder="{{field.placeholder | translate | ucFirst}}"></textarea>
    </ng-container>

    <ng-container *ngIf="field.type === 'dateTime'">
      <app-date-time-field [config]="field"
                           [control]="formGroup"
                           [minimumOrderTime]="minimumOrderTime"
                           [orderTimesHandler]="(mainConfig && mainConfig.orderTimesHandler ? mainConfig.orderTimesHandler : 'availability')"
                           [orderMode]="orderMode"
                           [airportDates]="(mainConfig ? mainConfig.airportDates : '')"
                           [configRequestedDestinationDateOffset]="(mainConfig && mainConfig.requestedDestinationDateOffset ? mainConfig.requestedDestinationDateOffset : '')"
                           [airportDeparture]="airportDeparture"
                           [airportDestination]="airportDestination"
                           (doToggleHelpText)="toggleHelpModal($event.helpTitleTag, $event.helpTextTag)"></app-date-time-field>
    </ng-container>

    <ng-container *ngIf="field.type === 'address'">
      <app-address-field [config]="field" [form]="formGroup"
                         (openHelpModal)="toggleHelpModal($event.helpTitleTag, $event.helpTextTag, $event.type, $event.meta)"
                         (checkWorkArea)="checkWorkAreaEmit($event)"></app-address-field>
    </ng-container>

    <ng-container *ngIf="field.type === 'sortable-address'">
      <app-sortable-address-field [departureConfig]="part.fields[0]"
                                  [destinationConfig]="part.fields[2]"
                                  (checkWorkArea)="checkWorkAreaEmit($event)"
                                  (openHelpModal)="toggleHelpModal($event.helpTitleTag, $event.helpTextTag, $event.type, $event.meta)"
                                  [form]="formGroup"></app-sortable-address-field>
    </ng-container>


    <ng-container *ngIf="field.type === 'number'">
      <app-number-field [className]="(index % 2 === 0 ? 'even' : 'odd')" [config]="field" [control]="formGroup" (openHelpModal)="toggleHelpModal($event.helpTitleTag, $event.helpTextTag, $event.type, $event.meta)"></app-number-field>
    </ng-container>
  </ng-container>

  <span class="wb-summary-value" *ngIf="summary">
    <app-field-value [field]="field"
                     [formGroup]="formGroup"
                     [orderMode]="orderMode"
                     [summary]="summary"
                     [form]="form"
                     [airportDeparture]="airportDeparture"
                     [airportDestination]="airportDestination"></app-field-value>
  </span>

  <ng-container *ngIf="field.type === 'collapse'">
    <ng-container *ngFor="let field of field.fields; index as i;">
      <app-field-wrapper [className]="'wb-field wb-'+field.type+' '+field.cssclass"
                         [minimumOrderTime]="minimumOrderTime"
                         [index]="i"
                         [field]="field"
                         [formGroup]="formGroup"
                         [summary]="summary"
                         (openHelpModal)="openHelpModal.emit($event)"></app-field-wrapper>
    </ng-container>
  </ng-container>

</label>
