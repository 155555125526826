import {NgModule} from '@angular/core';
import {Ng2FlatpickrModule} from 'ng2-flatpickr';
import {ReactiveFormsModule} from '@angular/forms';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {UcFirstPipeModule} from '../pipes/uc-first.pipe';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {UnicornPipeModule} from '../pipes/unicorn.pipe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {HtmlPipeModule} from '../pipes/html.pipe';
import {StateService} from "../services/state.service";
import {GoogleMapsModule} from '@angular/google-maps';
import {KeepHtmlPipeModule} from "../pipes/keep-html.pipe";

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HtmlPipeModule,
    KeepHtmlPipeModule,
    Ng2FlatpickrModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSmartModalModule.forChild(),
    RouterModule,
    UcFirstPipeModule,
    TranslateModule,
    UnicornPipeModule,
    FontAwesomeModule,
    GoogleMapsModule
  ],
  exports: [ ],
  providers: [
    TranslateService,
    StateService,
  ]
})
export class FormModule { }
