<input type="text"
       placeholder="{{config.placeholder | translate | ucFirst}}"
       id="{{config.property}}"
       [maxLength]="(config.options.enforceFormat ? 7 : 999999999)"
       [value]="value"
       [disabled]="hasValidatedCoupon"
       [ngClass]="hasErrors ? 'error' : ''"
       onkeyup="this.value = this.value.toUpperCase()"
       (change)="onValueChanged($event)" />
<button (click)="validateCoupon()" class="couponButton"
        *ngIf="!hasValidatedCoupon">{{'validate'|translate|ucFirst}}</button>
<app-field-error [config]="config" (validityChanged)="validityChanged($event)"></app-field-error>
