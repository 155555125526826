import { ReplaySubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';

export class AsyncTranslationLoader extends TranslateHttpLoader {
  public static translationIdSubject = new ReplaySubject<string>();

  public getTranslation(lang: string) {
    return AsyncTranslationLoader.translationIdSubject.pipe(
      tap(value => { this.prefix = `${environment.translationUrl}/${value}/`; }),
      switchMap(() => super.getTranslation(lang))
    );
  }

}
