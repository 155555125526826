<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Name Column -->
    <ng-container *ngFor="let c of displayedColumnsConfig" [matColumnDef]="c.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          [hidden]="c.hidden"  [class]="(!c.hidden ? 'visible-column' : '')">{{ (c.label ? c.label : c.name) | translate | ucFirst }}
      </th>
      <td mat-cell *matCellDef="let item" [hidden]="c.hidden" [class]="(!c.hidden ? 'visible-column' : '')">
        <span [ngSwitch]="c.type">
          <span *ngSwitchCase="'date'"><span
            *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:'HH:mm DD-MM-YYYY' }}</span></span>

          <span *ngSwitchCase="'link'">
            <span *ngIf="item[c.name]">
              <a class='receipt_link' (click)="(c.name !== 'check' && item.receipt ? rowClick(item.receipt) : '')">{{ 'download_receipt' | translate| ucFirst }}</a>
            </span>
          </span>

          <span *ngSwitchCase="'amount'">
            <span *ngIf="item[c.name]" [ngClass]="(item.debit && item.total && item.total.total >= 0 ? 'debit' :
            'credit')">
              {{ (item.credit ? '- ' : '') }}{{ item[c.name] | localizedCurrency }}
            </span>
            <span *ngIf="!item[c.name]">-</span>
          </span>

          <span *ngSwitchDefault>
            <span *ngIf="item[c.name]">{{ item[c.name] | translate | ucFirst }}</span>
            <span *ngIf="!item[c.name]">-</span>
          </span>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let key"
        (click)="(displayedColumnsConfig[key] && displayedColumnsConfig[key].noRowClick ? false : rowClick(row.id))"
        [class]="(displayedColumnsConfig[key] && displayedColumnsConfig[key].noRowClick ? '' : 'rowAction')"></tr>
  </table>

  <mat-paginator #paginator
                 [length]="this.data.length"
                 [pageIndex]="0"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="[5, 10, 20]"
                 aria-label="Select page">
  </mat-paginator>
</div>
