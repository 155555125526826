import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {CustomerService} from "../../services/customer.service";
import {StateService} from "../../services/state.service";
import {environment} from "../../../environments/environment";
import {DateFormatPipe} from "angular2-moment/date-format.pipe";
import {LocalizedCurrencyPipe} from "../../pipes/localized-currency.pipe";

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [
    // TdDataTableService,
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    CustomerService, DateFormatPipe, LocalizedCurrencyPipe]
})
export class InvoicesComponent implements OnInit {
  debtorFilter = 'all';
  selected = '';
  loader = false;
  formGroup: UntypedFormGroup;
  formSubscription: Subscription;
  form: any = {config: {parts: [], widgetParts: []}};
  debtors: any[] = [];
  columnConfig = {
    'id': {
      'hidden': true
    },
    'reference': {
      'type': 'string',
      'label': 'invoice_id',
      'sort': false,
    },
    'created': {
      'type': 'string',
      'label': 'invoice_date',
      'sort': false,
    },
    'amount': {
      'type': 'amount',
      'sort': false,
    },
  }

  sortBy = 'reference';
  // sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  filterData: any;
  fromRow = 1;
  currentPage = 1;
  pageSize = 10;
  data: any = [];
  filteredData: any = [];
  allSelected = false;
  multiSelectBox = [];
  translations = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private datePipe: DateFormatPipe,
  ) {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(''),
      password: new UntypedFormControl(''),
    });

    let dataPointer = (this.activatedRoute.snapshot.params.formId ? this.activatedRoute.data : this.activatedRoute.parent.data);
    this.formSubscription = dataPointer.subscribe((data: { form: any }) => {
      this.form = data.form;
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  toggleMultiActionAll(): void {
    const self = this;
    self.filteredData.forEach((ride) => {
      self.multiSelectBox[ride.id] = (self.allSelected);
    });
    self.toggleMultiAction();
  }

  toggleMultiAction(): void {
  }

  loadData(): void {
    const self = this;
    let query:any = {};
    if(!self.debtors[0]) {
      self._customerService.getDebtorRoles()
        .subscribe((debtors: any) => {
          self.debtors = debtors.filter((d) => {
            return (d.Debtor);
          });
        });
    }

    if(this.debtorFilter !== 'all') {
      if(this.debtorFilter !== 'all') {
        query = { where:{
            billableId: this.debtorFilter,
            billableType: 'Debtor'
          }, include: ['Billable']};
      }
      // this.columns[1].hide = true;
      this.getDebtorInvoices(query);
    } else {
      // this.columns[1].hide = false
      this._customerService.getInvoices(query).subscribe((data: any) => {
        data = data.map((d) => {
          return {
            'id': d.id,
            'reference': d.reference,
            'debtor': 'personal',
            'created': this.datePipe.transform(d.created, 'DD-MM-YYYY'),
            'status': d.status,
            // 'description': d.subject,
            'amount': d.total
          }
        });
        this.getDebtorInvoices(query, data);
      }, (error) => {
        if (error && error.error && error.error.error) {
          error = error.error.error;
        }
      });
    }
  }

  getDebtorInvoices(query: any, filteredData: any[] = []) {
    this._customerService.getDebtorInvoices(query).subscribe((data: any) => {
      this.data = filteredData.concat(data.map((d) => {
        return {
          'id': d.id,
          'reference': d.reference,
          'created': this.datePipe.transform(d.created, 'DD-MM-YYYY'),
          'debtor': (d.Billable ? d.Billable.companyName : '-'),
          // 'description': d.subject,
          'status': d.status,
          'amount': d.total
        }
      }));
      this.data = this.data.concat(this.data);
    });
  }

  open(id: string): void {
    const url = `${environment.pasUrl}/api/invoices/${id}/getPdf`;
    window.open(url, '_blank');
  }

  selectionChanged() {
    this.loadData();
  }
}
