export const environment = {
  production: true,
  translationId: '5d88988ecf78f843c7a711b4',
  translationUrl: 'https://localization-api.dispatchapi.io/api/Projects',
  libraryUrl: 'https://library.dispatchapi.io',
  apiUrl: 'https://s-order.dispatchapi.io',
  orderFormUrl: 'https://s-order.yourdriverapp.com',
  pasUrl: 'https://pas.dispatchapi.io',
  ydaStripePublicKey: 'pk_live_51Hk3p7AAAqH6BJkpr4o8GHGaYD7zzvZlSr0xl5smFMSQ1SDoQLH00QnUMYH2beMFfKSaTP9DwPgGTP22KmNu0J7u00xpQW27hQ',
  customDomains: {
    'www.gatwickcars.com': '625d40d7ec6af2581a363fc3',
    'order.gatwickcars.com': '625d40d7ec6af2581a363fc3',
    'www.heathrowcars.com': '625d40d7ec6af2581a363fc3',
    'order.heathrowcars.com': '625d40d7ec6af2581a363fc3',
    'order.roadrunnersgroup.com': '625d40d7ec6af2581a363fc3',
    'booking.schipholtraveltaxi.nl': '5dd66bfba22b8cc7a3cced8e',
    'booking.schipholbusinesstaxi.nl': '5dd66c06a22b8cc7a3ccf33a',
    'www.vfashuttle.com': '62f67d523019db31b30f1738',
    'www.shuttles.co.zw': '62f6c6a2c326cc0ec2bfd83c',
    'booking.taxiserviceairportrome.com': '645d0cc10c2023e5ffd26264',
    'booking.tsairportransfers.com': '63a491cd88b78c65535a565f',
    'partnerbooking.taxiserviceairportrome.com': '64d15bd4c20eea1e8f3b9ef3',
  }
};
