import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {HistoryComponent} from './history/history.component';
import {InvoicesComponent} from './invoices/invoices.component';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {BrowserModule} from "@angular/platform-browser";
import {HtmlPipeModule} from "../pipes/html.pipe";
import {Ng2FlatpickrModule} from "ng2-flatpickr";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {NgxSmartModalModule} from "ngx-smart-modal";
import {RouterModule} from "@angular/router";
import {UcFirstPipeModule} from "../pipes/uc-first.pipe";
import {UnicornPipeModule} from "../pipes/unicorn.pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DashboardComponent} from './dashboard.component';
import {StateService} from "../services/state.service";
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {SetPasswordComponent} from './set-password/set-password.component';
import {MomentModule} from "angular2-moment";
import {LocalizedCurrencyPipeModule} from "../pipes/localized-currency.pipe";
import {DynamicTableComponent} from "../global/dynamic-table/dynamic-table.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PassengerSignupComponent } from './passenger-signup/passenger-signup.component';
import { DriverSignupComponent } from './driver-signup/driver-signup.component';

@NgModule({
  declarations: [
    LoginComponent, PasswordResetComponent, HistoryComponent, InvoicesComponent, DashboardComponent, SetPasswordComponent, DynamicTableComponent, PassengerSignupComponent, DriverSignupComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HtmlPipeModule,
    Ng2FlatpickrModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatGridListModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    SharedModule,
    NgxSmartModalModule.forChild(),
    RouterModule,
    UcFirstPipeModule,
    TranslateModule,
    UnicornPipeModule,
    FontAwesomeModule,
    MomentModule,
    // MatTableExporterModule,
    LocalizedCurrencyPipeModule,
  ],
  providers: [
    TranslateService,
    StateService
  ]
})
export class DashboardModule { }
