import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'keepHtml',
  pure: false
})
export class KeepHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {
  }

  transform(value: any, args?: any): any {
    let logOutputHtml = value;
    return this._sanitizer.bypassSecurityTrustHtml(logOutputHtml);
  }

}

@NgModule({
  declarations: [KeepHtmlPipe],
  exports: [KeepHtmlPipe]
})
export class KeepHtmlPipeModule {
}
