import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '../../interfaces/field';
import { Subscription } from 'rxjs';
import { StateService } from '../../services/state.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./product-selection.component.scss']
})
export class ProductSelectionComponent implements OnInit, OnDestroy {
  @Input() form: any;
  @Input() index: number;
  @Input() products: any;
  @Input() layout = 'page-layout';
  @Input() minimumOrderTime = 60;

  @Output() selectionFinished: EventEmitter<string> = new EventEmitter<string>();
  @Output() scrollUp: EventEmitter<string> = new EventEmitter<string>();
  @Output() openHelpModal: EventEmitter<any> = new EventEmitter<any>();

  formGroup: UntypedFormGroup;
  fields: Field[];
  orderMode: string;
  orderModeSub: Subscription;
  productSummery: boolean;
  forceSummaryTag: boolean;
  productExtraDescription: boolean;
  airportDeparture: boolean;
  airportDepartureSub: Subscription;
  airportDestination: boolean;
  airportDestinationSub: Subscription;
  returnTrip = false;
  returnTripCompleted = false;
  showReturn = true;
  formChanges: Subscription;
  returnDepartureField: Field;
  returnDestinationField: Field;
  formSubscription: Subscription;
  cdnUrl = '';

  constructor(private state: StateService,
              private route: ActivatedRoute) {
    this.formSubscription = this.route.data.subscribe((data: {form: any, currencies: string[]}) => {
      this.cdnUrl = (data.form && data.form.config && data.form.config.cdnUrl ? data.form.config.cdnUrl : '');
    });
    this.orderModeSub = this.state.orderMode$.subscribe(
      orderMode => this.orderMode = orderMode
    );
    this.airportDepartureSub = this.state.airportDeparture$.subscribe(
      airportDeparture => this.airportDeparture = airportDeparture
    );
    this.airportDestinationSub = this.state.airportDestination$.subscribe(
      airportDestination => {
        this.airportDestination = airportDestination
      }
    );
  }
  ngOnInit() {
    this.formGroup = this.state.formGroup;
    this.showReturn = this.state.formParts[this.index].showReturn;
    this.fields = this.state.formParts[this.index].fields;
    this.productSummery = this.state.formParts[this.index].productSummery;
    this.forceSummaryTag = this.state.formParts[this.index].forceSummaryTag;
    this.productExtraDescription = this.state.formParts[this.index].productExtraDescription;
    this.returnDepartureField = this.state.returnDepartureField;
    this.returnDestinationField = this.state.returnDestinationField;
    this.formChanges = this.formGroup.valueChanges.subscribe(this.onChanges.bind(this));
    this.onChanges(this.formGroup.getRawValue());

    if(this.returnTrip) {
      this.returnTripCompleted = true;
    }
  }

  onChanges(values) {
    this.returnTrip = (values.returnTrip);
    if(!this.returnTrip) {
      this.returnTripCompleted = false;
    }
  }

  selectProduct(product: any, returnTrip: boolean = false) {
    const self = this;
    this.returnTrip = returnTrip;

    this.formGroup.patchValue({
      returnTrip,
      product,
    });
    if (!returnTrip) {
      self.returnTripCompleted = false;
      this.navigate();
    } else {
      setTimeout(() => {
        self.returnTripCompleted = true;
      }, 300);
    }
  }

  navigate() {
    this.selectionFinished.emit('next');

    const self = this;
    setTimeout(() => {
      self.selectionFinished.emit('scrollUp');
    }, 500);
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.formChanges) {
      this.formChanges.unsubscribe();
    }
    if (this.orderModeSub) {
      this.orderModeSub.unsubscribe();
    }
    if (this.airportDepartureSub) {
      this.airportDepartureSub.unsubscribe();
    }
    if (this.airportDestinationSub) {
      this.airportDestinationSub.unsubscribe();
    }
  }

}
