<div class="loggedin_menu">
  <ul *ngIf="this.currentUser && this.currentUser.passengerId">
    <li>
<!--      <div class="welcome_message">{{this.welcomeMessage | translate | ucFirst}} {{this.currentUser.firstName}}</div>-->
<!--      <span class="welcome_message_separator separator"></span>-->
      <a
        routerLink="/{{this.translate.currentLang.split('-')[0]}}/{{this.mForm.id}}">{{'order_confirmation_book_new_ride' |
        translate |
        ucFirst}}</a>
      <span class="separator"></span>
      <a
        routerLink="/dashboard/{{this.translate.currentLang.split('-')[0]}}/{{this.mForm.id}}/invoices">{{'invoices' |
        translate |
        ucFirst}}</a>
      <span class="separator"></span>
      <a
        routerLink="/dashboard/{{this.translate.currentLang.split('-')[0]}}/{{this.mForm.id}}/history">{{'bookings' |
        translate |
        ucFirst}}</a>
      <span class="separator"></span>
      <a class="logout" (click)="logout()">{{'logout' |
        translate |
        ucFirst}} ({{this.currentUser.firstName}})</a>
    </li>
  </ul>

  <ul *ngIf="!this.currentUser || !this.currentUser.passengerId">
    <li>
      <a
        href="{{this.translate.currentLang.split('-')[0]}}/{{this.mForm.id}}/">{{'book_now' |
        translate |
        ucFirst}}</a>
      <span class="separator"></span>
      <a
        href="dashboard/{{this.translate.currentLang.split('-')[0]}}/{{this.mForm.id}}/login">{{'login' |
        translate |
        ucFirst}}</a>
    </li>
  </ul>
</div>

