<div class="loader-overlay" *ngIf="loader"></div>
<span id="loading" *ngIf="loader"></span>
<nav class="wb-top">
  <div class="wb-contain wb-page-login">
    <div class="wb-logo wb-page-login" *ngIf="form.config.logo">
      <img src="{{form.config.logo}}" alt="logo" class="wb-logo">
    </div>
    <h1 class="wb-logo wb-logotext wb-page-login" *ngIf="!form.config.logo">{{form.name}}</h1>
    <div class="wb-main">
      <h1 class="wb-formtitle">{{'form_title' | translate | ucFirst}}</h1>
      <div class="wb-steps">

      </div>
    </div>
  </div>
</nav>

<form [formGroup]="formGroup" class="wb-form-container" autocomplete="off">
  <!-- First field is to throw off browsers from using autocomplete -->
  <input autocomplete="off" name="hidden" type="text" style="display:none;">

  <h1>
    {{'set_password_title' | translate | ucFirst}}
  </h1>
  <div>
    {{subtext | translate | ucFirst}}
  </div>

  <div class="field">
    <span class="wb-summary-name">
      {{'password' | translate | ucFirst}} <span
      class="wb-field-wrapper-required">*</span>
    </span>
    <ng-container>
      <input formControlName="password" type="password" id="password" [ngClass]="false ? 'error' : ''"
             placeholder="{{'password' | ucFirst}}">
    </ng-container>
  </div>

  <div class="field">
    <span class="wb-summary-name">
      {{'confirm-password' | translate | ucFirst}} <span
      class="wb-field-wrapper-required">*</span>
    </span>
    <ng-container>
      <input formControlName="password-confirm" type="password" id="confirm-password" [ngClass]="false ? 'error' : ''"
             placeholder="{{'confirm-password' | ucFirst}}">
    </ng-container>
  </div>

  <nav class="wb-form wb-page-login" #formNavigation>
    <div class="wb-container">
      <button class="wb-next" (click)="setPassword()">
        {{'save_password' | translate | ucFirst}}
      </button>
    </div>
  </nav>
</form>
