import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from '../../services/state.service';
import { Subscription } from 'rxjs';
import { Field } from '../../interfaces/field';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  encapsulation: ViewEncapsulation.Emulated,
styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('lastFields') lastFields: ElementRef;
  @ViewChild('mobileFieldsContainer', { static: true }) mobileFieldsContainer: ElementRef;
  @Input() index: number;
  @Input() selectedIndex: number;
  @Input() form: any = {config: {parts: []}};
  @Input() totalPrice: number;
  @Input() singlePrice: number;
  @Input() returnPrice: number;
  @Input() transactionCosts: number;
  @Input() formGroup: UntypedFormGroup;
  @Input() formNavigation: ElementRef;
  @Input() layout = 'page-layout';

  @Output() bookOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  parts: any[] = [];
  orderMode: string;
  orderModeSub: Subscription;
  airportDeparture: boolean;
  airportDepartureSub: Subscription;
  airportDestination: boolean;
  airportDestinationSub: Subscription;
  returnDepartureField: Field;
  returnDestinationField: Field;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  chapterStates: string[] = [];
  lastIndex: number;
  pricingIndex: number;
  currency: string;
  formSubscription: Subscription;
  cdnUrl = '';
  _state: StateService;

  constructor(protected state: StateService,
              private route: ActivatedRoute) {

    if (!this.state.formConfig) {
      this.formSubscription = this.route.data.subscribe((data: {form: any}) => {
        if(data.form) {
          this.cdnUrl = data.form.config.cdnUrl ? data.form.config.cdnUrl : '';
          }
      });
    } else {
      this.cdnUrl = this.state.formConfig.cdnUrl;
    }
    this._state = state;
  }

  ngOnInit() {
    this.currency = this.state.currency;
    this.formGroup = this.state.formGroup;
    this.parts = this.state.formParts;
    this.returnDepartureField = this.state.returnDepartureField;
    this.returnDestinationField = this.state.returnDestinationField;
    this.parts.forEach((part, key) => {
      // console.log(part);
      this.chapterStates[key] = (key === 0 || key === 1 || key === 2 ? 'open' : 'closed');
      if (part.type === 'products') {
        this.pricingIndex = key;
      }
    });

    this.lastIndex = this.chapterStates.length - 1;
    this.chapterStates[this.chapterStates.length] = 'closed';
    this.chapterStates[1] = 'closed';

    this.orderModeSub = this.state.orderMode$.subscribe(
      orderMode => this.orderMode = orderMode
    );
    this.airportDepartureSub = this.state.airportDeparture$.subscribe(
      airportDeparture => this.airportDeparture = airportDeparture
    );
    this.airportDestinationSub = this.state.airportDestination$.subscribe(
      airportDestination => this.airportDestination = airportDestination
    );
  }

  ngAfterViewChecked(): void {
    this.ngAfterViewInit();
  }

  ngAfterViewInit(): void {
    if(this.layout !== 'widget-layout') {
      const self = this;
      if (this.lastFields.nativeElement.clientHeight > 0) {
        const height = this.lastFields.nativeElement.clientHeight;
        this.mobileFieldsContainer.nativeElement.style.paddingBottom = `${height}px`;
        this.currency = this.state.currency;
      }
    }
    this.handleNavigationPane();
  }

  onResize() {
    if(this.layout !== 'widget-layout') {
      const height = this.lastFields.nativeElement.clientHeight;
      this.mobileFieldsContainer.nativeElement.style.paddingBottom = `${height}px`;
      this.currency = this.state.currency;
    }
    this.handleNavigationPane();
  }

  handleNavigationPane(): void {
    if (this.lastFields.nativeElement.clientWidth && this.lastFields.nativeElement.clientWidth < 700 && this.selectedIndex === 3) {
      if(document.getElementById(this.formNavigation+'_formNavigation')) {
        document.getElementById(this.formNavigation + '_formNavigation').style.display = 'none';
      }
      if(document.getElementById(this.formNavigation+'_formNavigationModal')) {
        document.getElementById(this.formNavigation + '_formNavigationModal').style.display = 'none';
      }
    } else {
      if(document.getElementById(this.formNavigation+'_formNavigation')) {
        document.getElementById(this.formNavigation + '_formNavigation').style.display = 'block';
      }
      if(document.getElementById(this.formNavigation+'_formNavigationModal')) {
        document.getElementById(this.formNavigation + '_formNavigationModal').style.display = 'block';
      }
    }
  }

  toggleChapter(index: any): void {
    this.chapterStates[index] = (this.chapterStates[index] === 'closed' ? 'open' : 'closed');
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.orderModeSub) {
      this.orderModeSub.unsubscribe();
    }
    if (this.airportDepartureSub) {
      this.airportDepartureSub.unsubscribe();
    }
    if (this.airportDestinationSub) {
      this.airportDestinationSub.unsubscribe();
    }
    this.handleNavigationPane();
  }

  navigateForm(direction) {
    this.navigate.emit(direction);
    this.selectedIndex = this.selectedIndex -1;
    this.handleNavigationPane();
  }

  bookOrderForm() {
    const self = this;
    this.bookOrder.emit();
    self.handleNavigationPane();
  }
}
