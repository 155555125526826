import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unicorn'
})
export class UnicornPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value.formatUnicorn(args);
  }
}

@NgModule({
  declarations: [UnicornPipe],
  exports: [UnicornPipe]
})
export class UnicornPipeModule {}
