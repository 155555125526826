import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { Field } from '../../interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PaymentGateway } from '../../interfaces/payment-gateway';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-payment-method-field',
  templateUrl: './payment-method-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
styleUrls: ['./payment-method-field.component.scss'],
})
export class PaymentMethodFieldComponent implements OnInit, OnDestroy {
  @Input() config: Field;
  @Input() control: UntypedFormGroup;
  gateways: PaymentGateway[] = [];
  changes: Subscription;
  gatewaySub: Subscription;
  selected: string;
  _state: StateService

  constructor(private state: StateService) {
    this._state = state;
  }

  ngOnInit() {
    this.gatewaySub = this.state.paymentGateways$.subscribe((gateways: PaymentGateway[]) => {
      this.gateways = gateways;
    });
    this.changes = this.control.controls[this.config.property].valueChanges.subscribe(value => {
      this.selected = value;
    });
  }

  ngOnDestroy(): void {
    if (this.gatewaySub) {
      this.gatewaySub.unsubscribe();
    }
    if (this.changes) {
      this.changes.unsubscribe();
    }
  }

}
