import {AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { DynamicTableDataSource, DynamicTableItem } from './dynamic-table-datasource';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable) table!: MatTable<DynamicTableItem>;
  @Input() rowClick = null;
  @Input() columnConfig;
  @Input() data;
  @Input() pageSize;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = [];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumnsConfig:any = {
    name: 'id',
    type: 'string',
    hidden: false,
    sort: false,
  };
  label = {
    'requestedDate': 'requested_date'
  }

  constructor() {
  }

  ngOnInit() {
    this.dataSource.data = this.data;
    this.reloadTable();
  }


  reloadTable() {
    const columns = this.data
      .reduce((columns, row) => {
        return [...columns, ...Object.keys(row)]
      }, [])
      .reduce((columns, column) => {
        return columns.includes(column)
          ? columns
          : [...columns, column]
      }, [])

    // Describe the columns for <mat-table>.
    this.displayedColumnsConfig = columns.map(column => {
      return {
        name: column,
        label: this.label[column],
        type: (this.columnConfig[column] ? this.columnConfig[column].type : 'string'),
        sort: (this.columnConfig[column] ? this.columnConfig[column].sort : true),
        hidden: (this.columnConfig[column] ? this.columnConfig[column].hidden : false),
      };
    });
    this.displayedColumns = columns.map(column => {
      return column;
    });

    console.log(this.displayedColumnsConfig);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  ngOnChanges() {
    if(this.table) {
      console.log('ngOnChanges');
      // this.dataSource.data = [];
      this.dataSource.data = this.data;
      this.table.dataSource = this.dataSource;
      this.reloadTable();
    }
  }
}
