import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export enum DataLayerProperties {
  Event = 'event',
  VirtualPageUrl = 'virtualPageURL',
  VirtualPageTitle = 'virtualPageTitle',
  AffiliateCode = 'affiliateCode',
  TransactionId = 'transactionId',
  TransactionAffiliation = 'transactionAffiliation',
  TransactionTotal = 'transactionTotal',
  QuotationDestination = 'QuotationDestination',
  QuotationDeparture = 'QuotationDeparture',
  QuotatioPassengers = 'QuotatioPassengers',
  QuotationRequestedDate = 'QuotationRequestedDate',
}

export type FullDataLayer = {
  [key in DataLayerProperties]: string | number |  undefined;
};

export interface PartialDataLayer {
  [key: string]: string | number;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private dataLayer: FullDataLayer;
  public localLayer: any[];

  constructor(private router: Router) {
    this.generateEmptyDataLayer();
  }

  pageView(overridePath?: string, overrideTitle?: string): void {
    if((window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: 'VirtualPageView',
        virtualPageURL: overridePath || this.router.url,
        virtualPageTitle: overrideTitle || overridePath || this.router.url,
      });
    }
    this.localLayer = (window as any).dataLayer;
    console.log('pageView', (window as any).dataLayer);
  }

  public addToDataLayer(properties: PartialDataLayer, pushDataLayer: boolean = false): void {
    const dataLayerObject = {};
    Object.keys(DataLayerProperties).map(key => DataLayerProperties[key]).forEach(key => {
      dataLayerObject[key] = properties[key];
    });
    this.pushDataLayer(dataLayerObject);
  }

  private pushDataLayer(dataLayerObject): void {
    if((window as any).dataLayer) {
      (window as any).dataLayer.push(dataLayerObject);
    }
    this.localLayer = (window as any).dataLayer;
  }

  private generateEmptyDataLayer(): void {
    this.dataLayer = {
      [DataLayerProperties.Event]: undefined,
      [DataLayerProperties.VirtualPageUrl]: undefined,
      [DataLayerProperties.VirtualPageTitle]: undefined,
      [DataLayerProperties.AffiliateCode]: undefined,
      [DataLayerProperties.TransactionId]: undefined,
      [DataLayerProperties.TransactionAffiliation]: undefined,
      [DataLayerProperties.TransactionTotal]: undefined,
      [DataLayerProperties.QuotationDestination]: undefined,
      [DataLayerProperties.QuotationDeparture]: undefined,
      [DataLayerProperties.QuotatioPassengers]: undefined,
      [DataLayerProperties.QuotationRequestedDate]: undefined,
    };
  }

}
