<div class="loader-overlay" *ngIf="loader"></div>
<span id="loading" *ngIf="loader"></span>

<nav class="wb-top">
  <div class="wb-contain wb-page-login">
    <div class="wb-logo wb-page-login" *ngIf="form.config.logo">
      <img src="{{form.config.logo}}" alt="logo" class="wb-logo">
    </div>
    <h1 class="wb-logo wb-logotext wb-page-login" *ngIf="!form.config.logo">{{form.name}}</h1>
    <div class="wb-main">
      <h1 class="wb-formtitle">{{'form_title' | translate | ucFirst}}</h1>
      <div class="wb-steps">

      </div>
    </div>
  </div>
</nav>

<form [formGroup]="formGroup" class="wb-form-container" autocomplete="off" (ngSubmit)="startLogin()">
  <!-- First field is to throw off browsers from using autocomplete -->
  <input autocomplete="off" name="hidden" type="text" style="display:none;">

  <h1>
    {{'login_title' | translate | ucFirst}}
  </h1>
  <div>
    {{'login_sub_title' | translate | ucFirst}}
  </div>

  <div class="field">
    <span class="wb-summary-name">
      {{'email' | translate | ucFirst}} <span
      class="wb-field-wrapper-required">*</span>
    </span>
    <ng-container>
      <input formControlName="email" type="text" id="email" [ngClass]="loginError ? 'error' : ''"
             placeholder="{{'email' | translate | ucFirst}}">
    </ng-container>
  </div>

  <div class="field">
    <span class="wb-summary-name">
      {{'password' | translate | ucFirst}} <span
      class="wb-field-wrapper-required">*</span>
    </span>
    <ng-container>
      <input formControlName="password" type="password" id="password" [ngClass]="loginError ? 'error' : ''"
             placeholder="{{'password' | translate | ucFirst}}">
    </ng-container>
  </div>
  <div class="field">
    <a class="yda-link forgot" flex href="#"
       routerLink="../password-reset">{{'forgot_password'|translate}}</a>
    <span class="separator"></span>
    <a
      href="dashboard/{{this.translate.currentLang.split('-')[0]}}/{{this.form.id}}/signup">{{'signup' |
      translate |
      ucFirst}}</a>
  </div>
  <nav class="wb-form wb-page-login" #formNavigation>
    <div *ngIf="loginError" class="errorMsg">
      {{loginError | translate | ucFirst}}
    </div>
    <div class="wb-container">
      <button class="wb-previous"
              routerLink="/{{this.translate.currentLang.split('-')[0]}}/{{this.form.id}}">
        {{'back' | translate | ucFirst}}
      </button>
      <button class="wb-next" (click)="startLogin()">
        {{'login' | translate | ucFirst}}
      </button>
    </div>
  </nav>
</form>
