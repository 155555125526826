<header *ngIf="this.mForm.form.config">
  <ul *ngIf="headerLinks.length > 0">
    <li *ngFor="let link of headerLinks; index as fi;" >
      <a href="{{link.url | translate}}" target="_blank">{{link.text | translate | ucFirst}}</a>
      <span *ngIf="headerLinks[fi+1]" class="separator"></span>
    </li>
  </ul>

  <app-loggedin-menu [className]="(headerLinks.length > 0 ? 'header-links' : 'no-links')"
                     *ngIf="this.mForm.form.config.passengerLogin"></app-loggedin-menu>

  <app-country-select></app-country-select>

</header>

<router-outlet></router-outlet>

