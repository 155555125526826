import {Component, Inject, Renderer2} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {StateService} from "../../services/state.service";
import {CoolLocalStorage} from "@angular-cool/storage";
import {ActivatedRoute} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {environment} from "../../../environments/environment";
import {LANGUAGES} from "../../data/languages";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss']
})
export class CountrySelectComponent {
  formSubscription: Subscription;
  headerLinks: any[] = [];
  mForm: any;
  currentLanguage: any;
  langNames = {
    'en': {
      name: 'English',
      code: 'en-GB',
      short: 'gb',
    },
    'us': {
      name: 'United States',
      flag: 'us',
      code: 'en-US',
      short: 'gb',
    },
    'in': {
      name: 'India english',
      flag: 'in',
      code: 'en-IN',
      short: 'gb',
    },
    'de': {
      name: 'Deutsch',
      code: 'de-DE',
      short: 'de'
    },
    'fr': {
      name: 'France',
      code: 'fr-FR',
      short: 'fr'
    },
    'es': {
      name: 'Espange',
      code: 'es-ES',
      short: 'es'
    },
    'nl': {
      name: 'Nederlands',
      code: 'nl-NL',
      short: 'nl'
    },
    'it': {
      name: 'Italiano',
      code: 'it-IT',
      short: 'it',
    },
    'uk': {
      name: 'українська',
      code: 'uk-UK',
      short: 'ua'
    },
    'ar': {
      name: 'عربى',
      code: 'ar-100',
      short: 'sa'
    },
    'ja': {
      name: 'Japanese',
      code: 'jp-JA',
      short: 'jp'
    },
    'zh': {
      name: 'Chinese',
      code: 'zh-Hans-CN',
      short: 'cn'
    }
  };

  constructor(public translate: TranslateService,
              private state: StateService,
              private _vault: CoolLocalStorage,
              private route: ActivatedRoute,
              private _renderer2: Renderer2,
              @Inject('Window') private window: Window,
              @Inject(DOCUMENT) public document: Document) {
    this.formSubscription = this.route.data.subscribe((data: { form: any }) => {
      this.mForm = data.form;
      this.headerLinks = data.form.config.header.links;
      this.state.translationId = data.form.config.translationId ? data.form.config.translationId : environment.translationId;

      this.currentLanguage = (this.state.language && this.langNames[this.state.language]? this.langNames[this.state.language].code : (LANGUAGES[this.route.snapshot.paramMap.get('language') || translate.getBrowserLang()]));

      if(!this.currentLanguage) {
        this.currentLanguage = LANGUAGES[translate.getBrowserLang()];
      }

      this.languageDisplay = translate.getLangs().map((t) => {
        let split = t.split('-');
        let lang = this.langNames[split[0]];
        if(lang) {
          return {
            name: lang.name,
            flag: ( lang.flag ? lang.flag : lang.short ),
            short: lang.short,
            lang: t,
          }
        }
      });

      this.translate.use(this.currentLanguage);
    });
  }

  languageDisplay: any = [];
  loadedScripts: any = {};

  getLanguageName(lang: string): string {
    return lang.split('-')[0].toUpperCase();
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    // @ts-ignore
    $("#country_selector").countrySelect("destroy");
  }

  ngAfterViewInit(): void {
    this.loadScript(`assets/flags/build/js/countrySelect.js`, 'initCountrySelected');
  }

  loadScript(url, callback = null) {
    if (this.loadedScripts[url]) {
      return;
    }
    const self = this;
    let script = this._renderer2.createElement('script');
    script.src = url;
    let done = false;
    script.onload = script.onreadystatechange = function () {
      if (!done && (!this.readyState ||
        this.readyState === "loaded" || this.readyState === "complete")) {
        done = true;
        if (callback) {
          self[callback]();
        }
        // Handle memory leak in IE
        script.onload = script.onreadystatechange = null;
      }
    };
    this._renderer2.appendChild(this.document.body, script);
    this.loadedScripts[url] = true;
  }


  initCountrySelected() {
    const availableLanguages = [];
    Object.keys(this.langNames).forEach(key => {
      availableLanguages.push(this.langNames[key]);
    });

    const splitLanguage = this.currentLanguage.split('-');

    let defaultCountry = availableLanguages.filter((l) => {
      return (this.currentLanguage === l.code)
    })[0];

    if(!defaultCountry) {
      defaultCountry = availableLanguages.filter((l) => {
        return (splitLanguage[0] === l.short)
      })[0];
    }

    if(!defaultCountry) {
      defaultCountry = {
        short: 'en'
      };
    }
    // @ts-ignore
    $("#country_selector").countrySelect({
      forcedCountries: availableLanguages.map((l) => {
        return {
          name:l.name,
          iso2: ( l.flag ? l.flag : l.short ),
          short: l.short,
        };
      }),
      onlyCountries: this.languageDisplay.map((l) => {
        return ( l.flag ? l.flag : l.short );
      }),
      preferredCountries: null,
      responsiveDropdown:false,
      defaultCountry: (defaultCountry.flag ? defaultCountry.flag : defaultCountry.short)
    });
  }

  countrySelected(event): void {
    // @ts-ignore
    const myData = $("#country_selector").countrySelect("getSelectedCountryData");
    const lang = this.languageDisplay.filter((l) => {
      return (l.short === myData.short)
    })[0];
    this.translate.use(lang.lang);
    this.currentLanguage = lang.lang;
    this.state.language = this.currentLanguage.split('-')[0];
  }
}
