<div class="ng-autocomplete {{hasErrors ? 'error' : ''}}" id="autoComplete-{{config.property}}"
     [formGroup]="form">
  <div *ngIf="config.allowBrowserLocation && hasLocationActive" class="currentLocation"
       (click)="setCurrentLocation()" title="{{'select_my_location'|translate}}"></div>

  <div  *ngIf="!config.formArray">
    <ng-autocomplete
      #inputAutoComplete
      placeholder="{{config.placeholder | translate | ucFirst}}"
      (inputChanged)="search($event)"
      (selected)="getAddress($event)"
      [customFilter]="customFilter"
      (inputCleared)="clear()"
      (closed)="selectFirstOption($event)"
      [focusFirst]="true"
      [data]="data"
      [debounceTime]="500"
      [searchKeyword]="'description'"
      [isLoading]="loading"
      [historyIdentifier]="config.property + 'AutoComplete'"
      [historyHeading]="'autocomplete_recent_searches' | translate | ucFirst"
      [historyListMaxNumber]="3"
      [itemTemplate]="itemTemplate"
      [formControlName]="config.property"
      [class]="(config.allowBrowserLocation && hasLocationActive? 'currentLocationAutocomplete' : '')"
      [notFoundTemplate]="notFoundTemplate">
    </ng-autocomplete>
  </div>

  <div *ngIf="config.formArray" formArrayName="stopOvers">
    <ng-autocomplete
      #inputAutoComplete
      (inputChanged)="search($event)"
      placeholder="{{config.placeholder | translate | ucFirst}}"
      (selected)="getAddress($event)"
      [customFilter]="customFilter"
      (inputCleared)="clear()"
      (closed)="selectFirstOption($event)"
      [focusFirst]="true"
      [data]="data"
      [debounceTime]="500"
      [searchKeyword]="'description'"
      [isLoading]="loading"
      [historyIdentifier]="config.property + 'AutoComplete'"
      [historyHeading]="'autocomplete_recent_searches' | translate | ucFirst"
      [historyListMaxNumber]="3"
      [itemTemplate]="itemTemplate"
      [formControlName]="config.property"
      [class]="(config.allowBrowserLocation && hasLocationActive? 'currentLocationAutocomplete' : '')"
      [notFoundTemplate]="notFoundTemplate">
    </ng-autocomplete>
  </div>
  <ng-template #itemTemplate let-item>
    <a class="{{((item.types || []).join(' '))}}">
      <span class="icon"><fa-icon [icon]="getIcon(item.types)"></fa-icon></span>
      <span [innerHTML]="item.description | ucFirst"></span>
    </a>
  </ng-template>
  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="'autocomplete_no_address_found' | translate | ucFirst"></div>
  </ng-template>
</div>
<app-field-error [config]="config" (validityChanged)="validityChanged($event)"
                 (openHelpModal)="toggleHelpModal($event.helpTitleTag, $event.helpTextTag, 'serviceAreaModal')">
</app-field-error>

