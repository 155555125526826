import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {CustomerService} from "../../services/customer.service";
import {CoolLocalStorage} from "@angular-cool/storage";
import {StateService} from "../../services/state.service";
import {TranslateService} from "@ngx-translate/core";

const apiFormIdProvider = (state: StateService, route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  } else {
    return state.formId;
  }
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [StateService, ActivatedRoute]
    },
    CustomerService
  ]
})
export class LoginComponent implements OnInit {
  loginError = '';
  loader = false;
  formGroup: UntypedFormGroup;
  formSubscription: Subscription;
  form: any = {config: {parts: [], widgetParts: []}};
  currentLanguage: any;

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private _customerService: CustomerService,
    private _vault: CoolLocalStorage,
    private router: Router,
    private state: StateService,
    private route: ActivatedRoute
  ) {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(''),
      password: new UntypedFormControl(''),
    });

    let dataPointer = (this.activatedRoute.snapshot.params.formId ? this.activatedRoute.data : this.activatedRoute.parent.data);
      this.formSubscription = dataPointer.subscribe((data: { form: any }) => {
        this.form = data.form;
      });
  }

  ngOnInit(): void {
  }

  startLogin(): void {
    const self = this;
    this._customerService.login({
      email: this.formGroup.controls['email'].value,
      password: this.formGroup.controls['password'].value,
      realm: this.state.realm,
    }).subscribe((data: any) => {
      self._vault.setItem(`#t-#${this.form.id}`, data.token);
      self._vault.setItem(`#t-#${this.form.id}-user`, JSON.stringify(data.passengerDetails));
      document.location = `dashboard/${this.translate.currentLang.split('-')[0]}/${this.form.id}/history`;
    }, (error) => {
      console.log(error);
      self.loginError = 'login_error';
      setTimeout(function () {
        self.loginError = '';
      }, 3000)
    });
  }
}
