import {
  Component,
  ElementRef,
  Inject,
  isDevMode,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Quotation} from '../../interfaces/quotation';
import {Order} from '../../interfaces/order';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {TranslateService} from '@ngx-translate/core';
import {filter, Observable, pairwise, Subscription} from 'rxjs';
import {DefaultPrice, Price} from '../../interfaces/price';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DOCUMENT} from '@angular/common';
import {StateService} from '../../services/state.service';
import {FieldErrorService} from '../../services/field-error.service';
import {FieldError} from '../../interfaces/field-error';
import {faExclamationTriangle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {AnalyticsService, DataLayerProperties} from '../../services/analytics.service';
import {Product} from '../../interfaces/product';
import {environment} from '../../../environments/environment';
import {Field} from '../../interfaces/field';
import {Title} from '@angular/platform-browser';
import {PaymentGateway, TransactionCosts, TransactionCostType} from '../../interfaces/payment-gateway';
import {VisitorService} from '../../services/visitor.service';
import {MSP_LOCALES} from '../../data/msp-locales';
import {Job} from '../../interfaces/job';
import * as moment from 'moment-timezone';
import Swal from 'sweetalert2';
import {CustomerService} from "../../services/customer.service";
import * as randomstring from 'randomstring';
import {PreviewRouteMapComponent} from "../preview-route-map/preview-route-map.component";
import {map} from "rxjs/operators";
import md5 from 'crypto-js/md5';
import {GoogleMap} from '@angular/google-maps';
import {FieldWrapperComponent} from "../field-wrapper/field-wrapper.component";

const apiFormIdProvider = (route: ActivatedRoute) => {
  if (route.parent && route.parent.snapshot) {
    return route.parent.snapshot.params.formId;
  }
};

interface FieldControl {
  property: string;
  control: UntypedFormControl;
}

const getBounds = (
  polygons: google.maps.Polygon[]
): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds();
  polygons.map(p => p.getPath()
    .forEach((el, i) => bounds.extend(el)));
  return bounds;
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./order.component.scss'],
  providers: [
    {
      provide: 'apiModel',
      useValue: 'orders'
    },
    {
      provide: 'apiFormId',
      useFactory: apiFormIdProvider,
      deps: [ActivatedRoute]
    },
    ApiService, CustomerService
  ]
})
export class OrderComponent implements OnInit, OnDestroy {
  @ViewChild('formNavigation', {static: true}) formNavigation: ElementRef;
  @ViewChild('paymentModalIframe', {static: true}) paymentModalIframe: ElementRef;
  @ViewChild(GoogleMap, {static: false}) serviceAreaMap: GoogleMap;
  @ViewChildren(FieldWrapperComponent) wrappers: QueryList<boolean>;
  @ViewChild('supportFrame', {static: false}) supportFrame: ElementRef;
  @ViewChild('supportReportModal', {static: false}) supportReportModal: ElementRef;
  @ViewChildren('routeMap', {}) routePreviewMaps: PreviewRouteMapComponent[];
  supportCalculationData = [];
  returnTripSupportData = [];
  supportMode: string = null;
  buttonbgcolor: string = '000000';
  buttontextcolor: string = 'ffffff';
  buttontext: string = 'process_order_button';
  buttonposition: string = 'bottomRight';
  buttonstyle: string = 'rounded';
  showbreadcrumbs: boolean = true;
  showlogo: boolean = true;
  showclose: boolean = false;
  showtopbar: boolean = true;
  acceptedPrice: boolean = false;
  priceUpdateButton: any;
  widgettype: string = 'popup-widget';
  orderStatus: string;

  loadedScripts: any = {};
  formStyle = 'webbooker';
  formConfig = 'webbooker';
  formGroup: UntypedFormGroup;
  styleSubscription: Subscription;
  activeTab = 'route';
  form: any = {config: {parts: [], widgetParts: []}};
  durationConfig: any = {
    "type": "select",
    "label": "duration_field_label",
    "property": "duration",
    "helpText": "duration_count_field_help_text",
    "quotationField": true,
    "default": 1,
    "values": []
  }
  productSelectionCode = randomstring.generate();
  navigation = 'inline';
  destinationConfig: any;
  formChanges: Subscription;
  formId: string;
  isWidget = false;
  currentFormHash: string;
  currentForm: any;
  selectedFormIndex: number;
  quotation: Quotation;
  catchDuplicateBooking = false;
  ipAddress: string;
  ipAddressSubscription: Subscription;
  order: Order;
  orderId = '';
  products: any[] = [];
  pageStates: boolean[] = [];
  metaFields: any[] = [];
  numericFields: string[] = [];
  quotationFields: string[] = [];
  productMutations: any[] = [];
  helpData = {
    helpTitleTag: '',
    helpTextTag: '',
    type: 'helpModal',
    meta: null,
  };
  totalPrice = 0;
  transactionCosts = 0;
  singlePrice = 0;
  discountPrice = 0;
  singleMeta: any = {};
  singleForeignMeta: any = {};
  displayProducts: any = {};
  singleProducts: Product[] = [];
  returnPrice = 0;
  returnMeta: any = {};
  returnForeignMeta: any = {};
  returnProducts: Product[] = [];
  jobPrice: Price;
  jobPriceReturn: Price;
  formSubscription: Subscription;
  processingPayment = false;
  errorSubscription: Subscription;
  isErroneous = false;
  globalErrors: FieldError[];
  productPageIndex = 999;
  faInfoCircle = faInfoCircle;
  faExclamationTriangle = faExclamationTriangle;
  navigationError = {title: '', text: ''};
  affiliateCode: string;
  paymentGateways: PaymentGateway[] = [];
  mspLanguage: string;
  loader = false;
  hasGoogleError = false;
  savedValues: any;
  passenger: any;
  formParts = [];
  modalFormIndex = 0;
  steps: any = [];
  changedDestinationDate = '';
  metrics: any;
  modalOpen = false;
  onlyShowLoader: boolean = false;
  selectedProducts: any;
  mapServiceAreaCenter: any;

  departureMarker: any;
  destinationMarker: any;
  googleMapsLoaded = false;
  myPolygon = null;
  serviceArea = null;
  serviceAreaError = false;
  protected readonly Math = Math;

  constructor(private apiService: ApiService,
              private activatedRoute: ActivatedRoute,
              private modalService: NgxSmartModalService,
              private translate: TranslateService,
              private _vault: CoolLocalStorage,
              private router: Router,
              private route: ActivatedRoute,
              protected state: StateService,
              private errorService: FieldErrorService,
              public analytics: AnalyticsService,
              private pageTitle: Title,
              private visitorService: VisitorService,
              private _customerService: CustomerService,
              private _renderer2: Renderer2,
              @Inject('Window') private window: Window,
              @Inject(DOCUMENT) public document: Document) {
    this.selectedFormIndex = 0;
  }

  loadScript(url, callback = null) {
    if (this.loadedScripts[url]) {
      return;
    }
    const self = this;
    let script = this._renderer2.createElement('script');
    script.src = url;
    let done = false;
    script.onload = script.onreadystatechange = function () {
      if (!done && (!this.readyState ||
        this.readyState === "loaded" || this.readyState === "complete")) {
        done = true;
        if (callback) {
          self[callback]();
        }
        // Handle memory leak in IE
        script.onload = script.onreadystatechange = null;
      }
    };
    this._renderer2.appendChild(this.document.body, script);
    this.loadedScripts[url] = true;
  }

  ngOnInit(): void {
    if (this.activatedRoute && this.activatedRoute.snapshot && this.activatedRoute.parent && !this.isWidget) {
      this._ngOnInit();
    }
  }

  startGoogleMaps(apiKey) {
    const self = this;
    apiKey = (apiKey ? apiKey : "AIzaSyDQ9xFGyOnw47jqMXxgvLJ6TQXqLCeLNwg");
    if (isDevMode()) {
      console.log('startGoogleMaps: ' + apiKey);
      apiKey = "AIzaSyDQ9xFGyOnw47jqMXxgvLJ6TQXqLCeLNwg";
    }
    // if ((window as any).dataLayer) {
    //   const dataLayer = (window as any).dataLayer || [];
    //   dataLayer.push({
    //     'gtm.start': new Date().getTime(),
    //     event: 'gtm.js'
    //   });
    // }

    if (typeof google !== 'object' || typeof google.maps !== 'object') {
      setTimeout(function () {
        let loaded = window.document.getElementById('google-map-script');
        if (!loaded) {
          let s = window.document.createElement('script');
          s.id = 'google-map-script';
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://maps.googleapis.com/maps/api/js?loading=async&who=${self.formStyle}&libraries=places,routes,maps&key=${apiKey}&language=${self.state.language}${(self.formStyle === 'inline' || self.formStyle === 'widget' ? '' : '&callback=testGoogleApi')}`;
          window.document.body.appendChild(s);
        }
      }, 50);
    }
  }

  tinyAlert(message) {
    Swal.fire(message);
  }

  handleParams(params) {
    console.groupCollapsed('handleParams');
    const styles = {
      colors: {}
    };
    Object.keys(params).forEach((o) => {
      if (params[o] === 'true') {
        this[o] = true;
      } else if (params[o] === 'false') {
        this[o] = false;
      } else {
        this[o] = params[o];
      }
    });

    if (this['headercolor']) {
      styles.colors['global-title-color'] = `#${this['headercolor']}`;
    }
    if (this['textcolor']) {
      styles.colors['header-text-color'] = `#${this['textcolor']}`;
      styles.colors['global-text-color'] = `#${this['textcolor']}`;
    }
    if (this['backgroundcolor']) {
      styles.colors['form-nav-background-color'] = `#${this['backgroundcolor']}`;
    }
    if (!this['showlogo']) {
      styles.colors['logo-display'] = 'none';
    } else {
      styles.colors['logo-display'] = 'block';
    }
    if (!this['showtopbar']) {
      styles.colors['header-display'] = 'none';
    } else if (this.form.config.styles && this.form.config.styles.colors && this.form.config.styles.colors['header-display']) {
      styles.colors['header-display'] = this.form.config.styles.colors['header-display'];
    } else {
      styles.colors['header-display'] = 'flow-root';
    }

    if (this['font']) {
      styles.colors['form-fontstyle'] = this['font'];
    }

    // console.log('#############');
    Object.keys(styles.colors).forEach(key => {
      if (styles.colors[key].indexOf('$') > -1) {
        console.log(`--${key}: var(--${styles.colors[key].replace('$', '')})`);
        this.document.documentElement.style.setProperty(`--${key}`, `var(--${styles.colors[key].replace('$', '')})`);
      } else {
        console.log(`--${key}: ${styles.colors[key]}`);
        this.document.documentElement.style.setProperty(`--${key}`, styles.colors[key]);
      }
    });
    console.groupEnd();
  }

  _ngOnInit(initData?: any): void {
    const self = this;
    let startStep = 'step1';
    this.state.couponDiscount = null;
    if (!this.formGroup && this.state.formGroup) {
      this.formGroup = this.state.formGroup;
    }

    if (this.activatedRoute && this.activatedRoute.queryParams) {
      this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
        const data = params.get('preOrder');
        let orderObject = JSON.parse(data);
        if (orderObject) {
          if (!orderObject.departureModel) {
            orderObject = JSON.parse(orderObject);
          }

          if (orderObject.activeTab) {
            self.setActivateTab(orderObject.activeTab);
            delete orderObject.activeTab;
          }

          orderObject.timesaved = moment();
          this._vault.setObject(this.state.storageKeys.values, orderObject);
          if (orderObject.navigateTo) {
            startStep = orderObject.navigateTo;
          }
        }
      });
    }

    this.errorSubscription = this.errorService.$errors.subscribe(() => {
      this.globalErrors = this.errorService.getFieldErrors('global');
      this.isErroneous = this.globalErrors.length > 0;
    });
    this.ipAddressSubscription = this.visitorService.getIpAddress().subscribe(ip => {
      this.ipAddress = ip;
    });
    let dataPointer;
    if (initData) {
      dataPointer = Observable.create(function (observer) {
        observer.next(initData);
        observer.complete();
      });
    } else {
      dataPointer = (this.activatedRoute.snapshot.params.formId ? this.activatedRoute.data : this.activatedRoute.parent.data);
    }
    this.formSubscription = dataPointer.subscribe((data: { form: any }) => {
      let paymentStatus;
      let orderId;
      if (this.activatedRoute && this.activatedRoute.parent && this.activatedRoute.snapshot) {
        paymentStatus = this.activatedRoute.snapshot.params.paymentStatus;
        orderId = this.activatedRoute.snapshot.params.orderId ?
          this.activatedRoute.snapshot.params.orderId :
          this.activatedRoute.parent.snapshot.params.orderId;
      }

      if (orderId) {
        this.orderId = orderId;
      }

      if (this._vault.getItem(this.state.storageKeys.paymentStarted) === '1' && paymentStatus !== 'completed') {
        this.state.reset();
      }

      this.form = data.form;
      this.formParts = (this.navigation !== 'modal' ? this.form.config.parts : [this.form.config.parts[0]]);

      this.formId = data.form.id;

      const supportHash = this.route.snapshot.paramMap.get('hash');
      if (supportHash) {
        const testHash = md5(`billyJeans_${new Date('Y-m-d')}_${(data.form.config.customUrl ? data.form.config.customUrl : data.form.id)}`);
        if (supportHash === testHash.toString()) {
          this.supportMode = supportHash;
        }
      }

      this.loadSavedValues();
      this.state.formParts = this.preProcessParts(this.form.config.parts);

      this.startGoogleMaps(this.state.googleApiKey);

      this.steps = this.form.config.parts.filter((p) => {
        return (!p.hideFromSteps)
      });
      if (!this.form.config.widgetParts) {
        this.form.config.widgetParts = JSON.parse(JSON.stringify([this.form.config.parts[0]]));
        this.form.config.widgetParts[0].fields = this.form.config.parts[0].fields.filter((item, i) => {
          return (i < 3);
        });
        this.form.config.length = 1;
      }

      if (this.formConfig === 'widget') {
        this.form.config.parts = Object.assign(this.form.config.parts, this.form.config.widgetParts);
      }

      if (this.form.config.hourlyBookings) {
        for (let x = (this.form.config.hourlyMinTime > 0 ? this.form.config.hourlyMinTime : 1); x < (this.form.config.hourlyMaxTime > 0 ? (this.form.config.hourlyMaxTime + 1) : 25); x++) {
          this.durationConfig.values.push({
            "value": x,
            "label": (x === 1 ? "hours_field_value_singular" : "hours_field_value_multi")
          });
        }

        this.durationConfig.default = (this.form.config.hourlyMinTime > 0 ? this.form.config.hourlyMinTime : 1);
      }

      if (this.form.config.favicon) {
        this.document.getElementById('app-favicon').setAttribute('href', this.form.config.favicon);
      }

      if (self.formStyle !== 'inline') {
        if (this.form.config.pageTitle) {
          this.pageTitle.setTitle(this.form.config.pageTitle);
        } else {
          this.pageTitle.setTitle(this.form.name);
        }
      }

      if (this.formStyle === 'webbooker') {
        this.createGTM();
      }

      if (paymentStatus === 'completed') {
        this.processingPayment = true;
        this.waitForPayment();

        if (self.form.config.successUrl) {
          return;
        }
      }

      if (this.activatedRoute.snapshot.paramMap.get('showtopbar')) {
        this.handleParams(this.activatedRoute.snapshot.params);
      } else if ((!this.form.config.styles || !this.form.config.styles.colors || !this.form.config.styles.colors['header-display']) && this.showtopbar) {
        /**
         * We start with none, for cleaning loading of the form
         */
        this.document.documentElement.style.setProperty(`--header-display`, `flow-root`);
      }

      this.passenger = JSON.parse(this._vault.getItem(`#t-#${this.form.id}-user`) || '{}');
      this.setup();
      this.getPaymentGateways();

      if (this.state.forcedRequestedDateMode) {
        this.formGroup.controls['requestedDateMode'].setValue(this.state.forcedRequestedDateMode);
        this.formGroup.controls['returnRequestedDateMode'].setValue(this.state.forcedRequestedDateMode);
      } else {
        this.formGroup.controls['requestedDateMode'].setValue(this.getDateMode());
        this.formGroup.controls['returnRequestedDateMode'].setValue(this.getDateMode(true));
      }
      this.setCorrectDateField(this.formGroup.controls['requestedDateMode'].value, this.formGroup.controls['returnRequestedDateMode'].value);

      // this.formGroup.valueChanges.subscribe(this._onChanges.bind(this));
      this.formGroup.valueChanges.pipe(
        pairwise(),
        map(([oldState, newState]) => {
          let changes = {};
          for (const key in newState) {
            if (oldState[key] !== newState[key] &&
              oldState[key] !== undefined) {
              changes[key] = newState[key];
            }
          }
          return changes;
        }),
        filter(changes => Object.keys(changes).length !== 0)
      ).subscribe(
        changes => {
          this._onChanges(changes);
        }
      );

      this.analytics.pageView(`/${this.state.language}/${this.form.id}/${this.form.config.parts[0].gtmVirtualPageUrl}`);

      if (startStep === 'step2') {
        this.navigate('next');
      }

      this.detectGoogleMapsLoaded();
    });
  }

  createGTM(): void {
    if (this.form.config.gtmId && !isDevMode()) {
      const position = this.document.getElementsByTagName('script')[0];
      const script = this.document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?ydaLoaded=true&id=${this.form.config.gtmId}`;
      position.parentNode.insertBefore(script, position);
      const noScriptIframeContainer = this.document.getElementById('gtm-no-script');
      const noScriptIframe = this.document.createElement('iframe');
      noScriptIframe.src = `https://www.googletagmanager.com/ns.html?id=${this.form.config.gtmId}`;
      noScriptIframe.setAttribute('height', '0');
      noScriptIframe.setAttribute('width', '0');
      noScriptIframe.setAttribute('style', 'display:none;visibility:hidden');
      noScriptIframeContainer.appendChild(noScriptIframe);
    }
  }

  createControl(field): FieldControl[] {
    const controls: FieldControl[] = [];

    let value = this.savedValues[`${field.property}`] ?
      this.savedValues[`${field.property}`] :
      typeof field.default !== 'undefined' ?
        field.default :
        '';
    if (field.type === 'number') {
      value = this.savedValues[field.property] ? this.savedValues[field.property] : (field.min || 0);
      controls.push({
        property: field.property,
        control: new UntypedFormControl(value),
      });
    } else if (field.type === 'address') {
      const modelValue = this.savedValues[`${field.property}Model`] ? this.savedValues[`${field.property}Model`] : null;
      controls.push({
        property: `${field.property}Model`,
        control: new UntypedFormControl(modelValue),
      });
      controls.push({
        property: field.property,
        control: new UntypedFormControl(value),
      });
    } else if (field.type === 'dateTime') {
      let defaultValue = 'requestedDate';
      const modeValue = this.savedValues[`${field.property}Mode`] ? this.savedValues[`${field.property}Mode`] : defaultValue;
      if (field.property === 'requestedDate' || field.property === 'returnRequestedDate') {
        controls.push({
          property: `${field.property}Mode`,
          control: new UntypedFormControl(field.property),
        });
      }

      controls.push({
        property: field.property,
        control: new UntypedFormControl(value),
      });
    } else if (field.type === 'phone') {
      controls.push({
        property: field.property,
        control: new UntypedFormControl(value),
      });
      controls.push({
        property: field.property + 'Country',
        control: new UntypedFormControl(this.savedValues[`${field.property}Country`]),
      });
      controls.push({
        property: 'smsAuthorization',
        control: new UntypedFormControl(value),
      });
    } else {
      controls.push({
        property: field.property,
        control: new UntypedFormControl(value),
      });
    }
    return controls;
  }

  specialSetup(field: Field, index: number) {
    if (field.meta || field.foreignMeta) {
      this.metaFields.push(field);
    }
    if (field.numeric) {
      this.numericFields.push(field.property);
    }
    if (field.product) {
      this.productMutations.push({
        property: field.property,
        label: field.label,
        type: field.type,
        mutation: field.product
      });
    }
    if (field.quotation) {
      this.quotationFields.push(field.property);
    }

    if (!this.form.config.parts[index].requirements) {
      this.form.config.parts[index].requirements = [];
    }
    if (field.required) {
      this.form.config.parts[index].requirements.push(field);
    }

    if (field.type === 'address') {
      if (field.property === 'departure') {
        this.state.returnDepartureField = {...field, property: 'destination'};
      } else if (field.property === 'destination') {
        this.state.returnDestinationField = {...field, property: 'departure'};
      }
    }
  }

  getDateMode(returnDate = false): string {
    let mode = 'requestedDate';
    if (!returnDate) {
      if (this.state.orderMode === 'airport' && this.form.config.airportDates !== 'none') {
        if (this.form.config.airportDates === 'departure' || this.form.config.airportDates === 'destination') {
          if (this.state.airportDeparture && this.form.config.airportDates === 'departure') {
            mode = 'requestedScheduleDate'
          } else if (this.state.airportDestination && this.form.config.airportDates === 'destination') {
            mode = 'requestedScheduleDate'
          }
        } else if (this.state.airportDeparture) {
          mode = 'requestedScheduleDate'
        } else if (this.state.airportDestination) {
          mode = 'requestedDestinationDate'
        }
      }
    } else {
      mode = 'returnRequestedDate';
      if (this.state.orderMode === 'airport' && this.form.config.airportDates !== 'none')
        if (this.form.config.airportDates === 'departure' || this.form.config.airportDates === 'destination') {
          if (this.state.airportDestination && this.form.config.airportDates === 'departure') {
            mode = 'returnRequestedScheduleDate'
          } else if (this.state.airportDeparture && this.form.config.airportDates === 'destination') {
            mode = 'returnRequestedScheduleDate'
          }
        } else if (this.state.airportDestination) {
          mode = 'returnRequestedScheduleDate'
        } else if (this.state.airportDeparture) {
          mode = 'returnRequestedDestinationDate'
        }
    }
    return mode;
  }

  setCorrectDateField(requestedDateMode, returnRequestedDateMode) {
    // console.log(requestedDateMode, returnRequestedDateMode);
    /**
     * Hide all date fields in config parts[0] and parts[1] that are not requestedDateMode
     */
    this.form.config.parts[0].fields = this.form.config.parts[0].fields.map((f) => {
      /**
       * For fields that are dateTime type and not requestedDateMode set hidden to true
       */
      if (f.type === 'dateTime') {
        if (f.property !== requestedDateMode) {
          // console.log("Hidden--" + f.property + "!==" + this.formGroup.controls['requestedDateMode'].value);
          f.hidden = true;
        } else {
          f.hidden = false;
          // console.log("Visible--" + f.property + "!==" + this.formGroup.controls['requestedDateMode'].value);
        }
      }
      return f;
    });

    this.form.config.parts[1].fields = this.form.config.parts[1].fields.map((f) => {
      /**
       * For fields that are dateTime type and not requestedDateMode set hidden to true
       */
      if (f.type === 'dateTime') {
        if (f.property !== returnRequestedDateMode) {
          // console.log("Hidden--" + f.property + "!==" + this.formGroup.controls['returnRequestedDateMode'].value);
          f.hidden = true;
        } else {
          f.hidden = false;
          // console.log("Visible--" + f.property + "!==" + this.formGroup.controls['returnRequestedDateMode'].value);
        }
      }
      return f;
    });
  }

  loadSavedValues() {
    if (!this.savedValues) {
      this.savedValues = JSON.parse(this._vault.getItem(this.state.storageKeys.values) || '{}');

      if (this.savedValues && !this.savedValues.timesaved) {
        try {
          this.savedValues = JSON.parse(this.savedValues);
        } catch (e) {
        }
      }

      if (!this.savedValues || !this.savedValues.timesaved || moment(this.savedValues.timesaved).isBefore(moment().subtract('1', 'hour'))) {
        this.savedValues = {};
      }
    }
    if (this.passenger) {
      if (!this.savedValues) {
        this.savedValues = {};
      }
      this.savedValues = Object.assign({}, this.savedValues, this.passenger);
    }
    if (this.savedValues['requestedDateMode'] && this.form.config.airportDates === 'optional') {
      this.state.forcedRequestedDateMode = this.savedValues['requestedDateMode'];
    }
  }

  preProcessParts(parts: any[]) {
    const newFields = [], newFields2 = [];
    parts[0].fields.forEach((field, i) => {
      newFields.push(field);

      if (field.type === 'dateTime' && this.form.config.airportDates !== 'none') {
        let nField = JSON.parse(JSON.stringify(field));
        nField.property = 'requestedDestinationDate';
        nField.control = new UntypedFormControl(this.savedValues[nField.property] || '');
        nField.label = 'requested_destination_date_airport_field_label';
        nField.required = false;
        // nField.hidden = true;
        newFields.push(nField);
      }
      if (field.type === 'dateTime' && this.form.config.airportDates !== 'none') {
        let nField = JSON.parse(JSON.stringify(field));
        nField.property = 'requestedScheduleDate';
        nField.control = new UntypedFormControl(this.savedValues[nField.property] || '');
        nField.label = 'requested_schedule_date_airport_field_label';
        nField.required = false;
        // nField.hidden = true;
        newFields.push(nField);
      }
    });

    parts[1].fields.forEach((field, i) => {
      newFields2.push(field);
      if (field.type === 'dateTime' && this.form.config.airportDates !== 'none') {
        let nField2 = JSON.parse(JSON.stringify(field));
        nField2.property = 'returnRequestedDestinationDate';
        nField2.control = new UntypedFormControl(this.savedValues[nField2.property] || '');
        nField2.label = 'requested_destination_date_airport_field_label';
        nField2.required = false;
        // nField2.hidden = true;
        newFields2.push(nField2);
      }

      if (field.type === 'dateTime' && this.form.config.airportDates !== 'none') {
        let nField2 = JSON.parse(JSON.stringify(field));
        nField2.property = 'returnRequestedScheduleDate';
        nField2.control = new UntypedFormControl(this.savedValues[nField2.property] || '');
        nField2.label = 'requested_schedule_date_airport_field_label';
        nField2.required = false;
        // nField2.hidden = true;
        newFields2.push(nField2);
      }
    });

    parts[0].fields = newFields;
    parts[1].fields = newFields2;
    return parts;
  }

  toggleSMSAuthorizationRequirement(requestAuthorization): void {
    const lastStep = this.form.config.parts.length - 2;
    if (this.form.config.parts[lastStep]) {
      const hasCheckBox = this.form.config.parts[lastStep].fields.filter((field) => {
        return field.property === 'smsAuthorization'
      })[0];
      if (!hasCheckBox && requestAuthorization) {
        const nField = {
          type: 'checkbox',
          property: 'smsAuthorization',
          label: 'request_sms_authorization_title',
          placeholder: 'request_sms_authorization',
          meta: true,
          required: false,
          default: true,
          opened: true,
        };
        this.form.config.parts[lastStep].fields.push(nField);
      } else if (hasCheckBox && !requestAuthorization) {
        this.form.config.parts[lastStep].fields = this.form.config.parts[lastStep].fields.filter((field) => {
          return field.property !== 'smsAuthorization'
        });
      }
    }
  }

  setup(): void {
    const controls: any = {
      product: new UntypedFormControl(null),
      returnTrip: new UntypedFormControl(false),
    };
    let addressIndex = 0;

    this.form.config.parts[0].fields.forEach((field, i) => {
      if (field.type === 'address') {
        addressIndex = i;
      }
    });

    if(this.state.companySettings.hasOwnProperty('orderTimesHandler')) {
      this.form.config.orderTimesHandler = this.state.companySettings.orderTimesHandler;
    }

    if (this.form.config.hourlyBookings) {
      /**
       * We add this now, so it is added to the controls, but we remove/leave it based on the active tab
       */
      this.destinationConfig = this.form.config.parts[0].fields[addressIndex];
      this.form.config.parts[0].fields.splice(addressIndex + 1, 0, this.durationConfig);
      // if(!this.form.config.bookingHourlyDestination) {
      //   this.form.config.parts[0].fields.splice((addressIndex), 1);
      // }
      if (!this.form.config.routeBookings) {
        this.activeTab = 'hourly';
      }
    }

    this.form.config.parts.forEach((part, index) => {
      if (part.type === 'products') {
        this.productPageIndex = index;
      }
      if (part.fields) {
        part.fields.forEach(field => {
          const subFields = field.fields;
          this.specialSetup(field, index);

          const fieldControls = this.createControl(field);
          fieldControls.forEach((fc: FieldControl) => {
            controls[fc.property] = fc.control;

            if (part.type === 'address') {
              controls[`${fc.property}Modal`] = fc.control;
            }
          });

          if (subFields) {
            subFields.forEach(subField => {
              this.specialSetup(subField, index);
              const subFieldControls = this.createControl(subField);
              subFieldControls.forEach((fc: FieldControl) => {
                controls[fc.property] = fc.control;
              });
            });
          }
        });
      }
    });
    if (this.form.config.routeBookings && this.form.config.hourlyBookings) {
      if (!this.form.config.bookingHourlyDestination && !this.form.config.routeBookings) {
        this.form.config.parts[0].fields.splice((addressIndex), 1);
      } else {
        this.form.config.parts[0].fields.splice((addressIndex + 1), 1);
      }
    }

    /**
     * Add completed and canceled configparts as defaults add the end
     */
    if (this.form.config.parts && this.form.config.parts[this.form.config.parts.length - 1].type !== 'completion') {
      this.form.config.parts.push({
        "type": "completion",
        "title": "completed",
        "hideFromSteps": true,
        "gtmVirtualPageUrl": "booking_completed"
      });
    }

    // if(this.formConfig !== 'widget') {
    // this.preFillFieldsForTesting(controls, 3, false);
    // }

    controls.stopOvers = new UntypedFormArray([]);
    controls.stopOversModel = new UntypedFormArray([]);

    if(this.savedValues.stopOvers) {
      this.savedValues.stopOvers.forEach((stopOver, i) => {
        controls.stopOvers.push(new UntypedFormControl(stopOver));
      });
      this.savedValues.stopOversModel.forEach((stopOver, i) => {
        controls.stopOversModel.push(new UntypedFormControl(stopOver));
      });
    }

    const group = new UntypedFormGroup(controls);
    this.formGroup = group;
    this.state.formGroup = group;

    if (this.form.config.allowStopOvers) {
      /**
       * Replace addresses in first part for sortable-address-field
       */
      // this.form.config.parts[0].fields.splice(0,2);
      this.form.config.parts[0].fields[0].type = 'hiddenInputAddress';
      this.form.config.parts[0].fields[1].type = 'hiddenInputAddress';
      this.form.config.parts[0].fields.unshift({
        "type" : "sortable-address",
        "property" : "stopOvers",
        "label":"sortable-address",
        "opened": true
      });
      let b = this.form.config.parts[0].fields[0];
      this.form.config.parts[0].fields[0] = this.form.config.parts[0].fields[1];
      this.form.config.parts[0].fields[1] = b;
    }
    this.form.config.orderType = this.activeTab;

    if (this.savedValues) {
      this._onChanges(this.savedValues);
    }
  }

  canShowNextButton(navButton?: string): boolean {
    let productPage = (this.selectedFormIndex === this.productPageIndex);
    let normalPage = !productPage && this.selectedFormIndex < (this.form.config.parts.length - 2);
    if (this.navigation === 'modal') {
      productPage = (this.modalFormIndex === this.productPageIndex);
      normalPage = !productPage && this.modalFormIndex < (this.form.config.parts.length - 2);

      const productPageReturnTrip = productPage && this.formGroup.controls.returnTrip.value;
      return ((productPageReturnTrip || normalPage) && (this.modalFormIndex === 0 || navButton === 'modal'));
    } else {
      const productPageReturnTrip = productPage && this.formGroup.controls.returnTrip.value;
      return (productPageReturnTrip || normalPage);
    }
  }

  preFillFieldsForTesting(controls: any, index: number = 0, returnTrip: boolean = false): void {
    if (!environment.production) {
      console.log('PREFILLING FIELDS FOR TESTING PURPOSES');

      // this.orderStatus = 'failed'
      // this.state.orderMode = 'airport';
      const self = this;
      if (index) {
        setTimeout(function () {
          self.navigate('next', index);
        }, 1000);
      }

      this.state.airportDestination = true;
      controls.requestedDate.setValue(moment().add('1', 'hour'));
      // controls.requestedDate.setValue(new Date());
      controls.flightNumber.setValue('KL0896');
      if (returnTrip) {
        // controls.returnRequestedDate.setValue(new Date());
        controls.returnFlightNumber.setValue('AS1259');
      }
      // controls.luggage.setValue(0);
      controls.departureModel.setValue({
        gps: {
          lat: 52.7663081,
          lng: 5.099421000000007
        },
        internationalAlias: 'Overleek 4, 1671 GD Medemblik, Nederland',
        houseNumber: '4',
        streetName: 'Overleek',
        city: 'Medemblik',
        countryCode: 'NL',
        postalCode: '1671GD'
      });
      controls.departure.setValue('Overleek 4, 1671 GD Medemblik, Nederland');
      controls.destinationModel.setValue({
        type: 'airport',
        code: 'AMS',
        gps: {
          lat: '52.30907',
          lng: '4.763385'
        },
        internationalAlias: 'Schiphol (AMS)'
      });
      controls.destination.setValue('Schiphol (AMS)');
      controls.product.setValue({
        isAllowedOnMeter: 'true',
        vehicleType: 'saloon',
        descriptionTag: 'saloon',
        maxPassengers: 4,
        name: 'Super Taxi',
        price: {
          total: 10050,
          currency: 'EUR',
          breakdown: {
            route: {
              total: 10050,
              taxPercentage: 0
            },
            toll: {
              total: 0,
              taxPercentage: 21
            },
            parking: {
              total: 0,
              taxPercentage: 21
            },
            waiting: {
              total: 0,
              taxPercentage: 21
            },
            cleaning: {
              total: 0,
              taxPercentage: 21
            },
            discount: {
              total: 0,
              taxPercentage: 0
            }
          }
        },
        priceReturn: {
          total: 20100,
          currency: 'EUR',
          breakdown: {
            route: {
              total: 10050,
              taxPercentage: 0
            },
            toll: {
              total: 0,
              taxPercentage: 21
            },
            parking: {
              total: 0,
              taxPercentage: 21
            },
            waiting: {
              total: 0,
              taxPercentage: 21
            },
            cleaning: {
              total: 0,
              taxPercentage: 21
            },
            discount: {
              total: 0,
              taxPercentage: 0
            }
          }
        },
        isFixed: 'true'
      });
      controls.returnTrip.setValue(returnTrip);
      if (controls.luggage) {
        controls.luggage.setValue(1);
      }
      controls.firstName.setValue('Martin');
      controls.lastName.setValue('Zwaneveld');
      controls.email.setValue('martin@taxiid.nl');
      controls.phoneNumber.setValue('+31646096022');
      controls.note.setValue('Dit is een test rit!');
      // controls.acceptTermsAndConditions.setValue(true);
      this.selectedFormIndex = index;
    }
  }

  onChanges(values): void {
    this._onChanges(values);
  }

  _onChanges(changes): void {
    // console.log(this.formGroup.controls['requestedDateMode'].value);
    const hash = md5(JSON.stringify(this.formGroup.value)).toString();
    const values = this.formGroup.value;

    const previousData = this.currentForm;
    if (this.currentFormHash !== hash) {
      this.currentFormHash = hash;
      this.currentForm = values;
      const airportDeparture = (values.departureModel && values.departureModel.type === 'airport') || false;
      this.state.airportDeparture = airportDeparture;
      const airportDestination = (values.destinationModel && values.destinationModel.type === 'airport') || false;
      this.state.airportDestination = airportDestination;
      if (airportDeparture || airportDestination) {
        this.state.orderMode = 'airport';
      } else {
        this.state.orderMode = 'default';
      }

      if (previousData) {
        if (previousData['departureModel'] !== values['departureModel'] ||
          previousData['destinationModel'] !== values['destinationModel'] ||
          previousData['stopOversModel'] !== values['stopOversModel']) {
          if (this.routePreviewMaps && this.routePreviewMaps.length > 0) {
            this.routePreviewMaps.forEach((routePreviewMap) => {
              routePreviewMap.recalculateRoute();
            });
          }
        }
        if (this.state.forcedRequestedDateMode) {
          this.setCorrectDateField(this.state.forcedRequestedDateMode, this.state.forcedRequestedDateMode);
        } else {
          values['requestedDateMode'] = this.getDateMode();
          values['returnRequestedDateMode'] = this.getDateMode(true);

          if (previousData && (previousData['requestedDateMode'] !== values['requestedDateMode'] || previousData['returnRequestedDateMode'] !== values['returnRequestedDateMode'])) {
            this.setCorrectDateField(values['requestedDateMode'], values['returnRequestedDateMode']);
            this.formGroup.controls['requestedDateMode'].setValue(values['requestedDateMode']);
            this.formGroup.controls['returnRequestedDateMode'].setValue(values['returnRequestedDateMode']);
          }
        }

        if (this.formGroup.controls['phoneNumber'].valid) {
          if (this.formGroup.controls['phoneNumberCountry'].value === 'us') {
            this.toggleSMSAuthorizationRequirement(true);
          } else {
            this.toggleSMSAuthorizationRequirement(false);
          }
        }
      }

      if (!Object.keys(changes).includes('flightnumber') && !Object.keys(changes).includes('phoneNumber') && !Object.keys(changes).includes('firstName') && !Object.keys(changes).includes('lastName') && !Object.keys(changes).includes('email') && !Object.keys(changes).includes('note')) {
        this.calculatePricing(values);
      }
      this.buildMeta(values);
      // this.buildQuotation(values);
      this.buildOrder(values);

      if (this.selectedFormIndex === (this.form.config.parts.length - 1)) {
        this.checkPageValidity(values, this.selectedFormIndex);
      }

      if (((!previousData || !previousData.product) && values.product) ||
        (previousData && previousData.product && values.product && previousData.product.price.total !== values.product.price.total)) {
        this.getPaymentGateways();
      }
    }
    this.saveState();
  }

  checkPageValidity(values?: any, index?: number) {
    console.log('checkPageValidity');
    if (!index) {
      index = this.selectedFormIndex;
    }
    if (!this.form.config.parts[index].requirements) {
      this.pageStates[index] = true;
    } else {
      if (!values) {
        values = this.formGroup.getRawValue();
      }
      let valid = true;
      let errorText = 'field_required_error';
      const newErrors: FieldError[] = [];
      const oldErrors: string[] = [];

      if (this.formConfig === 'widget' && index === 0) {
        this.form.config.widgetParts[index].requirements = this.form.config.parts[index].requirements.filter((item, i) => {
          return (i < 2);
        });
      }
      (this.formConfig === 'widget' && this.form.config.widgetParts[index] &&
      this.form.config.widgetParts[index].requirements ? this.form.config.widgetParts[index].requirements : this.form.config.parts[index].requirements).forEach(field => {
        if (field.type === 'collapse' || (this.activeTab === 'hourly' && field.property === 'destination')) {
          return;
        }
        // console.log(`Property '${field.property}' is required and has value '${values[field.property]}`);
        const errorId = `${field.property}Required`;
        let fieldValid = true;
        errorText = 'field_required_error';

        if (field.showIf && field.showIf.addressAirport === 'any' && this.state.orderMode !== 'airport') {
          fieldValid = true;
        } else {
          if (typeof values[field.property] === 'undefined') {
            // console.log(`field '${field.property}' undefined, valid is false!`);
            valid = false;
            fieldValid = false;
          }
          if (values[field.property] === '') {
            // console.log(`field '${field.property}' empty, valid is false!`);
            valid = false;
            fieldValid = false;
          }
          if (field.type === 'checkbox' && values[field.property] !== true) {
            // console.log(`field '${field.property}' false, valid is false!`);
            valid = false;
            fieldValid = false;
          }
          if (field.type === 'dateTime' && values[field.property] === '' && field.property !== values['requestedDateMode']) {
            // console.log(`field '${field.property}' false, valid is false!`);
            valid = true;
            fieldValid = true;
          }
          if (field.type === 'email' && values[field.property]) {
            const re = new RegExp(/^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/);
            const isValidEmail = re.test(values[field.property]);
            if (!isValidEmail) {
              valid = false;
              fieldValid = false;
              errorText = 'field_invalid_email_error';
            }
          }

          if (field.type === "address" && field.property === "departure") {
            if (values.departure && values.departureModel && values.destinationModel && values.departureModel.gps.lat === values.destinationModel.gps.lat && this.activeTab !== 'hourly') {
              valid = false;
              fieldValid = false;
              errorText = 'field_duplicate_address_error';
            }
          }

          // console.log(`field '${field.property}' is valid: ${fieldValid}`);
          if (!fieldValid) {
            newErrors.push({
              id: errorId,
              property: field.property,
              text: errorText,
              label: field.label,
            });
          } else {
            oldErrors.push(errorId);
          }
        }
      });

      this.form.config.parts[index].fields.forEach(field => {
        if (field.type === 'collapse') {
          return;
        }
        let fieldValid = true;
        const errorId = `${field.property}Valid`;

        if (field.type === "address" && field.property === "departure") {
          if (values.departure && (!values.departureModel || !values.departureModel.gps || !values.departureModel.gps.lat)) {
            valid = false;
            fieldValid = false;
            errorText = 'field_invalid_selection';
          }
        }
        if (field.type === "address" && field.property === "destination") {
          if (values.destination && (!values.destinationModel || !values.destinationModel.gps || !values.destinationModel.gps.lat) && this.activeTab !== 'hourly') {
            valid = false;
            fieldValid = false;
            errorText = 'field_invalid_selection';
          }
        }

        if (field.type === "flightNumber" && field.property === "flightNumber") {
          if (field.requiredAirportPickup && values[field.property] === '' && values.departureModel && values.departureModel.type === 'airport' && this.activeTab !== 'hourly') {
            valid = false;
            fieldValid = false;
          }
          if (field.requiredAirportDestination && values[field.property] === '' && values.destinationModel && values.destinationModel.type === 'airport' && this.activeTab !== 'hourly') {
            valid = false;
            fieldValid = false;
          }
        } else if (field.type === "flightNumber" && field.property === "returnFlightNumber" && values.returnTrip) {
          if (field.requiredAirportPickup && values[field.property] === '' && values.destinationModel && values.destinationModel.type === 'airport' && this.activeTab !== 'hourly') {
            valid = false;
            fieldValid = false;
          }
          if (field.requiredAirportDestination && values[field.property] === '' && values.departureModel === 'airport' && this.activeTab !== 'hourly' && values.departureModel.type === 'airport') {
            valid = false;
            fieldValid = false;
          }
        }

        if (!fieldValid) {
          newErrors.push({
            id: errorId,
            property: field.property,
            text: errorText,
            label: field.label,
          });
        } else {
          oldErrors.push(errorId);
        }
      });

      this.errorService.addErrors(newErrors);
      this.errorService.removeErrors(oldErrors);
      if (this.form.config.forceAirport) {
        this.checkForcedAirport();
      }
      if (this.form.config.forceFlightNumbers) {
        this.checkForcedFlightNumbers();
      }
      // this.checkOtherErrors();
      // console.log(index);
      // console.log(valid);
      // console.log(this.isErroneous);

      if(this.serviceAreaError) {
        valid = false;
      }
      this.pageStates[index] = valid && !this.isErroneous;
    }
  }

  checkOtherErrors(): void {
    this.form.config.parts[this.selectedFormIndex].fields.forEach(field => {
      if (this.errorService.fieldHasErrors(field.property)) {
        this.isErroneous = true;
      }
    });
  }

  checkForcedAirport(): void {
    const departure = this.formGroup.controls.departureModel.value;
    const destination = this.formGroup.controls.destinationModel.value;
    const errorId = `airportAddressRequired`;
    const departureErrorId = `departureAirportAddressRequired`;
    const destinationErrorId = `destinationAirportAddressRequired`;

    if ((!departure || departure.type !== 'airport') && (!destination || destination.type !== 'airport')) {
      this.errorService.addErrors([
        {
          id: errorId,
          property: 'global',
          text: 'airport_required_error',
          label: '',
        },
        {
          id: departureErrorId,
          property: 'departure',
        },
        {
          id: destinationErrorId,
          property: 'destination',
        },
      ]);
    } else {
      this.errorService.removeErrors([errorId, departureErrorId, destinationErrorId]);
    }
  }

  checkForcedFlightNumbers(): void {
    const flightNumber = this.formGroup.controls.flightNumber.value;
    const returnFlightNumber = this.formGroup.controls.returnFlightNumber.value;
    const flightNumberErrorId = 'flightNumberRequired';
    const returnFlightNumberErrorId = 'returnFlightNumberRequired';
    if (!flightNumber && this.state.orderMode === 'airport') {
      // console.log(this.order.jobs[0].departure.type);
      // console.log(this.order.jobs[0].destination.type);
      this.errorService.addErrors([
        {
          id: flightNumberErrorId,
          property: 'flightNumber',
          text: 'field_required_error',
          label: '',
        },
      ]);
    } else {
      this.errorService.removeError(flightNumberErrorId);
    }

    if (!returnFlightNumber && this.state.orderMode === 'airport') {
      this.errorService.addErrors([
        {
          id: returnFlightNumberErrorId,
          property: 'returnFlightNumber',
          text: 'field_required_error',
          label: '',
        },
      ]);
    } else {
      this.errorService.removeError(returnFlightNumberErrorId);
    }
  }

  getPaymentGateways(): void {
    this.apiService.getCustom(`/payments/gateways`).subscribe(result => {
      const transactionCosts = this.form.config.payment.transactionCosts;
      let gateways = this.form.config.payment.gateways;

      if (this.form.config.payment['PSP'] === 'Multisafepay') {
        /**
         * We remove the standard MSP option, because we retrieve the options from the backend
         */
        gateways = gateways.filter((gateway) => !(gateway.id === 'MULTISAFEPAY'))
      }

      if (result.data) {
        gateways = gateways.concat(result.data);
      }

      let gatewayData: PaymentGateway[] = [];
      gateways.forEach((gateway, i) => {
        if (transactionCosts[gateway.id]) {
          gateways[i].transactionCosts = transactionCosts[gateway.id];
        }
        gatewayData[`${gateways[i].id}`] = gateways[i];
      });

      const hasPrice = (this.formGroup.controls['product'] && this.formGroup.controls['product'].value && this.formGroup.controls['product'].value.price.total > 0);

      gateways = gateways.filter((gateway) => {
        return ((gateway.directProcess && (!gateway.onlyMetered || !hasPrice)) ||
        (hasPrice && !gateway.onlyMetered) ? gateway : false);
      });
      this.state.paymentGateways = gateways;

      this.paymentGateways = gatewayData;
      if (gateways[0]) {
        this.formGroup.controls.paymentMethod.setValue(gateways[0].id);
      }
      if (this.form.config && this.form.config.payment && this.form.config.payment['PSP'] === 'Stripe') {
        this.loadScript(`https://js.stripe.com/v3/`);
      }

      if (this.passenger && this.passenger.passengerId) {
        const methods = [];
        this._customerService.getPaymentMethods().subscribe((paymentMethods: any[]) => {
          paymentMethods.forEach((method: any) => {
            methods.push({
              id: `ONACCOUNT_${method.debtorId}`,
              description: `${this.translate.instant('payment_method_on_account')} ${method.name}`,
              directProcess: true,
              transactionCosts: 0
            });
          });

          gateways.forEach((method: any) => {
            methods.push(method);
          })
          gateways = methods.filter((gateway) => {
            return (gateway.directProcess ||
            (this.formGroup.controls['product'] && this.formGroup.controls['product'].value &&
              this.formGroup.controls['product'].value.price.total > 0) ? gateway : false);
          });
          if (gateways[0]) {
            this.formGroup.controls.paymentMethod.setValue(gateways[0].id);
          }
          this.state.paymentGateways = gateways;
          gatewayData = [];
          gateways.forEach((gateway, i) => {
            if (transactionCosts[gateway.id]) {
              gateways[i].transactionCosts = transactionCosts[gateway.id];
            }
            gatewayData[`${gateways[i].id}`] = gateways[i];
          });
          this.paymentGateways = gatewayData;
        })
      }
    });
  }

  calculatePricing(values?: any): void {
    if (!values) {
      values = this.formGroup.getRawValue();
    }
    this.totalPrice = 0;
    this.singlePrice = 0;
    this.returnPrice = 0;
    this.transactionCosts = 0;
    this.singleProducts = [];
    this.returnProducts = [];
    this.jobPrice = DefaultPrice;
    this.jobPriceReturn = DefaultPrice;
    this.displayProducts = {};

    if (!values.product) {
      return;
    }

    this.selectedProducts = [
      JSON.parse(JSON.stringify(values.product)),
      JSON.parse(JSON.stringify(values.product))
    ]

    const selectedProduct = JSON.parse(JSON.stringify(values.product));
    const selectedProductReturn = JSON.parse(JSON.stringify(values.product));

    console.log('[selectedProduct]', selectedProduct);
    console.log('[selectedProductReturn]', selectedProductReturn);

    if (selectedProduct) {
      console.log('Calculating single prices', selectedProduct.price);
      this.singlePrice = JSON.parse(JSON.stringify(selectedProduct.price.total));
    }
    if (values.returnTrip) {
      console.log('Calculating single prices', selectedProductReturn.priceReturn);
      this.returnPrice = JSON.parse(JSON.stringify(selectedProductReturn.priceReturn.total));
    }

    console.log('[productMutations]', this.productMutations);
    this.productMutations.forEach((fieldConfig) => {
      let field = JSON.parse(JSON.stringify(fieldConfig));
      if (field.type === 'number') {
        field.mutation.amount = field.mutation.amount * values[field.property];
        if (field.mutation.returnAmount) {
          field.mutation.returnAmount = field.mutation.returnAmount * values[field.property];
        }
      }
      let calculatedAmount = values.returnTrip ?
        (field.mutation.returnAmount ? (field.mutation.returnAmount / 2) : field.mutation.amount) :
        field.mutation.amount;

      const product: Product = {
        sku: field.property,
        description: this.translate.instant(field.label),
        amount: {
          total: calculatedAmount,
          currency: this.state.currency,
          taxPercentage: field.mutation.taxPercentage,
        }
      };

      this.displayProducts[field.property] = product;

      if (values[field.property]) {
        if (field.mutation.type === 'global') {
          this.singleProducts.push(product);
          this.returnProducts.push(product);
          if (values.returnTrip) {
            this.singlePrice += calculatedAmount;
            this.returnPrice += calculatedAmount;
          } else {
            this.singlePrice += calculatedAmount;
          }
        } else if (field.mutation.type === 'single') {
          this.singleProducts.push(product);
          this.singlePrice += calculatedAmount;
        } else if (field.mutation.type === 'return') {
          this.returnProducts.push(product);
          this.returnPrice += calculatedAmount;
        } else if (field.mutation.type === 'airportDestinationJob') {
          if (values.returnTrip) {
            if (this.state.airportDeparture) {
              this.singleProducts.push(product);
              this.singlePrice += calculatedAmount;
            } else {
              this.returnProducts.push(product);
              this.returnPrice += calculatedAmount;
            }
          } else {
            this.singleProducts.push(product);
            this.singlePrice += calculatedAmount;
          }
        } else if (field.mutation.type === 'airportDepartureJob') {
          if (values.returnTrip) {
            if (this.state.airportDestination) {
              this.singleProducts.push(product);
              this.singlePrice += calculatedAmount;
            } else {
              this.returnProducts.push(product);
              this.returnPrice += calculatedAmount;
            }
          } else {
            this.singleProducts.push(product);
            this.singlePrice += calculatedAmount;
          }
        }
      }
    });
    this.state.paymentGateways.forEach((method) => {
      if (method.id === values.paymentMethod) {
        this.transactionCosts = method.calculatedCosts;
      }
    });

    if (this.transactionCosts) {
      if (this.state.couponDiscount) {
        this.calculateCouponDiscount(selectedProduct, selectedProductReturn);
        let tmpSelectedProduct = JSON.parse(JSON.stringify(selectedProduct));
        let tmpSelectedProductReturn = null;
        console.log(tmpSelectedProduct);

        tmpSelectedProduct.price.total = tmpSelectedProduct.price.total + this.state.couponDiscount.discount;
        if (tmpSelectedProduct.price.breakdown) {
          tmpSelectedProduct.price.breakdown.route.total = tmpSelectedProduct.price.total;
        }
        if (values.returnTrip) {
          tmpSelectedProductReturn = JSON.parse(JSON.stringify(selectedProductReturn));
          tmpSelectedProductReturn.priceReturn.total = tmpSelectedProduct.price.total + this.state.couponDiscount.discountRetour;
          if (tmpSelectedProductReturn && tmpSelectedProductReturn.priceReturn.breakdown) {
            tmpSelectedProductReturn.priceReturn.breakdown.route.total = tmpSelectedProductReturn.priceReturn.total;
          }
        }
        this.getPaymentMethodCosts(tmpSelectedProduct, tmpSelectedProductReturn);
        this.state.paymentGateways.forEach((method) => {
          if (method.id === values.paymentMethod) {
            this.transactionCosts = method.calculatedCosts;
          }
        });
      }

      console.log('[TransactionCosts]', this.transactionCosts);
      if (values.returnTrip) {
        this.singlePrice = this.singlePrice + Math.ceil(this.transactionCosts / 2);
        this.returnPrice = this.returnPrice + Math.floor(this.transactionCosts / 2);
      } else {
        this.singlePrice = this.singlePrice + this.transactionCosts;
      }
    }

    this.calculateCouponDiscount(selectedProduct, selectedProductReturn);

    if (this.state.couponDiscount) {
      console.log('[Coupon]', this.state.couponDiscount);
      this.singlePrice = this.singlePrice + this.state.couponDiscount.discount;

      if (selectedProductReturn && values.returnTrip) {
        this.returnPrice = this.returnPrice + this.state.couponDiscount.discountRetour;
      }
    }

    this.totalPrice = Math.round(this.singlePrice + this.returnPrice);

    this.jobPrice = {
      total: this.singlePrice,
      currency: selectedProduct.price.currency,
      breakdown: {
        route: {
          total: this.singlePrice,
          taxPercentage: 0
        },
        toll: {
          total: 0,
          taxPercentage: 0
        },
        parking: {
          total: 0,
          taxPercentage: 0
        },
        waiting: {
          total: 0,
          taxPercentage: 0
        },
        cleaning: {
          total: 0,
          taxPercentage: 0
        },
        discount: {
          total: 0,
          taxPercentage: 0
        }
      }
    }

    if (values.returnTrip) {
      this.jobPriceReturn = {
        total: this.returnPrice,
        currency: selectedProduct.price.currency,
        breakdown: {
          route: {
            total: this.returnPrice,
            taxPercentage: 0
          },
          toll: {
            total: 0,
            taxPercentage: 0
          },
          parking: {
            total: 0,
            taxPercentage: 0
          },
          waiting: {
            total: 0,
            taxPercentage: 0
          },
          cleaning: {
            total: 0,
            taxPercentage: 0
          },
          discount: {
            total: 0,
            taxPercentage: 0
          }
        }
      }
    }

    this.state.pricingData = {
      ...this.state.pricingData,
      single: this.singlePrice,
      return: this.returnPrice,
      transaction: this.transactionCosts,
      total: this.totalPrice,
    };
    // console.log(`this.state.pricingData: ${this.state.pricingData}`);
    // console.log(this.state.pricingData);
    // console.log(`total price: ${this.totalPrice}`);
    // console.log(`single price: ${this.singlePrice}`);
    // console.log(`return price: ${this.returnPrice}`);
    // console.log(`transactionCosts: ${this.transactionCosts}`);
    // console.log(`discountPrice: ${this.discountPrice}`);
    // if (selectedProduct && selectedProduct.price && selectedProduct.price.breakdown && selectedProduct.price.breakdown.discount) {
    //   console.log(`discount: ${(selectedProduct.price.breakdown.discount.total ? selectedProduct.price.breakdown.discount.total : 0)}`);
    // }
    // console.log(`single products`, this.singleProducts);
    // console.log(`single products`, this.returnProducts);
    // console.log('metaFields', this.metaFields);
    // console.log('--------------');
  }

  calculateTransactionCosts(price: number, costs: TransactionCosts[]): number {
    let transactionCosts = 0;
    costs.forEach(cost => {
      if (cost.type === TransactionCostType.Fixed) {
        transactionCosts += cost.amount;
      } else if (cost.type === TransactionCostType.Percentage) {
        transactionCosts += Math.round(((price) / 100) * cost.amount);
      }
    });
    return transactionCosts;
  }

  calculateCouponDiscount(product, productReturn) {
    if (!this.state.couponDiscount)
      return;
    /**
     * Sometimes the discount is already filled because of the special rates. The discount can also be positive in
     * that case it is an price increase from the special rates!
     */
    let discount = 0;
    let discountRetour = 0;
    if (this.state.couponDiscount.discountType === 'fixed') {
      discount = (0 - parseInt(String(this.state.couponDiscount.value)));
      discountRetour = (0 - parseInt(String(this.state.couponDiscount.value)));
    } else if (this.state.couponDiscount.discountType === 'balance') {
      discount = (0 - (this.state.couponDiscount.balanceLimitByCustomer < this.state.couponDiscount.maxDiscount ? this.state.couponDiscount.balanceLimitByCustomer : this.state.couponDiscount.maxDiscount));
      discountRetour = 0;
      if (this.state.couponDiscount.balanceLimitByCustomer > product.price.total) {
        const leftOver = this.state.couponDiscount.balanceLimitByCustomer - product.price.total;
        discountRetour = (0 - (leftOver < this.state.couponDiscount.maxDiscount ? leftOver : this.state.couponDiscount.maxDiscount));
      }
    } else {
      discount =
        Math.round(0 - ((product.price.total / 100) * parseInt(String(this.state.couponDiscount.value))));
      if (productReturn) {
        discountRetour =
          Math.round(0 - ((productReturn.price.total / 100) * parseInt(String(this.state.couponDiscount.value))));
      }
    }

    this.state.couponDiscount.discount = discount;
    this.state.couponDiscount.discountRetour = discountRetour;
  }

  buildQuotation(values: any): void {
    console.log('Building Quotation!', values);
    const singleMeta = {};
    const singleForeignMeta = {};
    Object.keys(this.singleMeta).forEach(key => {
      if (this.quotationFields.indexOf(key) > -1) {
        singleMeta[key] = this.singleMeta[key];
      }
    });
    Object.keys(this.singleForeignMeta).forEach(key => {
      if (this.quotationFields.indexOf(key) > -1) {
        singleForeignMeta[key] = this.singleForeignMeta[key];
      }
    });
    this.quotation = {
      passengerCount: (values.passengerCount ? Number(values.passengerCount) : 1),
      departure: values.departureModel,
      duration: (values.duration ? (values.duration * 60 * 60) : 0),
      meta: singleMeta,
      foreignMeta: singleForeignMeta,
    };

    if (values.destinationModel) {
      this.quotation.destination = values.destinationModel;
    }

    this.quotation[values.requestedDateMode] = (values[values.requestedDateMode] ? values[values.requestedDateMode] : values.requestedDate);

    if (this.formGroup.controls.returnTrip.value) {
      if (values[values.returnRequestedDateMode]) {
        this.quotation[values.returnRequestedDateMode] = values[values.returnRequestedDateMode];
      }
      if (!values[values.requestedDateMode] && values.requestedDate) {
        values[values.requestedDateMode] = values.requestedDate;
      }
    }
    if (values.stopOvers) {
      this.quotation.stopOvers = values.stopOversModel;
    }

    console.groupCollapsed('Quotation')
    console.log(this.quotation);
    console.log('values.requestedDate:', values.requestedDate);
    console.log('values.' + values.requestedDateMode + ':', values[values.requestedDateMode]);
    console.log('values.requestedDateMode:', values.requestedDateMode);
    console.groupEnd();
  }

  buildMeta(values: any): void {
    this.metaFields.forEach(field => {
      if (field.metaRule) {
        if (field.metaRule === 'airportDestinationJob' && values.destinationModel) {
          if (values.destinationModel.type === 'airport') {
            // console.log(`RULE FIELD: set on single meta cause airport destination job`);
            this[field.meta ? 'singleMeta' : 'singleForeignMeta'][field.property] = this.numericFields.indexOf(field.property) > -1 ?
              Number(values[field.property]) :
              values[field.property];
          } else if (values.returnTrip && values.departureModel.type === 'airport') {
            // console.log(`RULE FIELD: set on return meta cause airport destination job`);
            this[field.meta ? 'returnMeta' : 'returnForeignMeta'][field.property] = this.numericFields.indexOf(field.property) > -1 ?
              Number(values[field.property]) :
              values[field.property];
          }
        }
      } else {
        // console.log(`NO RULE FIELD: set on single and return meta`);
        this[field.meta ? 'singleMeta' : 'singleForeignMeta'][field.property] = this.numericFields.indexOf(field.property) > -1 ?
          Number(values[field.property]) :
          values[field.property];
        this[field.meta ? 'returnMeta' : 'returnForeignMeta'][field.property] = this.numericFields.indexOf(field.property) > -1 ?
          Number(values[field.property]) :
          values[field.property];
      }
    });
    this.singleForeignMeta.productSku = values.product ? values.product.sku : '';
    this.returnForeignMeta.productSku = values.product ? values.product.sku : '';

    this.singleMeta.orderId = this.orderId;
    this.returnMeta.orderId = this.orderId;
    const affiliateCode = this.activatedRoute.snapshot.queryParams.affiliate;
    if (affiliateCode && affiliateCode !== '') {
      this.affiliateCode = affiliateCode;
      this.singleMeta.affiliateCode = affiliateCode;
      this.returnMeta.affiliateCode = affiliateCode;
    }
  }

  buildOrder(values: any): void {
    /**
     * We revert the coupon discount in the order, because the platform does that on it's own terms
     */
    let firstJobPrice = DefaultPrice;
    let secondJobPrice = DefaultPrice;

    if (this.jobPrice) {
      firstJobPrice = JSON.parse(JSON.stringify(this.jobPrice));
      secondJobPrice = JSON.parse(JSON.stringify(this.jobPriceReturn));

      if (this.state.couponDiscount) {
        if (this.state.couponDiscount.discount) {
          firstJobPrice.total = firstJobPrice.total - this.state.couponDiscount.discount;
          firstJobPrice.breakdown.route.total = firstJobPrice.total;
        }

        if (this.state.couponDiscount.discountRetour && values.returnTrip) {
          secondJobPrice.total = secondJobPrice.total - this.state.couponDiscount.discountRetour;
          secondJobPrice.breakdown.route.total = secondJobPrice.total;
        }
      }
    }
    const job: Job = {
      departure: values.departureModel,
      passengerCount: (values.passengerCount ? Number(values.passengerCount) : 1),
      note: values.note,
      vehicleType: (values.product && values.product.vehicleType ? values.product.vehicleType : ''),
      [values.requestedDateMode]: (values[values.requestedDateMode] ? values[values.requestedDateMode] : values.requestedDate),
      language: (this.state.language ? this.state.language : 'NL'),
      timezone: (moment(values[values.requestedDateMode]).tz(this.state.timeZone).utcOffset() / 60),
      price: firstJobPrice,
      flightNumber: values.flightNumber,
      couponId: values.couponId,
      passenger: {
        fname: values.firstName,
        lname: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        language: (this.state.language ? this.state.language : 'NL'),
      },
      meta: JSON.parse(JSON.stringify(this.singleMeta)),
      foreignMeta: {...this.singleForeignMeta, origin: this.formId},
      products: JSON.parse(JSON.stringify(this.singleProducts)),
    };

    if (values.requestedDateMode === 'requestedDestinationDate' && values.requestedDate) {
      job['requestedDate'] = values.requestedDate;
    }

    if (this.passenger) {
      job.passengerId = this.passenger.passengerId;
    }
    if (values.paymentMethod && values.paymentMethod.indexOf('ONACCOUNT') > -1) {
      job.debtorId = values.paymentMethod.split('_')[1];
    }

    if (values.destinationModel) {
      job.destination = values.destinationModel;
    }

    if (values.stopOvers) {
      job.stopOvers = values.stopOversModel;
    }
    if (this.state.couponDiscount && this.state.couponDiscount.returnType === 'meta') {
      job.meta.couponId = this.state.couponDiscount.id;
    }

    if (values.product) {
      const vehicleProduct: Product = {
        category: 'vehicleType',
        sku: values.product.sku,
        imageUrl: values.product.imagePath,
        description: values.product.name,
        extraInfo: values.product.extraInfo,
        amount: {
          total: values.product.price.total,
          currency: values.product.price.currency,
          taxPercentage: values.product.price.breakdown.route.taxPercentage,
        }
      };
      if (!job.products) {
        job.products = [];
      }
      job.products.push(vehicleProduct);
    }

    if (this.transactionCosts) {
      let transactionCosts = this.transactionCosts;
      if (values.returnTrip) {
        transactionCosts = Math.ceil(this.transactionCosts / 2);
      }
      const transactionCostProduct: Product = {
        category: 'transactionCosts',
        description: 'transactionCosts',
        sku: 'TRANSACTION_COSTS',
        amount: {
          total: transactionCosts,
          currency: values.product.price.currency,
          taxPercentage: values.product.price.breakdown.route.taxPercentage,
        }
      };
      if (!job.products) {
        job.products = [];
      }
      job.products.push(transactionCostProduct);
    }

    if (this.activeTab === 'hourly') {
      if (job.price.total > 0) {
        job.priceMethod = 'hourlyFixed';
      } else {
        job.priceMethod = 'hourlyMeter';
      }

      job.priceMeta = {
        bookedDuration: (values.duration ? (values.duration * 60 * 60) : 0),
      };
    }

    this.order = {
      paymentMethod: values.paymentMethod,
      jobs: [
        job
      ]
    };

    if (values.returnTrip) {
      let timeMode = values.returnRequestedDateMode;
      const rJob: Job = {
        departure: values.destinationModel,
        passengerCount: (values.passengerCount ? Number(values.passengerCount) : 1),
        note: values.note,
        vehicleType: (values.product && values.product.vehicleType ? values.product.vehicleType : ''),
        language: (this.state.language ? this.state.language : 'NL'),
        price: secondJobPrice,
        timezone: (moment(values[values.returnRequestedDateMode]).tz(this.state.timeZone).utcOffset() / 60),
        flightNumber: values.returnFlightNumber,
        couponId: values.couponId,
        passenger: {
          fname: values.firstName,
          lname: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          language: (this.state.language ? this.state.language : 'NL'),
        },
        meta: JSON.parse(JSON.stringify(this.returnMeta)),
        foreignMeta: {...this.returnForeignMeta, origin: this.formId},
        products: JSON.parse(JSON.stringify(this.returnProducts)),
      };

      if (values.returnRequestedDateMode === 'requestedDestinationDate') {
        job['requestedDate'] = values.requestedDate ? values.requestedDate : values[values.returnRequestedDateMode];
      }

      if (values.stopOvers) {
        rJob.stopOvers = JSON.parse(JSON.stringify(values.stopOversModel)).reverse();
      }

      if (timeMode) {
        rJob[values.returnRequestedDateMode.replace('returnR', 'r')] = (values[timeMode] ? values[timeMode] : null);
      } else if (values['requestedDate']) {
        rJob['requestedDate'] = (values['requestedDate'] ? values['requestedDate'] : null);
      }
      if (this.passenger) {
        rJob.passengerId = this.passenger.passengerId;
      }
      if (values.paymentMethod.indexOf('ONACCOUNT') > -1) {
        rJob.debtorId = values.paymentMethod.split('_')[1];
      }
      if (values.departureModel) {
        rJob.destination = values.departureModel;
      }
      if (values.product) {
        const vehicleProduct: Product = {
          category: 'vehicleType',
          sku: values.product.sku,
          imageUrl: values.product.imagePath,
          description: values.product.name,
          extraInfo: values.product.extraInfo,
          amount: {
            total: values.product.priceReturn.total,
            currency: values.product.priceReturn.currency,
            taxPercentage: values.product.priceReturn.breakdown.route.taxPercentage,
          }
        };
        if (!rJob.products) {
          rJob.products = [];
        }
        rJob.products.push(vehicleProduct);
      }

      if (this.transactionCosts) {
        let transactionCostsReturn = Math.floor(this.transactionCosts / 2);
        const transactionCostProduct: Product = {
          category: 'transactionCosts',
          description: 'transactionCosts',
          sku: 'TRANSACTION_COSTS',
          amount: {
            total: transactionCostsReturn,
            currency: values.product.price.currency,
            taxPercentage: values.product.price.breakdown.route.taxPercentage,
          }
        };
        if (!job.products) {
          job.products = [];
        }
        job.products.push(transactionCostProduct);
      }

      if (this.state.couponDiscount && this.state.couponDiscount.returnType === 'meta') {
        job.meta.couponId = this.state.couponDiscount.id;
      }
      if (this.order.jobs) {
        this.order.jobs.push(rJob);
      }
    }
  }

  getQuotation(direction = 'route'): Promise<any> {
    this.loader = true;
    console.groupCollapsed('getQuotation()');
    // console.log('this.quotation', this.quotation);
    // if (!this.quotation) {
    const values = this.formGroup.getRawValue();
    // console.log(values);
    this.buildMeta(values);
    this.buildQuotation(values);
    // }
    this.quotation.priceType = this.activeTab;
    this.quotation.calculateReturn = true;
    if (this.supportMode) {
      this.quotation.returnSupportData = true;
    }
    this.supportCalculationData = [];
    this.returnTripSupportData = [];

    const localRequestedDateMode = (direction === 'route' ? 'requestedDateMode' : 'returnRequestedDateMode');

    return new Promise((resolve, reject) => {
      this.apiService.postCustom('/quotations', this.quotation).subscribe((products) => {
        this.analytics.addToDataLayer({
          [DataLayerProperties.Event]: 'Quotation',
          [DataLayerProperties.QuotationDeparture]: this.quotation.departure.internationalAlias,
          [DataLayerProperties.QuotationDestination]: (this.quotation.destination ? this.quotation.destination.internationalAlias : ''),
          [DataLayerProperties.QuotatioPassengers]: this.quotation.passengerCount,
          [DataLayerProperties.QuotationRequestedDate]: this.quotation.requestedDate
        });

        if(products && products.error && products.error === 'NO_RULES_MATCHED') {
          reject({
            title: 'error_no_rules_found_title',
            text: this.translate.instant('no_rules_found_text').replace('{routes}', products.suggestedRoutes.map((r) => `${r.departure} - ${r.destination}`).join('</li><li>')),
          });
          return;
        }

        const firstProduct = products[0];
        if (firstProduct && firstProduct.metrics && this.formGroup.controls[localRequestedDateMode].value === (direction === 'route' ? 'requestedDestinationDate' : 'returnRequestedDestinationDate') && firstProduct.metrics.duration && this.form.config.airportDropOffRecalculatePickupTime) {
          const d = moment(this.formGroup.controls[this.formGroup.controls[localRequestedDateMode].value].value).subtract(Math.round(firstProduct.metrics.duration), 'minutes').toDate();
          let ms = 1000 * 60 * 5; // convert minutes to ms
          let roundedDate = moment(Math.floor(d.getTime() / ms) * ms).format();
          if (direction === 'route') {
            this.formGroup.controls['requestedDate'].setValue(roundedDate);
            this.formGroup.controls['requestedScheduleDate'].setValue('');
          } else {
            this.formGroup.controls['returnRequestedDate'].setValue(roundedDate);
            this.formGroup.controls['returnRequestedScheduleDate'].setValue('');
          }
          console.log(`Calculated ${localRequestedDateMode}: ${this.formGroup.controls[localRequestedDateMode].value}`);
        } else if (firstProduct && firstProduct.metrics && this.formGroup.controls[localRequestedDateMode].value === (direction === 'route' ? 'requestedDestinationDate' : 'returnRequestedDestinationDate') && firstProduct.metrics.duration && this.form.config.requestedDestinationDateOffset) {
          const d = moment(this.formGroup.controls[this.formGroup.controls[localRequestedDateMode].value].value).subtract(Math.round(this.form.config.requestedDestinationDateOffset), 'minutes').toDate();
          let ms = 1000 * 60 * 5; // convert minutes to ms
          let roundedDate = moment(Math.floor(d.getTime() / ms) * ms).format();

          if (direction === 'route') {
            this.formGroup.controls['requestedDate'].setValue(roundedDate);
            this.formGroup.controls['requestedScheduleDate'].setValue('');
          } else {
            this.formGroup.controls['returnRequestedDate'].setValue(roundedDate);
            this.formGroup.controls['returnRequestedScheduleDate'].setValue('');
          }
          console.log(`Calculated ${localRequestedDateMode}: ${this.formGroup.controls[localRequestedDateMode].value}`);
        } else if (this.formGroup.controls[localRequestedDateMode].value === (direction === 'route' ? 'requestedScheduleDate' : 'returnRequestedScheduleDate')) {
          this.formGroup.controls[this.formGroup.controls[localRequestedDateMode].value].setValue(this.formGroup.controls[this.formGroup.controls[localRequestedDateMode].value].value);

          if (direction === 'route') {
            this.formGroup.controls['requestedDate'].setValue('');
            this.formGroup.controls['requestedDestinationDate'].setValue('');
          } else {
            this.formGroup.controls['returnRequestedDate'].setValue('');
            this.formGroup.controls['returnRequestedDestinationDate'].setValue('');
          }
        } else if (this.formGroup.controls[localRequestedDateMode].value === (direction === 'route' ? 'requestedDate' : 'returnRequestedDate')) {
          if (direction === 'route') {
            if (this.formGroup.controls['requestedScheduleDate']) {
              this.formGroup.controls['requestedScheduleDate'].setValue('');
            }
            if (this.formGroup.controls['requestedDestinationDate']) {
              this.formGroup.controls['requestedDestinationDate'].setValue('');
            }
          } else {
            if (this.formGroup.controls['returnRequestedScheduleDate']) {
              this.formGroup.controls['returnRequestedScheduleDate'].setValue('');
            }
            if (this.formGroup.controls['returnRequestedDestinationDate']) {
              this.formGroup.controls['returnRequestedDestinationDate'].setValue('');
            }
          }
        } else {
          const currentValue = this.formGroup.controls[this.formGroup.controls[localRequestedDateMode].value].value;
          this.formGroup.controls[(direction === 'route' ? 'requestedDate' : 'returnRequestedDate')].setValue('');
          this.formGroup.controls[(direction === 'route' ? 'requestedDestinationDate' : 'returnRequestedDestinationDate')].setValue('');
          this.formGroup.controls[(direction === 'route' ? 'requestedScheduleDate' : 'returnRequestedScheduleDate')].setValue('');
          this.formGroup.controls[this.formGroup.controls[localRequestedDateMode].value].setValue(currentValue);
        }

        this.products = products.map(product => {
          const currency = this.state.currencies.filter((c) => {
            return (c.alphaCode === product.price.currency);
          })[0];

          this.state.currency = currency.alphaCode;
          this.state.currencySymbol = (currency.nativeSymbol ? currency.nativeSymbol : currency.symbol);
          this.metrics = product.metrics;
          if (product.metrics) {
            this.metrics.durationDesc = Math.ceil(product.metrics.duration);
            //Round product.metrics.distance to 2 decimal places
            product.metrics.distanceDesc = Math.round(product.metrics.distance * 100) / 100;
          }

          if (this.supportMode) {
            product.supportData.price = product.price;
            product.supportData.name = product.name;
            this.supportCalculationData.push(product.supportData);

            if(product.returnTripSupportData) {
              product.returnTripSupportData.price = product.priceReturn;
              product.returnTripSupportData.name = product.name;
              this.returnTripSupportData.push(product.returnTripSupportData);
            }
          }

          return {
            ...product,
            'descriptionTag': (product.sku && (!this.form.config.parts[1].productDescription
              || this.form.config.parts[1].productDescription === 'sku')
              ? `product_description_${product.sku.toLowerCase()}` : product.name),
            'summaryTag': `product_summary_${product.sku.toLowerCase()}`,
            'currencySymbol': (currency ? (currency.nativeSymbol ? currency.nativeSymbol : currency.symbol) : null),
            'imagePath': (product.imagePath ? product.imagePath : `${this.form.config.cdnUrl}/products/${product.sku}.png`),
            'hourlyButton': (this.formGroup.controls['duration'] ? this.translate.instant('product_button_hourly').replace('{x}', this.formGroup.controls['duration'].value) : false)
          };
        });

        console.groupEnd();
        this.loader = false;
        if (this.products.length > 0) {
          resolve(1);
        } else {
          console.log('Error from quotation:');
          reject({
            title: 'error_no_products_found_title',
            text: 'error_no_products_found_text'
          });
        }
      }, (error) => {
        console.log('Error from quotation:', error);
        console.groupEnd();
        if (error && error.error && error.error.error && error.error.error.name === 'NO_ROUTE') {
          reject({
            title: 'error_no_route_found_title',
            text: 'error_no_route_found_text'
          });
        } else {
          reject({
            title: 'error_no_products_found_title',
            text: 'error_no_products_found_text'
          });
        }
      });
    });
  }

  checkQuotation(): Promise<any> {
    return this.getQuotation('return')
      .then((results) => {
        /**
         * Get total price
         */
        const selectedProduct = this.products.filter((p) => {
          if(this.formGroup.controls['product'].value.productId) {
            return p.productId === this.formGroup.controls['product'].value.productId
          } else {
            return p.sku === this.formGroup.controls['product'].value.sku
          }
        })[0];

        if (selectedProduct && selectedProduct.priceReturn && selectedProduct.totalPrice.total && selectedProduct.totalPrice.total !== this.formGroup.controls['product'].value.totalPrice.total) {
          this.priceUpdateButton = selectedProduct;
          const modal = this.modalService.getModal(`priceUpdate`);
          this.getPaymentMethodCosts();
          modal.open();
          return Promise.reject();
        }
      })
  }

  selectProduct() {
    this.formGroup.patchValue({
      product: this.priceUpdateButton,
    });
    console.log(this.priceUpdateButton);
    console.log(this.formGroup.getRawValue());
    this.acceptedPrice = true;
    this.navigate('next');
  }

  getSkipQuotation(): Promise<number> {
    return this.getQuotation().then(() => {
      this.formGroup.patchValue({
        'product': this.products[0]
      });
      return Promise.resolve(2);
    })
  }

  getPaymentMethodCosts(selectedProduct = null, selectedProductReturn = null): Promise<any> {
    if (!selectedProduct) {
      selectedProduct = JSON.parse(JSON.stringify(this.selectedProducts[0]));
      selectedProductReturn = JSON.parse(JSON.stringify(this.selectedProducts[1]));
    }

    let singlePrice = this.singlePrice;
    let returnPrice = this.returnPrice;
    if (selectedProduct) {
      singlePrice = selectedProduct.price.total;
    }
    if (selectedProductReturn && this.formGroup.controls.returnTrip.value) {
      returnPrice = selectedProductReturn.priceReturn.total;
    }
    return new Promise((resolve) => {
      const gateways = this.state.paymentGateways;
      gateways.forEach((g, i) => {
        if (g.transactionCosts) {
          gateways[i].calculatedCosts = this.calculateTransactionCosts((singlePrice + returnPrice), g.transactionCosts);
        }
      });
      this.state.paymentGateways = [...gateways];

      this.state.paymentGateways.forEach((method) => {
        if (method.id === this.formGroup.controls.paymentMethod.value) {
          this.transactionCosts = method.calculatedCosts;
        }
      });

      return resolve(3);
    });
  }

  async bookOrder(): Promise<any> {
    const self = this;
    const paymentMethod = this.formGroup.controls.paymentMethod.value;
    // console.log(`bookOrder(${paymentMethod})`);
    this.state.orderPosted = true;
    this.saveState();
    this.checkPageValidity();
    if (this.pageStates[this.selectedFormIndex]) {
      this.saveOrder()
        .then(() => {
          if (!this.catchDuplicateBooking && this.orderId) {
            this.catchDuplicateBooking = true;
            setTimeout(function () {
              self.catchDuplicateBooking = false;
            }, 2000);
            // console.log(paymentMethod);``
            // console.log(this.formGroup.controls['product'].value.price);
            // console.log(this.paymentGateways);
            if (this.paymentGateways[paymentMethod].preAuth) {
              const modal = this.modalService.getModal(`paymentPreauthModal`);
              modal.open();
            } else if (this.form.config.orderConfirm) {
              const modal = this.modalService.getModal(`orderConfirm`);
              modal.open();
            } else if (!this.paymentGateways[paymentMethod].directProcess && this.totalPrice > 0) {
              this.createPayment();
            } else {
              this.processOrder();
            }
          }
        }).catch((error) => {
        if (error && error.error && error.error.message) {
          alert(error.error.message);
        }
        self.loader = false;
      })
    } else {
      /**
       * Not booking because form is not valid
       * - If there is only 1 error, that is the terms error we show a helper dialog
       */
      if (this.errorService.errors.length === 1 && this.errorService.errors[0].id === 'acceptTermsAndConditionsRequired') {
        const modal = this.modalService.getModal('termsAndConditionsModal');
        modal.addCustomClass('termsAndConditionsModal');
        modal.open();
        console.log('modelModal');
      }
    }
  }

  acceptTermsAndBook(): void {
    const termsAndConditionsModal = this.modalService.getModal('termsAndConditionsModal');
    this.formGroup.controls['acceptTermsAndConditions'].setValue(true);
    termsAndConditionsModal.close()
    this.bookOrder();
  }

  saveState(): void {
    const data = this.formGroup.getRawValue();
    data.timesaved = new Date();
    this._vault.setObject(this.state.storageKeys.values, data);
    this._vault.setItem(this.state.storageKeys.orderId, this.orderId);
  }

  clearState(): void {
    this._vault.removeItem(this.state.storageKeys.values);
    this._vault.removeItem(this.state.storageKeys.orderId);
  }

  saveOrder(): Promise<any> {
    // console.log(`saveOrder(): ${this.orderId}`, this.order);
    const method = (this.orderId ? 'patchCustom' : 'postCustom');
    const path = `/orders${(this.orderId ? `/${this.orderId}` : '')}`;
    // console.log(`Method: ${method}`);
    console.log(`Sending`, this.order);
    return this.apiService[method](path, this.order).toPromise().then((order) => {
      // console.log(`Order: ${order}`);
      if (order && order.id) {
        this.orderId = order.id;
      }
      this.saveState();
      return order;
    }, (error) => {
      return Promise.reject(error);
    });
  }

  processOrder(): void {
    const self = this;
    if (self.form.config.successUrl) {
      self.onlyShowLoader = true;
    }
    self.loader = !self.processingPayment;

    self.apiService.postCustom(`/orders/${self.orderId}/process`, {}).subscribe((processedOrder) => {
      console.log('ORDER PROCESSED', processedOrder);
      self.apiService.getCustom(`/orders/${self.orderId}/payment`, {}).subscribe(payment => {
        self.analytics.addToDataLayer({
          [DataLayerProperties.Event]: 'Booking',
          [DataLayerProperties.AffiliateCode]: self.affiliateCode,
          [DataLayerProperties.TransactionId]: self.orderId,
          [DataLayerProperties.TransactionAffiliation]: self.state.formId,
          [DataLayerProperties.TransactionTotal]: (payment ? payment.amount : self.totalPrice) / 100,
        });
        self.analytics.pageView(`/${self.state.language}/${self.state.formId}/order-processed`);
        self.clearState();
        self.loader = false;
        if (self.form.config.successUrl) {
          return top.location = self.form.config.successUrl;
        } else if (self.navigation === 'inline' || self.navigation === 'modal' || self.navigation === 'scroll') {
          self.orderStatus = (processedOrder.status === 'processed' ? 'completed' : 'failed');
          self.selectedFormIndex = 4;
        } else {
          return self.router.navigate([`/${self.state.language}/${self.state.formId}/${processedOrder.id}/completed`]);
        }
      }, (error) => {
        console.error(error);
        self.loader = false;
      });
    }, (error) => {
      if (error && error.error && error.error.error && error.error.error.message === 'Order already processed') {
        self.apiService.getCustom(`/orders/${self.orderId}/`).subscribe((processedOrder) => {
          self.clearState();
          self.loader = false;
          self.processingPayment = false;
          self.state.reset();
          if (self.form.config.successUrl) {
            return top.location = self.form.config.successUrl;
          } else if (self.navigation === 'inline' || self.navigation === 'modal') {
            self.orderStatus = (processedOrder.status === 'processed' ? 'completed' : 'failed');
            this.selectedFormIndex = 4;
            console.log('Redirecting2');
          } else {
            return self.router.navigate([`/${self.state.language}/${self.state.formId}/${processedOrder.id}/completed`]);
          }
        });
      }
      self.loader = false;
      self.processingPayment = false;
    });
  }

  createPayment(preAuth = false): void {
    this.loader = true;
    const language = this.activatedRoute.snapshot.paramMap.get('language') ?
      this.activatedRoute.snapshot.paramMap.get('language') :
      (this.activatedRoute.parent && this.activatedRoute.parent.snapshot.paramMap.get('language') ? this.activatedRoute.parent.snapshot.paramMap.get('language') : this.state.language);

    this.mspLanguage = MSP_LOCALES[language];
    const productDescription = this.translate.instant(this.formGroup.controls['product'].value.descriptionTag);
    const d = moment(this.formGroup.controls[this.formGroup.controls.requestedDateMode.value].value).tz(this.state.timeZone);
    const address = (this.formGroup.controls.departure.value && this.formGroup.controls.departure.value.description ? this.formGroup.controls.departure.value.description : this.formGroup.controls.departure.value);
    const description = (preAuth ? this.translate.instant('payment_description_preauth') :
      this.translate.instant('payment_description'))
      .replace('{productname}', productDescription)
      .replace('{date}', d.format('DD-MM-YYYY HH:mm'))
      .replace('{address}', address);

    const gateway = this.formGroup.controls.paymentMethod.value;
    const psp = this.paymentGateways[gateway]?.psp || (gateway.toUpperCase() === 'STRIPE' ? 'STRIPE' : 'MSP');

    const paymentData = {
      amount: this.totalPrice,
      currency: this.formGroup.controls['product'].value.price.currency,
      provider: psp,
      gateway: this.formGroup.controls.paymentMethod.value,
      product: {
        description: productDescription,
        sku: this.formGroup.controls['product'].value.sku,
        extraInfo: this.formGroup.controls['product'].value.extraInfo,
        amount: {
          total: this.formGroup.controls['product'].value.price.total,
          currency: this.formGroup.controls['product'].value.price.currency,
          taxPercentage: this.formGroup.controls['product'].value.price.taxPercentage,
        },
        imageUrl: (
          this.formGroup.controls['product'].value.imagePath ? this.formGroup.controls['product'].value.imagePath :
            ((this.form.config.cdnUrl === 'https://order.yourdriverapp.com' ?
              `${this.form.config.cdnUrl}/assets/default-product-img.png` :
              (this.form.config.cdnUrl ?
                `${this.form.config.cdnUrl}/products/${this.formGroup.controls['product'].value.sku}.png` :
                `${this.form.config.customUri}/assets/default-product-img.png`)))),
      },
      description,
      orderId: this.orderId,
      customer: {
        locale: this.mspLanguage,
        ipAddress: this.ipAddress,
        firstName: this.formGroup.controls.firstName.value,
        lastName: this.formGroup.controls.lastName.value,
        emailAddress: this.formGroup.controls.email.value,
        phoneNumber: this.formGroup.controls.phoneNumber.value,
      },
    };
    if (preAuth) {
      // @ts-ignore
      paymentData.preAuth = preAuth;
      // @ts-ignore
      paymentData.submitText = this.translate.instant('preauth_submit_text');
    }
    // console.log(`createPayment(): paymentData`, paymentData);

    this.apiService.postCustom(`/payments`, paymentData)
      .subscribe(payment => {
        this._vault.setObject(this.state.storageKeys.payment, paymentData);
        this._vault.setObject(this.state.storageKeys.paymentMeta, payment);
        this._vault.setItem(this.state.storageKeys.paymentStarted, '1');

        if (this.formGroup.controls.paymentMethod.value === 'STRIPE' ||
          (this.paymentGateways[this.formGroup.controls.paymentMethod.value].psp === 'STRIPE')) {
          payment.gateway = 'stripe';
          this._vault.setObject(this.state.storageKeys.paymentMeta, payment);

          const options = {};
          if (this.form.config.payment.stripeAccountId) {
            // @ts-ignore
            options.stripeAccount = this.form.config.payment.stripeAccountId;
          }

          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          const stripe = Stripe((this.form.config.payment.stripeAccountId ? environment.ydaStripePublicKey : this.form.config.payment.publicKey), options);
          stripe.redirectToCheckout({sessionId: payment.sessionId},)
            .then((result) => {
              // If redirectToCheckout fails due to a browser or network
              // error, you should display the localized error message to your
              // customer using error.message.
              if (result.error) {
                alert(result.error.message);
              }
            });
        } else if (this.formGroup.controls.paymentMethod.value === 'WIPAY') {
          payment.gateway = 'Wipay';
          this._vault.setObject(this.state.storageKeys.paymentMeta, payment);
          // if(this.formStyle === 'inline') {
          //   this.loader = false;
          //   this.paymentModalIframe.nativeElement.src = payment.paymentUrl;
          //   const modal = this.modalService.getModal(`paymentModal_${this.productSelectionCode}`);
          //   modal.addCustomClass('productSelectionModal');
          //   modal.open();
          // } else {
          this.document.location.href = payment.paymentUrl;
          // }
        } else if (this.formGroup.controls.paymentMethod.value === 'PAYPAL') {
          payment.gateway = 'PayPal';
          this._vault.setObject(this.state.storageKeys.paymentMeta, payment);
          top.location.href = payment.paymentUrl;
        }
        else {
          payment.gateway = 'multisafepay';
          this._vault.setObject(this.state.storageKeys.paymentMeta, payment);
          // if(this.formStyle === 'inline') {
          //   this.loader = false;
          //   this.paymentModalIframe.nativeElement.src = payment.paymentUrl;
          //   const modal = this.modalService.getModal(`paymentModal_${this.productSelectionCode}`);
          //   modal.addCustomClass('productSelectionModal');
          //   modal.open();
          // } else {
          top.location.href = payment.paymentUrl;
          // }
        }

      }, error => {
        this.loader = false;
        console.error(error);
      });
  }

  navigate(direction: string, index: number = -1): void {
    console.groupCollapsed('navigate');
    const currentType = this.form.config.parts[this.selectedFormIndex].type;
    const indexSpecified = index > -1;
    let newIndex = (indexSpecified ? index : (direction === 'next' ? this.selectedFormIndex + 1 : this.selectedFormIndex - 1));
    const newType = this.form.config.parts[newIndex].type;
    let checkPromise = Promise.resolve();

    if (direction === 'back' && this.form.config.parts[newIndex].before === 'getSkipQuotation') {
      newIndex = newIndex - 1;
    }
    const goingBack = this.selectedFormIndex > newIndex;
    const goingForward = this.selectedFormIndex < newIndex;
    const returnTrip = this.formGroup.controls.returnTrip.value;
    const hasProduct = this.formGroup.controls['product'].value !== null;
    const fn = this.form.config.parts[newIndex].before;
    const afterFn = this.form.config.parts[newIndex].after;
    console.log(`navigate(${direction})[${newIndex}]`);
    console.log(`current type: ${currentType}`);
    console.log(`new type: ${newType}`);
    console.log(`indexSpecified: ${indexSpecified}`);
    console.log(`goingBack: ${goingBack}`);
    console.log(`goingForward: ${goingForward}`);
    console.log(`hasProduct: ${hasProduct}`);
    console.log(`newIndex: ${newIndex}`);
    console.log(`returnTrip: ${returnTrip}`);
    console.log(`fn: ${fn}`);
    console.log(`afterFn: ${afterFn}`);

    if (goingForward) {
      this.checkPageValidity();
    }

    if (!this.pageStates[this.selectedFormIndex] && goingForward) {
      console.error('FORM NOT VALID, NOT NAVIGATING!');
      console.groupEnd();
    } else {
      if (currentType === 'products' && goingBack && hasProduct) {
        this.formGroup.controls['product'].setValue(null);
        this.formGroup.controls.returnTrip.setValue(false);
        this.pageStates[this.selectedFormIndex] = false;
        this.acceptedPrice = false;

        if (returnTrip) {
          this.selectedFormIndex = 1;
        } else {
          this.selectedFormIndex = newIndex;
        }
        console.groupEnd();
      } else {
        if (newType === 'products' && goingBack && !returnTrip) {
          this.pageStates[newIndex] = false;
        } else if (currentType === 'products' && !goingBack) {
          this.pageStates[newIndex] = false;
          this.processDatesForOrder();

          if (this.formGroup.controls.returnTrip.value && !this.acceptedPrice) {
            checkPromise = this.checkQuotation();
          }
        } else if (newType === 'products' && goingBack) {
          this.acceptedPrice = false;
        }

        /**
         * We need to correct the requestedDate setup
         * Are we showing the destinationdate? Then we put it in the requested date
         */
        checkPromise.then(() => {
          if (newIndex === 0 && this.state.orderMode == 'airport' && this.state.airportDestination &&
            this.formGroup.controls['requestedDestinationDate'] && this.formGroup.controls['requestedDestinationDate'].value) {
            this.formGroup.controls['requestedDate'].setValue(this.formGroup.controls['requestedDestinationDate'].value);
          }

          if (fn) {
            this[fn]().then((skipIndex) => {
              this.selectedFormIndex = skipIndex;
              this.scrollUp();
            }, (error) => {
              console.error('ERROR IN BEFORE', error);
              this.navigationError = error;
              this.modalService.getModal('navigationErrorModal').open();
              this.loader = false;
            });
          } else {
            this.selectedFormIndex = newIndex;
            this.scrollUp();
          }

          this.analytics.pageView(`/${this.state.language}/${this.form.id}/${this.form.config.parts[newIndex].gtmVirtualPageUrl}`);

          console.groupEnd();
        }).catch((error) => {
          console.groupEnd();
        });
      }
    }
  }

  processDatesForOrder() {
    let mode = this.formGroup.controls['returnRequestedDateMode'].value.replace('request', 'returnRequest');
    if (this.metrics && this.formGroup.controls['returnRequestedDateMode'].value === 'requestedDestinationDate' && this.metrics.duration && this.form.config.airportDropOffRecalculatePickupTime) {
      const d = moment(this.formGroup.controls[mode].value).subtract(Math.round(this.metrics.duration), 'minutes').toDate();
      let ms = 1000 * 60 * 5; // convert minutes to ms
      let roundedDate = moment(Math.ceil(d.getTime() / ms) * ms).format();
      this.formGroup.controls['returnRequestedDate'].setValue(roundedDate);
      // console.log('Calculated returnRequestedDate:', this.formGroup.controls['returnRequestedDate'].value);
    } else if (mode === 'requestedScheduleDate') {
      // console.log(this.formGroup.controls[mode].value);
      this.formGroup.controls['returnRequestedDate'].setValue(this.formGroup.controls[mode].value);
    } else if (mode === 'returnRequestedDate') {
      if (this.formGroup.controls['returnRequestedScheduleDate']) {
        this.formGroup.controls['returnRequestedScheduleDate'].setValue('');
      }
      if (this.formGroup.controls['returnRequestedDestinationDate']) {
        this.formGroup.controls['returnRequestedDestinationDate'].setValue('');
      }
    }
  }

  scrollUp(): void {
    setTimeout(() => {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 0
      });
    }, 100);
  }

  detectGoogleMapsLoaded() {
    const self = this;
    if (!this.googleMapsLoaded) {
      if (typeof (google) !== 'undefined') {
        this.googleMapsLoaded = true;
      } else {
        setTimeout(function () {
          self.detectGoogleMapsLoaded();
        }, 1000);
      }
    }
  }

  toggleHelpModal(event: any) {
    this.helpData.helpTitleTag = event.helpTitleTag || 'help_title_tag_unknown';
    this.helpData.helpTextTag = event.helpTextTag || 'help_text_tag_unknown';
    this.helpData.meta = event.meta || null;
    const type = (event.type ? event.type : 'helpModal');
    const modal = this.modalService.getModal(type);
    modal.addCustomClass(type);
    if (type === "serviceAreaModal" && this.serviceArea) {
      if (!this.myPolygon) {
        this.myPolygon = new google.maps.Polygon({
          paths: [this.serviceArea],
          strokeColor: this.form.config.styles.colors['global-title-color'],
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: this.form.config.styles.colors['global-title-color'],
          fillOpacity: 0.2,
          zIndex: 1
        });
      }

      this.departureMarker = {
        draggable: false,
        clickable: false,
        icon: {
          url: 'assets/icon-location-departure.svg',
          scaledSize: new google.maps.Size(30, 30),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(15, 15)
        }
      };
      this.destinationMarker = {
        draggable: false,
        clickable: false,
        icon: {
          url: 'assets/icon-location-destination.svg',
          scaledSize: new google.maps.Size(30, 30),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(15, 15)
        }
      };
      modal.open(true);
      this.serviceAreaMapFitBounds()
    } else {
      modal.open(true);
    }
  }

  serviceAreaMapFitBounds() {
    const self = this;
    if (self.serviceAreaMap) {
      const bounds = getBounds([this.myPolygon]);
      if (self.formGroup.controls.departureModel.value) {
        bounds.extend(new google.maps.LatLng(self.formGroup.controls.departureModel.value.gps));
      }
      if (self.formGroup.controls.destinationModel.value) {
        bounds.extend(new google.maps.LatLng(self.formGroup.controls.destinationModel.value.gps));
      }
      self.serviceAreaMap.fitBounds(bounds);
    } else {
      setTimeout(function () {
        self.serviceAreaMapFitBounds();
      }, 250)
    }
  }

  ngOnDestroy(): void {
    if (this.formChanges) {
      this.formChanges.unsubscribe();
    }
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.ipAddressSubscription) {
      this.ipAddressSubscription.unsubscribe();
    }
  }

  setActivateTab(tab): void {
    const self = this;
    if (!this.form.config.parts || !this.form.config.parts[0] || !this.form.config.parts[0].fields) {
      setTimeout(function () {
        self.setActivateTab(tab);
      }, 50);
    } else {
      this.activateTab(tab);
    }
  }

  activateTab(tab): void {
    if (this.activeTab === tab) {
      return;
    }
    this.activeTab = tab;
    this.form.config.orderType = tab;
    /**
     * We update the config to hourly
     */
    let addressIndex = 0;
    this.form.config.parts[0].fields.forEach((field, i) => {
      if (field.type === 'address' || field.type === 'sortable-address') {
        addressIndex = i;
      }
    });

    console.log(addressIndex);
    console.log(this.destinationConfig);
    console.log(this.durationConfig);

    if (tab === 'hourly') {
      this.destinationConfig = this.form.config.parts[0].fields[addressIndex];
      if(this.form.config.allowStopOvers) {
        if (!this.form.config.bookingHourlyDestination) {
          this.form.config.parts[0].fields.splice(addressIndex, 1);
          this.form.config.parts[0].fields[addressIndex-1].type = 'address';
          this.form.config.parts[0].fields.splice(addressIndex, 0, this.durationConfig);
        } else {
          this.form.config.parts[0].fields.splice(addressIndex + 1, 0, this.durationConfig);

          this.form.config.parts[0].fields[addressIndex-1].type = 'address';
          this.form.config.parts[0].fields[addressIndex].type = 'hiddenInputAddress';
          this.form.config.parts[0].fields[addressIndex+2].type = 'address';
        }
      } else {
        if (!this.form.config.bookingHourlyDestination) {
          this.form.config.parts[0].fields.splice(addressIndex, 1);
          this.form.config.parts[0].fields.splice(addressIndex, 0, this.durationConfig);
        } else {
          this.form.config.parts[0].fields.splice(addressIndex + 1, 0, this.durationConfig);
        }
      }
    } else {
      if (!this.form.config.bookingHourlyDestination) {
        this.form.config.parts[0].fields.splice((addressIndex + 1), 0, this.destinationConfig);
        this.form.config.parts[0].fields.splice((addressIndex + 2), 1);
        if(this.form.config.allowStopOvers) {
          this.form.config.parts[0].fields[addressIndex].type = 'hiddenInputAddress';
        }
      } else {
        this.form.config.parts[0].fields.splice((addressIndex - 1), 1);
        if(this.form.config.allowStopOvers) {
          this.form.config.parts[0].fields[0].type = 'hiddenInputAddress';
          this.form.config.parts[0].fields[1].type = 'sortable-address';
          this.form.config.parts[0].fields[2].type = 'hiddenInputAddress';
        }
      }
      console.log(this.form.config.parts[0].fields);

    }
  }

  closeModal = () => {
    const self = this;
    setTimeout(() => {
      self.formGroup.controls['product'].setValue(null);
      self.formGroup.controls.returnTrip.setValue(false);
      self.pageStates[this.selectedFormIndex] = false;
      self.selectedFormIndex = 0;
    }, 400);
  }

  sendReport() {
    this.toggleHelpModal({
      'helpTitleTag': this.translate.instant('support-report-title'),
      'helpTextTag': this.supportFrame.nativeElement.innerHTML,
      'type': 'supportReportModal'
    });
  }

  copyAndClose(containerid) {
    const modal = this.modalService.getModal('supportReportModal');
    modal.close();
    let r = document.createRange();
    r.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }

  checkWorkAreas(property): void {
    // address.direction = this.config.property;
    const addresses = {
      destination: this.formGroup.controls.destinationModel.value,
      departure: this.formGroup.controls.departureModel.value,
    };
    /**
     * Do we have an GPS position for both locations?
     */
    if (!addresses.destination || !addresses.departure ||
      !addresses.destination.gps || !addresses.departure.gps) {
      return;
    }
    this.apiService.postCustom('/forms/validateWorkarea', addresses).subscribe((result) => {
      const hasSortableAddress = (this.wrappers.toArray().filter((w: any) => {
        return (w.field.type === `sortable-address`);
      }).length > 0);
      this.wrappers.toArray().filter((w: any) => {
        return ((w.field.property === property && !hasSortableAddress) || w.field.type === `sortable-address`);
      }).forEach((wrapper: any) => {
        if (!result.isWorkarea && result.area && result.area.coordinates) {
          this.serviceArea = result.area.coordinates[0].map((c) => {
            return {
              lat: c[1],
              lng: c[0]
            };
          });
          this.serviceAreaError = true;
            wrapper.addError({
              id: `departureWorkArea`,
              property: 'departure',
              text: 'address_field_error_work_area',
              helpText: 'address_field_error_work_area',
              label: wrapper.field.label
            });
            wrapper.addError({
              id: `destinationWorkArea`,
              property: 'destination',
              text: 'address_field_error_work_area',
              helpText: 'address_field_error_work_area',
              label: wrapper.field.label
            });
        } else if (!result.allowedDeparture || !result.allowedDestination) {
          this.serviceAreaError = true;
          if(!result.allowedDeparture) {
            wrapper.addError({
              id: `departureWorkArea`,
              property: 'departure',
              text: 'address_field_error_area_not_allowed',
              helpText: 'address_field_error_area_not_allowed',
              label: wrapper.field.label
            });
          }

          if(!result.allowedDestination) {
            wrapper.addError({
              id: `destinationWorkArea`,
              property: 'destination',
              text: 'address_field_error_area_not_allowed',
              helpText: 'address_field_error_area_not_allowed',
              label: wrapper.field.label
            });
          }
          console.log(this.serviceAreaError);
        } else {
          this.serviceAreaError = false
          wrapper.removeError({
            id: `departureWorkArea`,
            property: 'departure',
          });
          wrapper.removeError({
            id: `destinationWorkArea`,
            property: 'destination',
          });
        }
      });
    });
  }

  waitForPayment(itterator=0): void {
    const self = this;
    self.apiService.getCustom(`/orders/${self.orderId}/payment`, {}).subscribe((payment) => {
      if(itterator > 20) {
        self.processingPayment = false;
        return self.router.navigate([`/${self.state.language}/${self.state.formId}/${self.orderId}/failed`]);
      }

      if(payment.status === 'processing') {
        setTimeout(function() {
          itterator = itterator + 1;
          self.waitForPayment(itterator);
        }, (itterator > 10 ? 2500 : 500));
      } else {
        self.processingPayment = false;
        if (self.form.config.successUrl) {
          return top.location = self.form.config.successUrl;
        } else if (self.navigation === 'inline' || self.navigation === 'modal' || self.navigation === 'scroll') {
          self.orderStatus = payment.status;
          self.selectedFormIndex = 4;
        } else {
          return self.router.navigate([`/${self.state.language}/${self.state.formId}/${self.orderId}/completed`]);
        }
      }
    })
  }
}
