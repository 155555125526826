import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { Field, ProductMutation } from '../../interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StateService } from '../../services/state.service';
import { CurrencyPipe } from '@angular/common';
import { UnicornPipe } from '../../pipes/unicorn.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  encapsulation: ViewEncapsulation.Emulated,
styleUrls: ['./checkbox-field.component.scss'],
  providers: [CurrencyPipe, UnicornPipe]
})
export class CheckboxFieldComponent implements OnInit, OnDestroy {
  @Input() config: Field;
  @Input() control: UntypedFormGroup;
  hasErrors = false;
  placeholder = '';
  description = '';
  productMutation: ProductMutation;
  returnTripSub: Subscription;

  constructor(private translate: TranslateService,
              private state: StateService,
              private currencyPipe: CurrencyPipe,
              private unicornPipe: UnicornPipe) {}

  ngOnInit(): void {
    this.returnTripSub = this.control.controls['returnTrip'].valueChanges.subscribe(this.onReturnTripChanges.bind(this));
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.defineDescription();
    });

    this.defineDescription();
  }

  defineDescription(): void {
    this.translate.get(this.config.placeholder).subscribe(placeholder => {
      this.placeholder = placeholder;

      const tempUrls = this.config.documentUrls ? this.translate.instant(this.config.documentUrls) : [];
      const translatedUrls = [];
      Object.keys(tempUrls).forEach(k => {
        translatedUrls.push(tempUrls[k]);
      });

      this.placeholder = this.unicornPipe.transform(this.placeholder, translatedUrls);

      if (this.config.product) {
        const amount = this.config.product.amount;
        if (this.config.product && this.config.product.type === 'airportDepartureJob') {
          if(this.state.airportDestination && !this.control.controls['returnTrip'].value) {
            delete this.config.product;
          }
        } else if(this.config.product && this.config.product.type === 'airportDestinationJob') {
          if(this.state.airportDeparture && !this.control.controls['returnTrip'].value) {
            delete this.config.product;
          }
        }
      }

      if (this.config.product) {
        this.productMutation = this.config.product;
        const amount = (this.control.controls['returnTrip'].value && this.productMutation.returnAmount ?
          this.productMutation.returnAmount :
          this.productMutation.amount);
        const amountFormatted: any = this.currencyPipe.transform((amount / 100), this.state.currency, 'symbol', '1.2-2');
        this.description = `${this.placeholder} (${amountFormatted})`;
      } else {
        this.description = this.placeholder;
      }
    });

  }

  onReturnTripChanges(value: any): void {
    this.defineDescription();
  }

  toggle(event: any) {
    const patch = {};
    patch[this.config.property] = event.target.checked;
    if(event.target.checked) {
      this.validityChanged(false);
      this.state.orderPosted = false;
    }
    this.control.patchValue(patch);
  }

  validityChanged(valid: boolean) {
    this.hasErrors = valid;
  }

  ngOnDestroy(): void {
    if (this.returnTripSub) {
      this.returnTripSub.unsubscribe();
    }
  }

}
