<div [formGroup]="control" class="{{hasErrors ? 'error' : ''}}">
  <select [formControlName]="config.property" name="{{config.property}}" id="{{config.property}}" (change)="selectionChanged($event)">
    <option *ngFor="let values of config.values" value="{{values.value}}">
      {{values.label | translate | ucFirst | unicorn:values.value}}
    </option>
  </select>
  <svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Book-your-trip" transform="translate(-108.000000, -498.000000)" fill="#FFFFFF">
        <polygon id="Triangle" transform="translate(115.000000, 503.000000) rotate(-180.000000) translate(-115.000000, -503.000000) " points="115 498 122 508 108 508"></polygon>
      </g>
    </g>
  </svg>
  <app-field-error [config]="config" (validityChanged)="validityChanged($event)"></app-field-error>
</div>
